/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Bookmarkoutlineadd = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M17.82 11.334V19.5L12 17l-5.82 2.5V6.18c0-.453.16-.847.48-1.18.32-.333.707-.5 1.16-.5h3.157a2.873 2.873 0 0 0 .006 1.68H7.82V17L12 15.16 16.18 17v-5.656a2.873 2.873 0 0 0 1.64-.01zM16.126 4.47h-2.412a.86.86 0 0 0 0 1.72l2.418-.01-.008 2.419a.86.86 0 1 0 1.72 0V6.188h2.41a.855.855 0 0 0 .603-1.462.876.876 0 0 0-.603-.258l-2.412.002V2.059a.86.86 0 1 0-1.72 0l.004 2.41z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Bookmarkoutlineadd.displayName = "Bookmarkoutlineadd"

Bookmarkoutlineadd.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Bookmarkoutlineadd.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Bookmarkoutlineadd;
