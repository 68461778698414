/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Book = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" fillRule="evenodd" d="M5.442 21.815a2.755 2.755 0 0 1-2.752-2.751 2.755 2.755 0 0 1 2.752-2.752h15.754v2.5H5.823a.345.345 0 1 0 0 .69h15.373v2.313H5.442zM2.69 5.786c0-1.4 1.052-2.557 2.407-2.727v12.584a3.438 3.438 0 0 0-2.407 1.356V5.786zm10.64-2.752h4.823v7.779l-2.209-1.598a.346.346 0 0 0-.404 0l-2.21 1.598V3.034zm7.866 12.588H5.786V3.034h6.855v8.454a.344.344 0 0 0 .546.279l2.555-1.847 2.554 1.847a.345.345 0 0 0 .547-.28V3.035h2.353v12.588zm.345-13.277H5.44A3.445 3.445 0 0 0 2 5.786v13.278a3.445 3.445 0 0 0 3.44 3.441h16.1c.19 0 .344-.154.344-.345V2.69a.345.345 0 0 0-.344-.345zM8.983 5.128a.571.571 0 1 0 0 1.143.571.571 0 0 0 0-1.143"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Book.displayName = "Book"

Book.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Book.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Book;
