/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Updates = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M27.858 26.653a42.884 42.884 0 0 1-1.262-10.733c0-6.391-4.103-11.031-9.752-11.031-5.493 0-9.75 5.929-9.75 11.031 0 4.813-1.263 10.711-1.276 10.769l-.142.671h6.075a5.12 5.12 0 0 0 10.182 0h6.13l-.205-.707zm-11.014 4.165a4 4 0 0 1-3.968-3.458h7.933a4 4 0 0 1-3.965 3.436v.022zm-9.8-4.61a59.556 59.556 0 0 0 1.16-10.328c0-5.587 4.645-9.92 8.64-9.92 5.085 0 8.64 4.08 8.64 9.92a45.89 45.89 0 0 0 1.12 10.329H7.044zm9.778-22.804a1.267 1.267 0 1 1 .01-2.533 1.267 1.267 0 0 1-.01 2.533z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Updates.displayName = "Updates"

Updates.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Updates.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Updates;
