import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Typography.module.scss';

const cx = classNames.bind(styles);

const H2 = ({ children, className, ...props }) => {
  const classes = cx('h2', className);

  return (
    <h2 className={classes} {...props}>
      {children}
    </h2>
  );
};

H2.propTypes = {
  children: PropTypes.node.isRequired,
  /** CSS classes */
  className: classNamePropShape,
};

H2.defaultProps = {
  className: null,
};

H2.displayName = 'H2';

H2.filename = __filename;

export default H2;
