/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Mobile = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="m 16.434325,27.915954 c 0.422081,0 0.764215,-0.349262 0.764215,-0.780136 0,-0.430875 -0.342134,-0.780193 -0.764215,-0.780193 -0.422083,0 -0.764271,0.349318 -0.764271,0.780193 0,0.430874 0.342188,0.780136 0.764271,0.780136 z M 8.3082789,24.844141 c 0,-0.218806 0.1737558,-0.396182 0.3880952,-0.396182 H 24.172443 c 0.214285,0 0.388096,0.177376 0.388096,0.396182 0,0.218804 -0.173811,0.39618 -0.388096,0.39618 H 8.6963741 c -0.2143394,0 -0.3880952,-0.177376 -0.3880952,-0.39618 z m 0,-21.259713 c 0,-1.3403015 1.0813025,-2.4441301 2.3942501,-2.4441301 h 11.46374 c 1.312759,0 2.393993,1.1035513 2.39427,2.4436264 0,1.697e-4 0,3.339e-4 0,5.037e-4 l 0.04774,16.529883 v 8.581835 c 0,1.340336 -1.081288,2.444152 -2.39427,2.444152 h -11.51148 c -1.3129476,0 -2.3942501,-1.103816 -2.3942501,-2.444152 z M 10.702529,1.932661 c -0.8842681,0 -1.6180625,0.7490752 -1.6180625,1.651767 v 25.111718 c 0,0.902726 0.7337944,1.651791 1.6180625,1.651791 h 11.511476 c 0.884301,0 1.618079,-0.749065 1.618079,-1.651791 V 20.114311 L 23.784348,3.5855939 v -0.00113 c 0,-0.9026917 -0.733833,-1.6517669 -1.618079,-1.6517669 z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Mobile.displayName = "Mobile"

Mobile.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Mobile.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Mobile;
