/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const SociallinkedIn = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M7.582 20H4.264V9.318h3.318V20zM5.922 7.86A1.936 1.936 0 0 1 4 5.922a1.922 1.922 0 0 1 3.843 0c0 1.061-.86 1.94-1.922 1.94zM20 20h-3.31v-5.2c0-1.24-.026-2.829-1.726-2.829-1.725 0-1.989 1.347-1.989 2.74V20H9.661V9.318h3.182v1.457h.046c.443-.84 1.525-1.725 3.14-1.725 3.357 0 3.975 2.21 3.975 5.082V20H20z" fill="#0077B5"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

SociallinkedIn.displayName = "SociallinkedIn"

SociallinkedIn.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

SociallinkedIn.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default SociallinkedIn;
