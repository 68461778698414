/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Trashcan = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M10 9.938v5.624c0 .092-.03.167-.088.225a.304.304 0 0 1-.225.088h-.624a.304.304 0 0 1-.225-.088.304.304 0 0 1-.088-.225V9.939c0-.092.03-.167.088-.225a.304.304 0 0 1 .225-.088h.624c.092 0 .167.03.225.088a.304.304 0 0 1 .088.225zm2.5 0v5.624c0 .092-.03.167-.088.225a.304.304 0 0 1-.225.088h-.624a.304.304 0 0 1-.225-.088.304.304 0 0 1-.088-.225V9.939c0-.092.03-.167.088-.225a.304.304 0 0 1 .225-.088h.624c.092 0 .167.03.225.088a.304.304 0 0 1 .088.225zm2.5 0v5.624c0 .092-.03.167-.088.225a.304.304 0 0 1-.225.088h-.624a.304.304 0 0 1-.225-.088.304.304 0 0 1-.088-.225V9.939c0-.092.03-.167.088-.225a.304.304 0 0 1 .225-.088h.624c.092 0 .167.03.225.088a.304.304 0 0 1 .088.225zm1.25 7.07V7.75H7.5v9.258a1.109 1.109 0 0 0 .21.659c.049.055.083.083.103.083h8.125c.019 0 .053-.028.102-.083a1.109 1.109 0 0 0 .21-.66zM9.687 6.5h4.376l-.47-1.143a.276.276 0 0 0-.165-.107h-3.096a.276.276 0 0 0-.166.107L9.687 6.5zm9.063.313v.625c0 .09-.03.166-.088.224a.304.304 0 0 1-.224.088H17.5v9.258c0 .54-.153 1.007-.459 1.401-.306.394-.674.591-1.104.591H7.813c-.43 0-.798-.19-1.104-.571-.306-.381-.459-.842-.459-1.382V7.75h-.938a.304.304 0 0 1-.224-.088A.304.304 0 0 1 5 7.438v-.625c0-.092.03-.167.088-.225a.304.304 0 0 1 .224-.088H8.33l.684-1.63c.097-.242.273-.447.527-.616.254-.17.511-.254.771-.254h3.126c.26 0 .517.085.771.254.254.17.43.374.527.615L15.42 6.5h3.018c.09 0 .166.03.224.088a.304.304 0 0 1 .088.224z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Trashcan.displayName = "Trashcan"

Trashcan.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Trashcan.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Trashcan;
