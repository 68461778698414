import React from 'react';

export default React.createContext({
  color: {
    primary: null,
    secondary: null,
    danger: null,
    info: null,
    success: null,
    warning: null,
    background: null,
    backgroundAlternate: null,
    text: null,
  },
  button: {
    allCaps: null,
    borderRadius: null,
    fontWeight: null,
    fontFamily: null,
  },
  spinner: {
    color: null,
  },
  checkbox: {
    borderRadius: null,
    color: {
      unchecked: null,
      checked: null,
    },
  },
  loader: {
    color: null,
  },
});
