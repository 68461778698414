/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Socialtwitter = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M19.948 6.62a6.435 6.435 0 0 1-1.858.51 3.24 3.24 0 0 0 1.422-1.79 6.486 6.486 0 0 1-2.053.786 3.234 3.234 0 0 0-5.51 2.95 9.183 9.183 0 0 1-6.668-3.38 3.232 3.232 0 0 0 1.001 4.318 3.225 3.225 0 0 1-1.465-.404v.04a3.237 3.237 0 0 0 2.595 3.172 3.252 3.252 0 0 1-1.461.055 3.238 3.238 0 0 0 3.02 2.246 6.488 6.488 0 0 1-4.787 1.34 9.152 9.152 0 0 0 4.957 1.452c5.95 0 9.202-4.928 9.202-9.202 0-.14-.003-.28-.009-.419a6.55 6.55 0 0 0 1.614-1.673z" fill="#00ACED"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Socialtwitter.displayName = "Socialtwitter"

Socialtwitter.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Socialtwitter.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Socialtwitter;
