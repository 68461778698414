/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Stopsign = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12.75 12.5V9a.75.75 0 1 0-1.5 0v3.5a.75.75 0 1 0 1.5 0zm0 3a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0zM4 14.586V9.414a1 1 0 0 1 .293-.707l4.414-4.414A1 1 0 0 1 9.414 4h5.172a1 1 0 0 1 .707.293l4.414 4.414a1 1 0 0 1 .293.707v5.172a1 1 0 0 1-.293.707l-4.414 4.414a1 1 0 0 1-.707.293H9.414a1 1 0 0 1-.707-.293l-4.414-4.414A1 1 0 0 1 4 14.586z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Stopsign.displayName = "Stopsign"

Stopsign.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Stopsign.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Stopsign;
