/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Certificate = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12 4.3a3.654 3.654 0 0 0-3.65 3.65C8.35 9.975 10 11.6 12 11.6c2.025 0 3.65-1.65 3.65-3.65S14.025 4.3 12 4.3zm.275 4.6l-.825.825-.825-.825-.8-.8.825-.825.8.8 1.9-1.9.825.825-1.9 1.9zM19.3 6.925c-.325-.3-.45-.775-.275-1.175l.15-.375c.25-.575-.075-1.225-.675-1.4l-.4-.1a1.044 1.044 0 0 1-.75-.95V2.5a1.035 1.035 0 0 0-1.2-.975l-.4.075c-.45.075-.875-.125-1.075-.525l-.2-.35c-.3-.55-1-.7-1.5-.35L12.65.6c-.35.25-.85.25-1.2 0l-.325-.25C10.625-.025 9.9.15 9.6.7l-.2.35c-.225.4-.65.6-1.1.525L7.9 1.5c-.625-.1-1.175.35-1.225.975l-.025.4c-.025.45-.325.825-.75.95l-.4.125c-.6.175-.925.825-.675 1.4l.15.375c.175.4.05.875-.275 1.175l-.3.275c-.475.4-.475 1.15 0 1.55L4.7 9c.325.3.45.775.275 1.175l-.15.375c-.25.575.075 1.225.675 1.4l.4.1c.425.125.725.5.75.95l.025.4c.025.625.6 1.075 1.2.975l.4-.075c.45-.075.875.125 1.075.525l.2.35c.3.55 1 .7 1.5.35l.325-.225c.35-.25.85-.25 1.2 0l.325.25c.5.375 1.225.2 1.525-.35l.2-.35c.225-.4.65-.6 1.1-.525l.4.075c.625.1 1.175-.35 1.225-.975l.025-.4c.025-.45.325-.825.75-.95l.4-.1c.6-.175.925-.825.675-1.4l-.15-.375c-.175-.4-.05-.875.275-1.175l.3-.275c.475-.4.475-1.15 0-1.55l-.325-.275zm-7.3 6.1c-2.8 0-5.05-2.275-5.05-5.05 0-2.8 2.275-5.05 5.05-5.05 2.8 0 5.05 2.275 5.05 5.05s-2.25 5.05-5.05 5.05zm-7.85 8.8L6.6 15.7c.325.15.7.25 1.075.25.15 0 .3-.025.45-.05h.05l.025.05c.425.8 1.3 1.3 2.25 1.3.25 0 .525-.05.75-.125L8.625 23.6a.244.244 0 0 1-.45.025l-1.225-2.15c-.05-.075-.125-.1-.2-.075l-2.3.725c-.2.075-.375-.125-.3-.3zm11.225 1.85l-2.6-6.5c.25.075.5.125.75.125.95 0 1.8-.5 2.25-1.325l.025-.05h.05c.15.025.3.05.45.05.4 0 .75-.1 1.1-.25l2.45 6.175c.075.2-.1.4-.3.325l-2.3-.725c-.075-.025-.15 0-.2.075l-1.225 2.15c-.1.15-.375.125-.45-.05z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Certificate.displayName = "Certificate"

Certificate.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Certificate.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Certificate;
