/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Pageduplicate = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M5.285 16.24V3.897a.687.687 0 0 1 .66-.737h8.863c.303 0 .528.395.528.692v1.746h.985v-1.93c-.008-.75-.58-1.49-1.333-1.49H5.484c-.624 0-1.181.631-1.181 1.256v13.194c.017.72.602 1.296 1.323 1.301h1.826v-.984H6.014a.765.765 0 0 1-.728-.703l-.001-.003z"/><path transform="scale(1 1)" d="M18.792 5.919H8.871c-.952 0-1.223.607-1.223 1.144V20.29a1.271 1.271 0 0 0 1.22 1.181h7.362c2.396 0 3.433-1.534 3.433-3.741V7.165c-.002-.535-.107-1.247-.87-1.247h-.001zM16.206 20.49v-2.458a.197.197 0 0 1 .214-.197h2.346c-.008 1.58-.778 2.688-2.56 2.649v.006zm2.58-3.572H16a.788.788 0 0 0-.787.787v2.777H9.017a.469.469 0 0 1-.47-.469V7.411a.512.512 0 0 1 .51-.51h9.296c.185 0 .435.105.435.628v9.374l-.002.016z"/><path transform="scale(1 1)" d="M15.991 13.238c0-.16-.06-.43-.474-.43h-1.575v-1.716a.621.621 0 0 0-.104-.43.38.38 0 0 0-.265-.106h-.02a.346.346 0 0 0-.345.194.808.808 0 0 0-.046.31v-.002 1.752h-1.497c-.319 0-.515.166-.515.43a.441.441 0 0 0 .432.433c.237.005.03 0 1.645 0v1.592c.01.257.067.459.372.459.12 0 .345-.053.345-.467v-1.589h1.575c.29-.037.473-.2.473-.43z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Pageduplicate.displayName = "Pageduplicate"

Pageduplicate.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Pageduplicate.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Pageduplicate;
