export const navigateTabs = (
  tabs, // item array
  currentIndex, // current index
  direction, // arrow key direction
  setTab = {}, // useState() callback
  loopNavigation = true // back to start!
) => {
  const currentTabId = tabs[currentIndex].id;
  const navDirection = {
    prev: determinePrevTab(tabs, currentIndex, currentTabId, loopNavigation),
    next: determineNextTab(tabs, currentIndex, currentTabId, loopNavigation),
  };
  const newActiveTabId = navDirection[direction];
  // If there was no change in active tab,
  // no attempt to alter state will be made
  if (newActiveTabId !== currentTabId) {
    // utilize useState() callback
    setTab(newActiveTabId);
    // focus new tab element
    document.getElementById(`tab-${newActiveTabId}`).focus();
  }
};

const determinePrevTab = (tabs, currentIndex, currentTab, loop) => {
  // If the previous index is valid, goto: previous in sequence
  // eslint-disable-next-line no-nested-ternary
  return typeof tabs[currentIndex - 1] !== 'undefined'
    ? tabs[currentIndex - 1].id // previous in sequence
    : loop // if previous index is invalid (start of sequence) and looping is enabled
    ? tabs[tabs.length - 1].id // jump to end of sequence
    : currentTab; // if not, do nothing
};

const determineNextTab = (tabs, currentIndex, currentTab, loop) => {
  // If the next index is valid, goto: next in sequence
  // eslint-disable-next-line no-nested-ternary
  return typeof tabs[currentIndex + 1] !== 'undefined'
    ? tabs[currentIndex + 1].id // next in sequence
    : loop // if next index is invalid (end of sequence) and looping is enabled
    ? tabs[0].id // jump to start of sequence
    : currentTab; // if not, do nothing
};
