/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contentpdf = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M18.819.192H5.033c-1.402 0-2.342 1.242-2.342 2.643v18.387c0 1.402.96 2.595 2.365 2.595 0 0 10.88 0 10.963-.054a5.553 5.553 0 0 0 5.229-5.54V2.834c0-1.395-1.024-2.643-2.429-2.643v.001zm-3.037 17.28c-.355 0-.586.64-.586.982v4.138H5.036c-.698 0-1.069-.672-1.069-1.366V2.836c0-.698.368-1.363 1.062-1.363h13.789c.698 0 1.149.666 1.149 1.363v14.637h-4.182l-.003-.001zm.666 4.96v-3.68H20c-.352 1.92-1.632 3.405-3.552 3.68z"/><path transform="scale(1 1)" d="M5.114 8.24v-.006c0-.292.236-.528.528-.528h1.453c1.155 0 1.856.685 1.856 1.674 0 1.123-.874 1.702-1.958 1.702h-.81v.96a.534.534 0 0 1-1.066.002V8.241l-.003-.001zm1.92 1.92a.75.75 0 0 0 .845-.732v-.001c0-.477-.32-.733-.864-.733h-.832v1.482h.845l.006-.016zm2.707-1.92v-.006c0-.292.236-.528.528-.528h1.363c1.523 0 2.56 1.046 2.56 2.41 0 1.363-1.053 2.422-2.56 2.422h-1.366a.527.527 0 0 1-.528-.528V8.24h.003zm1.891 3.35a1.378 1.378 0 0 0 1.459-1.45v.003a1.392 1.392 0 0 0-1.39-1.461l-.072.002h-.822v2.922h.822l.003-.016zm3.523-3.35v-.006c0-.292.236-.528.528-.528H18.4a.484.484 0 0 1 .003.96h-2.181v1.03h1.859a.486.486 0 0 1 .539.48.483.483 0 0 1-.541.48h-1.857v1.408a.534.534 0 0 1-1.066.002V8.24h-.001z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contentpdf.displayName = "Contentpdf"

Contentpdf.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contentpdf.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contentpdf;
