/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Circleoutlineprice = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-1.5a8.25 8.25 0 1 0 0-16.5 8.25 8.25 0 0 0 0 16.5z"/><path transform="scale(1 1)" d="M11.2 16.483c-.72-.102-1.29-.338-1.711-.706-.46-.401-.689-.914-.689-1.538v-.512c0-.06.02-.107.057-.141a.229.229 0 0 1 .158-.051h.919c.067 0 .12.017.157.051a.18.18 0 0 1 .058.14v.244c0 .436.17.795.51 1.077.339.282.81.423 1.413.423.564 0 .997-.126 1.298-.378.302-.252.452-.575.452-.968a.873.873 0 0 0-.23-.602 2.13 2.13 0 0 0-.588-.449 19.78 19.78 0 0 0-1.061-.512c-.421-.17-.814-.35-1.177-.538-.66-.308-1.143-.628-1.45-.961-.306-.333-.459-.773-.459-1.32 0-.666.216-1.202.646-1.608.403-.38.968-.619 1.697-.718V6.034c0-.24.205-.434.457-.434h.686c.252 0 .457.194.457.434v1.381c.674.1 1.21.324 1.61.674.48.419.718.965.718 1.64v.372a.18.18 0 0 1-.057.14.229.229 0 0 1-.158.052h-.918a.25.25 0 0 1-.158-.045.146.146 0 0 1-.058-.122v-.153c0-.462-.16-.835-.48-1.122-.32-.286-.787-.429-1.4-.429-.535 0-.949.111-1.24.333-.292.222-.438.534-.438.936 0 .247.074.457.222.628.148.17.342.311.581.422.24.111.593.257 1.062.436l.732.282c.823.333 1.425.705 1.808 1.115a2.1 2.1 0 0 1 .574 1.486c0 .7-.24 1.269-.717 1.704-.412.375-.973.614-1.683.717v1.485c0 .24-.205.434-.457.434h-.686a.446.446 0 0 1-.457-.434v-1.483z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Circleoutlineprice.displayName = "Circleoutlineprice"

Circleoutlineprice.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Circleoutlineprice.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Circleoutlineprice;
