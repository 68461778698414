/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Document = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M17.958 8.375c.028.083.042.208.042.375v10.167c0 .277-.083.527-.25.75-.167.222-.375.333-.625.333H7c-.25 0-.48-.111-.688-.333a1.07 1.07 0 0 1-.312-.75V4.958c0-.25.104-.472.313-.666A.982.982 0 0 1 7 4h6.667c.166 0 .291.028.375.083.166.084.264.14.291.167l3.459 3.833c.083.084.139.18.166.292zm-3.916-2.417v2.625h2.416l-2.416-2.625zM7.333 18.667h9.334v-8.75h-2.792c-.306 0-.576-.118-.813-.354a1.117 1.117 0 0 1-.354-.813V5.333H7.333v13.334z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Document.displayName = "Document"

Document.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Document.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Document;
