/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contentassignment = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M15.775 22.642c1.866-.269 3.111-1.712 3.453-3.578h-3.453v3.578zm3.421-4.822V9.55l1.245-1.235v10.233a5.4 5.4 0 0 1-5.084 5.387c-.08.052-10.658.052-10.658.052-1.367 0-2.299-1.159-2.299-2.523V3.588c0-1.364.913-2.571 2.277-2.571h12.904l-1.256 1.246H4.673c-.675 0-1.034.646-1.034 1.325v17.88c0 .675.362 1.329 1.04 1.329h9.879v-4.023c0-.333.225-.955.57-.955l.002.001h4.066zM6.952 6.236l4.78.02c.266.002.48.217.48.484l-.001.184a.481.481 0 0 1-.484.48l-4.78-.02a.482.482 0 0 1-.48-.483l.002-.187c0-.266.217-.48.483-.478zm5.615 10.168l-5.62-.025a.481.481 0 0 1-.48-.484l.002-.185a.48.48 0 0 1 .483-.478l5.619.023c.266 0 .481.218.48.482l-.002.187a.48.48 0 0 1-.482.48zM22.8 2.295a.166.166 0 0 0 .002-.235l-.856-.864a.167.167 0 0 0-.235 0l-1.274 1.262 1.096 1.095L22.8 2.295zm-8.44 8.37l6.153-6.101-1.094-1.096-6.149 6.096-.004 1.096 1.094.004zm9.257-9.415c.511.516.507 1.35-.007 1.86l-8.78 8.706-2.717-.013-5.166-.022a.48.48 0 0 1-.48-.482l.002-.187c0-.266.217-.48.483-.478l5.165.02.008-1.57L20.902.38a1.316 1.316 0 0 1 1.86.009l.855.861z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contentassignment.displayName = "Contentassignment"

Contentassignment.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contentassignment.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contentassignment;
