/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Gift = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M31 8.324h-7.397a3.069 3.069 0 0 0-3.419-5.017c-.823.412-2.216 2.087-3.255 3.442a1.355 1.355 0 0 0-1.839.024c-1.039-1.36-2.432-3.035-3.255-3.447A3.074 3.074 0 0 0 7.712 4.71a3.04 3.04 0 0 0 .704 3.648H1v6.56h1.676V28.03h26.648V14.884H31v-6.56zM20.61 4.15a2.087 2.087 0 0 1 1.619-.11 2.121 2.121 0 0 1 .249 3.907 9.226 9.226 0 0 1-2.753.378H17.21c.086-.176.133-.369.14-.565 1.167-1.55 2.604-3.284 3.26-3.61zm-7.358 9.777V9.311h.823c.958 0 1.7 0 1.915-.034.216-.033.958.034 1.916.034h.823v4.615h-5.477zm5.496.958v12.19h-5.496v-12.19h5.496zM9.503 7.946a2.126 2.126 0 0 1-.958-2.844 2.13 2.13 0 0 1 1.221-1.063 2.126 2.126 0 0 1 1.619.11c.656.326 2.087 2.06 3.255 3.606.002.197.048.392.134.57h-2.499a9.355 9.355 0 0 1-2.772-.379zm-7.545 5.98V9.282h10.336v4.64H1.958v.004zm1.675.958h8.661v12.19h-8.66v-12.19zm24.734 12.19h-8.661v-12.19h8.66v12.19zm1.675-13.148H19.706v-4.64h10.336v4.64z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Gift.displayName = "Gift"

Gift.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Gift.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Gift;
