import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Modal.module.scss';

const cx = classNames.bind(styles);

const ModalHeader = ({ children, hasTitle, ...rest }) => (
  <div className={cx('modal__header')} {...rest}>
    <div className={cx({ 'has-title': hasTitle })}>{children}</div>
  </div>
);

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  hasTitle: PropTypes.bool,
};

ModalHeader.defaultProps = {
  hasTitle: false,
};

ModalHeader.displayName = 'ModalHeader';

ModalHeader.filename = __filename;

ModalHeader.spreadAttributes = true;

export { ModalHeader }; // eslint-disable-line import/prefer-default-export
