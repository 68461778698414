/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Arrowundo = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12.17 8.778c-2.061 0-3.93.77-5.369 2.023L4 8v6.503a.5.5 0 0 0 .5.5h6.503l-2.817-2.817a6.189 6.189 0 0 1 3.985-1.462 6.234 6.234 0 0 1 5.914 4.28c.19.672.594.907 1.212.704.618-.203.828-.64.632-1.312-1.082-3.26-4.14-5.618-7.758-5.618z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Arrowundo.displayName = "Arrowundo"

Arrowundo.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Arrowundo.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Arrowundo;
