/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Trophy = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M16.375 20.375h-8.79a.6.6 0 0 0-.439.186.6.6 0 0 0-.185.439.6.6 0 0 0 .185.44.6.6 0 0 0 .44.185h8.789a.6.6 0 0 0 .44-.186A.6.6 0 0 0 17 21a.6.6 0 0 0-.186-.44.6.6 0 0 0-.439-.185zm5.488-15.742c.013.039.04.488.078 1.347.04.86-.247 1.667-.859 2.422-.352.43-.8.759-1.348.987-.547.228-1.185.348-1.914.361a6.642 6.642 0 0 1-.81 1.64 6.562 6.562 0 0 1-1.201 1.339 6.198 6.198 0 0 1-1.504.937 5.81 5.81 0 0 1-1.739.459v4.355H14.5a.6.6 0 0 1 .44.186.6.6 0 0 1 .185.44.6.6 0 0 1-.186.439.6.6 0 0 1-.439.185H9.46a.617.617 0 0 1-.625-.625.617.617 0 0 1 .626-.625h1.855v-4.355a5.79 5.79 0 0 1-1.718-.459 6.198 6.198 0 0 1-1.504-.937 6.562 6.562 0 0 1-1.201-1.338 6.642 6.642 0 0 1-.811-1.641c-.703-.026-1.322-.15-1.855-.371a3.184 3.184 0 0 1-1.309-.977c-.612-.755-.898-1.562-.86-2.422.04-.859.066-1.308.079-1.347a.57.57 0 0 1 .215-.371.621.621 0 0 1 .39-.137h2.969V3.5a.6.6 0 0 1 .185-.44.6.6 0 0 1 .44-.185h11.23a.617.617 0 0 1 .625.625v.625h3.067a.62.62 0 0 1 .39.137.57.57 0 0 1 .215.37zM3.875 7.62c.208.247.472.443.791.586a3.51 3.51 0 0 0 1.123.273 6.244 6.244 0 0 1-.078-.996V5.375H3.309c-.013.286.01.648.068 1.084.059.436.225.824.498 1.162zm13.066-.137V4.125h-9.98v3.36c0 .755.13 1.461.39 2.119a5.41 5.41 0 0 0 1.075 1.718 5.2 5.2 0 0 0 1.592 1.162c.605.287 1.253.43 1.943.43.69 0 1.338-.143 1.943-.43a5.082 5.082 0 0 0 1.582-1.162 5.503 5.503 0 0 0 1.065-1.718c.26-.658.39-1.364.39-2.12zm3.184.137a2.3 2.3 0 0 0 .498-1.162c.059-.436.081-.798.068-1.084h-2.5v2.11a6.137 6.137 0 0 1-.078.995c.456-.026.85-.113 1.182-.263a2.45 2.45 0 0 0 .83-.596z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Trophy.displayName = "Trophy"

Trophy.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Trophy.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Trophy;
