import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ReactSwitch from 'react-switch';
import Color from 'color';
import { Caption } from '../Typography';

import ThemeContext, { isColor } from '../theme';
import { classNamePropShape } from '../../prop-shapes';

import styles from './Toggle.module.scss';

const cx = classNames.bind(styles);

const Toggle = ({
  checked,
  className,
  disabled,
  id,
  offLabel,
  subLabel,
  onLabel,
  labelWidth,
  onChange,
}) => {
  const theme = useContext(ThemeContext);
  const themeProps = {};

  if (isColor(theme?.color?.primary)) {
    themeProps.onColor = Color(theme.color.primary).hex();
  }

  return (
    <div className={cx('toggle__container', className)}>
      <ReactSwitch
        checked={checked}
        checkedIcon={false}
        className={cx('toggle', {
          'toggle--checked': checked,
          'toggle--disabled': disabled,
          'toggle--no-custom-theme': !theme?.color?.primary,
          'toggle--with-custom-theme': !!theme?.color?.primary,
        })}
        disabled={disabled}
        handleDiameter={18}
        height={24}
        id={id}
        uncheckedIcon={false}
        width={40}
        onChange={onChange}
        onColor={themeProps.onColor}
      />
      <div className={cx('toggle__label-container')}>
        <label
          className={cx('toggle__label', {
            'toggle__label--disabled': disabled,
          })}
          htmlFor={id}
          style={{
            width: labelWidth,
            color: theme?.color?.text || null,
          }}
        >
          {checked ? onLabel : offLabel}
        </label>

        {subLabel && (
          <Caption className={cx('toggle__sublabel')}>{subLabel}</Caption>
        )}
      </div>
    </div>
  );
};

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired, // eslint-disable-line react/boolean-prop-naming
  className: classNamePropShape,
  disabled: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
  id: PropTypes.string.isRequired,
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offLabel: PropTypes.string,
  subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  onLabel: PropTypes.string,
};

Toggle.defaultProps = {
  className: null,
  disabled: false,
  labelWidth: null,
  offLabel: null,
  subLabel: null,
  onLabel: null,
};

Toggle.displayName = 'Toggle';

Toggle.filename = __filename;

export default Toggle;
