import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Label from '../Label';
import { classNamePropShape } from '../../prop-shapes';

import styles from './Textarea.module.scss';

const cx = classNames.bind(styles);

const Textarea = ({
  ariaLabel,
  className,
  formText,
  hasError,
  id,
  label,
  noResize,
  ...props
}) => (
  <div className={cx('toga-form-group', 'textarea__box')}>
    {label && (
      <Label className={cx('textarea__label')} htmlFor={id}>
        {label}
      </Label>
    )}
    <textarea
      aria-describedby={`${id}--msg`}
      aria-label={ariaLabel}
      className={cx('toga-form-control', 'textarea__textarea', className, {
        'has-error': hasError,
        'no-resize': noResize,
      })}
      id={id}
      {...props}
    />
    <div aria-live="polite" id={`${id}--msg`} role="alert">
      {formText && (
        <span
          className={cx('toga-form-text', {
            'has-error': hasError,
          })}
        >
          {formText}
        </span>
      )}
    </div>
  </div>
);

// eslint-disable-next-line no-unused-vars
const determineAriaLabelIsNeeded = (props, _propName, _componentName) => {
  const { ariaLabel, label } = props;

  if (!ariaLabel && !label) {
    return new Error('Either ariaLabel or label must be provided');
  }

  if (!ariaLabel && label && typeof label !== 'string') {
    return new Error('label is not type of string. ariaLabel must be provided');
  }

  if (ariaLabel && typeof ariaLabel !== 'string') {
    return new Error('ariaLabel must be type of string');
  }

  return null;
};

Textarea.propTypes = {
  /** Required if no label is provided for screenreaders */
  ariaLabel: determineAriaLabelIsNeeded,
  /** CSS classes applied to textarea */
  className: classNamePropShape,
  /** Optional form text */
  formText: PropTypes.string,
  /** Optional bool to set error style */
  hasError: PropTypes.bool,
  id: PropTypes.string.isRequired,
  /** Optional label */
  label: PropTypes.node,
  /** Optional bool to disable resize */
  noResize: PropTypes.bool,
};

Textarea.defaultProps = {
  ariaLabel: null,
  className: null,
  formText: null,
  hasError: false,
  label: null,
  noResize: false,
};

Textarea.displayName = 'Textarea';

Textarea.filename = __filename;

Textarea.spreadAttributes = true;

export default Textarea;
