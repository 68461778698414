/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Magnifyingglass = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M18.786 17.753l-3.381-3.382a5.82 5.82 0 0 0-.483-7.669A5.774 5.774 0 0 0 10.812 5a5.773 5.773 0 0 0-4.11 1.702A5.773 5.773 0 0 0 5 10.812c0 1.552.605 3.012 1.702 4.11a5.774 5.774 0 0 0 4.11 1.702c1.306 0 2.545-.43 3.56-1.219l3.38 3.381a.728.728 0 0 0 1.034 0 .731.731 0 0 0 0-1.033zm-11.05-3.865a4.323 4.323 0 0 1-1.275-3.076 4.32 4.32 0 0 1 1.275-3.076 4.321 4.321 0 0 1 3.076-1.275c1.162 0 2.255.453 3.077 1.275a4.355 4.355 0 0 1 0 6.152 4.323 4.323 0 0 1-3.077 1.275 4.323 4.323 0 0 1-3.077-1.275z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Magnifyingglass.displayName = "Magnifyingglass"

Magnifyingglass.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Magnifyingglass.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Magnifyingglass;
