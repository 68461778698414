/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Messages = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.6 0.6)" d="M6.83082 25.8462C7.4086 25.8462 7.87698 25.3778 7.87698 24.8001C7.87698 24.2223 7.4086 23.7539 6.83082 23.7539C6.25305 23.7539 5.78467 24.2223 5.78467 24.8001C5.78467 25.3778 6.25305 25.8462 6.83082 25.8462Z"/>
  <path transform="scale(0.6 0.6)" d="M13.7844 25.8462C14.3622 25.8462 14.8306 25.3778 14.8306 24.8001C14.8306 24.2223 14.3622 23.7539 13.7844 23.7539C13.2067 23.7539 12.7383 24.2223 12.7383 24.8001C12.7383 25.3778 13.2067 25.8462 13.7844 25.8462Z"/>
  <path transform="scale(0.6 0.6)" d="M20.7385 25.8462C21.3163 25.8462 21.7847 25.3778 21.7847 24.8001C21.7847 24.2223 21.3163 23.7539 20.7385 23.7539C20.1608 23.7539 19.6924 24.2223 19.6924 24.8001C19.6924 25.3778 20.1608 25.8462 20.7385 25.8462Z"/>
  <path transform="scale(0.6 0.6)" d="M13.6614 39.5077C13.4767 39.5077 13.2306 39.3847 13.1691 39.2L10.4614 34.8924H1.35367C0.984435 34.8924 0.738281 34.6462 0.738281 34.277V16.4308C0.738281 16.0616 0.984435 15.8154 1.35367 15.8154H26.0306C26.3998 15.8154 26.646 16.0616 26.646 16.4308V34.277C26.646 34.6462 26.3998 34.8924 26.0306 34.8924H16.9229L14.2152 39.2616C14.0921 39.4462 13.9075 39.5077 13.6614 39.5077ZM1.96905 33.6616H10.8306C11.0152 33.6616 11.2614 33.7847 11.3229 33.9693L13.7229 37.7847L16.1229 33.9693C16.246 33.7847 16.4306 33.6616 16.6152 33.6616H25.4767V17.0462H1.96905V33.6616Z"/>
  <path transform="scale(0.6 0.6)" d="M19.323 12.7383C19.9008 12.7383 20.3692 12.2699 20.3692 11.6922C20.3692 11.1144 19.9008 10.646 19.323 10.646C18.7452 10.646 18.2769 11.1144 18.2769 11.6922C18.2769 12.2699 18.7452 12.7383 19.323 12.7383Z"/>
  <path transform="scale(0.6 0.6)" d="M26.2766 12.7383C26.8544 12.7383 27.3228 12.2699 27.3228 11.6922C27.3228 11.1144 26.8544 10.646 26.2766 10.646C25.6988 10.646 25.2305 11.1144 25.2305 11.6922C25.2305 12.2699 25.6988 12.7383 26.2766 12.7383Z"/>
  <path transform="scale(0.6 0.6)" d="M33.2922 12.7383C33.87 12.7383 34.3384 12.2699 34.3384 11.6922C34.3384 11.1144 33.87 10.646 33.2922 10.646C32.7145 10.646 32.2461 11.1144 32.2461 11.6922C32.2461 12.2699 32.7145 12.7383 33.2922 12.7383Z"/>
  <path transform="scale(0.6 0.6)" d="M38.5229 21.7844H29.7229C29.3537 21.7844 29.1075 21.5383 29.1075 21.1691C29.1075 20.7998 29.3537 20.5537 29.7229 20.5537H37.9691V3.93829H13.846V12.246C13.846 12.6152 13.5998 12.8614 13.2306 12.8614C12.8614 12.8614 12.6152 12.6152 12.6152 12.246V3.3229C12.6152 2.95367 12.8614 2.70752 13.2306 2.70752H38.5229C38.8922 2.70752 39.1383 2.95367 39.1383 3.3229V21.1691C39.1383 21.4768 38.8922 21.7844 38.5229 21.7844Z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Messages.displayName = "Messages"

Messages.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Messages.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Messages;
