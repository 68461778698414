/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Location = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12 20.75c-1.948 0-7.75-7.296-7.75-11.1 0-4.45 3.285-7.4 7.75-7.4s7.75 2.95 7.75 7.4c0 3.804-5.802 11.1-7.75 11.1zm0-1.5c-.1 0-.008-.046.121-.136a8.57 8.57 0 0 0 .834-.693 22.77 22.77 0 0 0 2.286-2.489c1.855-2.331 3.009-4.623 3.009-6.282 0-3.583-2.58-5.9-6.25-5.9s-6.25 2.317-6.25 5.9c0 1.659 1.154 3.95 3.009 6.282a22.77 22.77 0 0 0 2.286 2.49c.323.297.61.535.834.692.13.09.221.136.121.136z"/><path transform="scale(1 1)" d="M12 13.75a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5zm0-1.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Location.displayName = "Location"

Location.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Location.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Location;
