/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Circleslash = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12 4c-4.416 0-8 3.584-8 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm0 14.4A6.398 6.398 0 0 1 5.6 12c0-1.48.504-2.84 1.352-3.92l8.968 8.968A6.322 6.322 0 0 1 12 18.4zm5.048-2.48L8.08 6.952A6.322 6.322 0 0 1 12 5.6c3.536 0 6.4 2.864 6.4 6.4 0 1.48-.504 2.84-1.352 3.92z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Circleslash.displayName = "Circleslash"

Circleslash.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Circleslash.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Circleslash;
