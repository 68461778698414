/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Heartfill = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" fillRule="evenodd" d="M12.0009939,5.09691001 C12.5647974,4.5618729 13.5865214,3.58080957 13.6442229,3.50171037 C15.2521899,1.8318815 16.7894385,1.42987486 18.2280896,1.50945993 C20.6682594,1.64453105 22.7837856,3.32670095 23.5842235,5.58209714 C24.2836352,7.55306658 24.0969311,9.44901811 23.0521849,11.2518775 C22.8230304,11.6471791 22.5346202,12.0197422 22.2197878,12.3517839 C18.8560059,15.8992957 15.4814415,19.4365071 12.109597,22.9763422 C12.0819119,23.0054942 12.0510212,23.0315367 11.9994396,23.0798319 C11.9417382,23.0252204 11.8836481,22.9749818 11.8308037,22.9195929 C8.92231946,19.8663054 6.00985246,16.8169048 3.10943089,13.7560377 C2.4204132,13.0288887 1.7028362,12.3130117 1.12086736,11.5038483 C0.195798212,10.2175631 -0.126222603,8.73362704 0.0432875996,7.15407232 C0.166461776,6.0059678 0.552692473,4.95027525 1.23792168,4.01896474 C2.22584075,2.67593024 3.53179786,1.82459349 5.19532923,1.58107678 C7.11647724,1.29985677 8.7677689,1.87648412 10.1675638,3.20708041" id="Like" fill="#36394D"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Heartfill.displayName = "Heartfill"

Heartfill.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Heartfill.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Heartfill;
