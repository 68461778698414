/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Checkboxselected = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M4 6.347A2.348 2.348 0 0 1 6.347 4h11.306A2.348 2.348 0 0 1 20 6.347v11.306A2.348 2.348 0 0 1 17.653 20H6.347A2.348 2.348 0 0 1 4 17.653V6.347zm14 2.709c.379-.518.379-.97 0-1.354-.379-.385-.808-.464-1.286-.239L10 14.184l-2.338-2.37c-.532-.223-.96-.161-1.284.186-.324.347-.354.795-.09 1.344l3.005 3.005a1 1 0 0 0 1.414 0L18 9.056z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Checkboxselected.displayName = "Checkboxselected"

Checkboxselected.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Checkboxselected.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Checkboxselected;
