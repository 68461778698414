/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Arrowup = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12.852 6.897l.008 12.233a.874.874 0 0 1-.252.616.852.852 0 0 1-1.216 0 .874.874 0 0 1-.252-.616V6.906L7.467 10.63a.87.87 0 0 1-1.216 0A.872.872 0 0 1 6 10.015a.87.87 0 0 1 .252-.616l5.043-5.1a.99.99 0 0 1 1.413 0l5.043 5.097a.874.874 0 0 1 .251.616.866.866 0 0 1-.86.872.851.851 0 0 1-.607-.256l-3.682-3.73z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Arrowup.displayName = "Arrowup"

Arrowup.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Arrowup.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Arrowup;
