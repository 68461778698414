import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Grid.module.scss';

const cx = classNames.bind(styles);

const Container = ({ children, className, fluid, ...props }) => {
  const classes = cx(className, {
    container: !fluid,
    'container-fluid': fluid,
  });

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

Container.propTypes = {
  /** Content for the badge */
  children: PropTypes.node.isRequired,
  /** A className applied to outermost element */
  className: classNamePropShape,
  /** Container is fluid */
  fluid: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
};

Container.defaultProps = {
  className: null,
  fluid: false,
};

Container.displayName = 'Container';

Container.filename = __filename;

Container.spreadAttributes = true;

export default Container;
