/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contentvideo = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M14.05 17.931H2.057A1.428 1.428 0 0 1 .63 16.517V7.194a1.43 1.43 0 0 1 1.426-1.426H14.05c.789 0 1.427.638 1.429 1.426v9.322a1.428 1.428 0 0 1-1.429 1.415zM2.057 6.876a.33.33 0 0 0-.33.329v9.311a.33.33 0 0 0 .33.33H14.05c.183 0 .33-.148.332-.33V7.194a.332.332 0 0 0-.33-.33H2.058v.012zM22.64 17.849h-.005a.714.714 0 0 1-.274-.054l-.06-.028-5.22-2.926a.72.72 0 0 1-.41-.65V9.489c0-.286.167-.532.41-.647l5.295-2.93a.722.722 0 0 1 .985.672V17.13a.722.722 0 0 1-.722.722l.001-.002zm-4.87-3.883l4.495 2.518V7.22l-4.494 2.504v4.241z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contentvideo.displayName = "Contentvideo"

Contentvideo.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contentvideo.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contentvideo;
