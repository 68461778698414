/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Microphone = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <g transform="translate(7 2)" fillRule="evenodd"><path transform="scale(0.75 0.75)" d="M8.806 21.18c-3.567 0-6.503-2.935-6.503-6.503V6.774C2.303 3.206 5.24.271 8.806.271c3.568 0 6.504 2.935 6.504 6.503v7.903c0 3.568-2.936 6.504-6.504 6.504zm0-20.006c-3.07 0-5.6 2.53-5.6 5.6v7.903c0 3.071 2.53 5.6 5.6 5.6 3.071 0 5.6-2.529 5.6-5.6V6.774c0-3.07-2.529-5.6-5.6-5.6z" fillRule="nonzero"/><path transform="scale(0.75 0.75)" d="M8.806 27.684c-.27 0-.451-.18-.451-.452v-4.47c0-.272.18-.452.451-.452s.452.18.452.451v4.471c0 .226-.18.452-.452.452zM5.826 8.265H2.755c-.271 0-.452-.181-.452-.452s.18-.452.452-.452h3.07c.272 0 .452.18.452.452a.453.453 0 0 1-.451.452zm0 2.709H2.755c-.271 0-.452-.18-.452-.451s.18-.452.452-.452h3.07c.272 0 .452.18.452.452a.453.453 0 0 1-.451.451zm0 3.432H2.755c-.271 0-.452-.18-.452-.451s.18-.452.452-.452h3.07c.272 0 .452.18.452.452a.453.453 0 0 1-.451.451zm9.032-6.141h-3.07c-.272 0-.453-.181-.453-.452s.181-.452.452-.452h3.071c.271 0 .452.18.452.452 0 .27-.181.452-.452.452zm0 2.709h-3.07c-.272 0-.453-.18-.453-.451s.181-.452.452-.452h3.071c.271 0 .452.18.452.452 0 .27-.181.451-.452.451zm0 3.432h-3.07c-.272 0-.453-.18-.453-.451s.181-.452.452-.452h3.071c.271 0 .452.18.452.452 0 .27-.181.451-.452.451z"/><path transform="scale(0.75 0.75)" d="M8.806 23.168c-4.606 0-8.4-3.749-8.4-8.4v-2.123c0-.27.181-.451.452-.451s.452.18.452.451v2.123c0 4.11 3.342 7.497 7.496 7.497a7.478 7.478 0 0 0 7.497-7.497v-2.123c0-.27.18-.451.452-.451.27 0 .451.18.451.451v2.123c0 4.651-3.793 8.4-8.4 8.4zm7.723 4.516H1.716c-.27 0-.451-.18-.451-.452 0-.27.18-.451.451-.451h14.858c.271 0 .452.18.452.451s-.226.452-.497.452z"/><circle cx="8.897" cy="5.148" r="1"/></g>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Microphone.displayName = "Microphone"

Microphone.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Microphone.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Microphone;
