import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Grid.module.scss';

const cx = classNames.bind(styles);

const Row = ({ children, className, ...props }) => {
  const classes = cx('row', className);

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

Row.propTypes = {
  /** Content for the badge */
  children: PropTypes.node.isRequired,
  /** CSS classes */
  className: classNamePropShape,
};

Row.defaultProps = {
  className: null,
};

Row.displayName = 'Row';

Row.filename = __filename;

Row.spreadAttributes = true;

export default Row;
