/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Viewdevicedesktop = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M19.364 4H4.636C3.736 4 3 4.736 3 5.636v9.819c0 .9.736 1.636 1.636 1.636h5.728v1.636H8.727v1.637h6.546v-1.637h-1.637v-1.636h5.728c.9 0 1.636-.736 1.636-1.636V5.636C21 4.736 20.264 4 19.364 4zm0 11.455H4.636V5.636h14.728v9.819z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Viewdevicedesktop.displayName = "Viewdevicedesktop"

Viewdevicedesktop.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Viewdevicedesktop.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Viewdevicedesktop;
