/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Refresh = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M14.907 15.717a.804.804 0 0 1 1.114.196.787.787 0 0 1-.196 1.103 6.663 6.663 0 0 1-3.812 1.199 6.86 6.86 0 0 1-.665-.037c-.045-.004-.089-.012-.134-.017-.123-.015-.247-.027-.37-.049-.037-.006-.072-.016-.109-.023-.088-.018-.175-.04-.262-.06a4.448 4.448 0 0 1-.596-.168 6.249 6.249 0 0 1-.43-.155l-.123-.055a6.35 6.35 0 0 1-.485-.232l-.044-.025a6.542 6.542 0 0 1-1.498-1.113l-.037-.038a6.897 6.897 0 0 1-.384-.418c-.032-.038-.06-.079-.092-.118-.082-.103-.167-.203-.244-.313-.01-.014-.017-.03-.026-.045a6.521 6.521 0 0 1-1.18-3.744H4l2.133-3.171 2.134 3.17H6.933c0 1.183.418 2.27 1.111 3.13l.06.074c.096.115.197.225.302.33l.017.018c.34.337.728.625 1.152.855l.017.01c.126.067.255.127.386.184a4.787 4.787 0 0 0 .419.158c.048.016.095.034.143.048.1.03.203.054.306.077.063.016.126.031.19.044.107.02.214.035.321.048.056.008.113.017.168.022.137.012.276.018.415.02a5.056 5.056 0 0 0 2.967-.905zm3.76-4.112H20l-2.133 3.17-2.134-3.17h1.334a4.977 4.977 0 0 0-1.15-3.18l-.01-.012a5.083 5.083 0 0 0-1.91-1.42 4.949 4.949 0 0 0-.418-.155c-.036-.01-.071-.024-.106-.034a4.93 4.93 0 0 0-.344-.088c-.051-.011-.102-.025-.154-.035a4.893 4.893 0 0 0-.358-.054l-.134-.017a5.058 5.058 0 0 0-3.388.883.804.804 0 0 1-1.114-.194.788.788 0 0 1 .196-1.104 6.653 6.653 0 0 1 3.806-1.193L12.001 5c.031 0 .062.004.094.005.176.002.352.01.53.026.073.007.145.019.217.028.107.013.212.021.317.04.039.006.076.018.115.025.079.015.156.035.234.053.142.033.281.066.42.107.057.017.113.037.17.057.16.051.316.107.47.17l.08.035A6.594 6.594 0 0 1 17.136 7.4c.013.014.023.03.035.044.1.122.2.244.291.373.013.018.02.036.03.054a6.527 6.527 0 0 1 1.176 3.735z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Refresh.displayName = "Refresh"

Refresh.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Refresh.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Refresh;
