import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { classNamePropShape } from '../../../prop-shapes';

import styles from '../Section.module.scss';

const cx = classNames.bind(styles);

export const SectionHeader = ({
  headerClassName,
  headerTitleClassName,
  rightAccessory,
  title,
}) => (
  <div className={cx('section__header', headerClassName)}>
    <h2 className={cx('section__header__title', headerTitleClassName)}>
      {title}
    </h2>
    {rightAccessory && (
      <div className={cx('section__header__actions')}>{rightAccessory}</div>
    )}
  </div>
);

SectionHeader.propTypes = {
  /** Optional classnames (array or string or object) */
  headerClassName: classNamePropShape,
  /** Optional classnames (array or string or object) */
  headerTitleClassName: classNamePropShape,
  /** Action buttons */
  rightAccessory: PropTypes.node,
  /** Section title (rendered as a H4) */
  title: PropTypes.string.isRequired,
};

SectionHeader.defaultProps = {
  headerClassName: null,
  headerTitleClassName: null,
  rightAccessory: null,
};

SectionHeader.filename = __filename;

SectionHeader.displayName = 'SectionHeader';
