import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { DeleteButton } from './DeleteButton/DeleteButton';
import { DownloadButton } from './DownloadButton/DownloadButton';
import { UploadButton } from './UploadButton/UploadButton';
import { FILESTACK_CDN_URL } from './constants';

import { classNamePropShape } from '../../prop-shapes';

import styles from './ImageInput.module.scss';

const cx = classNames.bind(styles);

export function ImageInput({
  awsBucket,
  awsPath,
  awsRegion,
  buttonLabel,
  className,
  clientOptions,
  filestackAPIKey,
  filestackOptions,
  height,
  initialImageUrl,
  isDisabled,
  onChange,
  onDelete,
  onError,
  placeholderImageUrl,
  showDownloadBtn,
  wrapperClass,
}) {
  const [imageUrl, setImageUrl] = React.useState(initialImageUrl);

  React.useEffect(() => {
    setImageUrl(initialImageUrl);
  }, [initialImageUrl]);

  const previewImageUrl = imageUrl || placeholderImageUrl;

  function handleDelete() {
    setImageUrl(null);
    onDelete();
  }

  function handleUploadError(error) {
    onError(error.message);
  }

  function handleUploadSuccess(event) {
    const { filesFailed, filesUploaded } = event;

    if (filesFailed.length > 0) {
      const file = filesFailed[0];
      onError(`Failed to upload ${file.filename}`);
    }

    if (filesUploaded.length > 0) {
      const file = filesUploaded[0];
      const securityParams = `security=policy:${clientOptions.security.policy},signature:${clientOptions.security.signature}/`;
      const url = FILESTACK_CDN_URL + securityParams + file.handle;

      setImageUrl(url);
      onChange(file);
    }
  }

  return (
    <figure
      className={cx('figure__uploader', wrapperClass, className)}
      style={{ height }}
    >
      {previewImageUrl && <img alt="Upload preview" src={previewImageUrl} />}
      <figcaption>
        <UploadButton
          awsBucket={awsBucket}
          awsPath={awsPath}
          awsRegion={awsRegion}
          buttonLabel={buttonLabel}
          clientOptions={clientOptions}
          filestackAPIKey={filestackAPIKey}
          filestackOptions={filestackOptions}
          isDisabled={isDisabled}
          onError={handleUploadError}
          onSuccess={handleUploadSuccess}
        />
        {imageUrl && (
          <div>
            {showDownloadBtn && <DownloadButton imageUrl={imageUrl} />}

            <DeleteButton isDisabled={isDisabled} onClick={handleDelete} />
          </div>
        )}
      </figcaption>
    </figure>
  );
}

ImageInput.propTypes = {
  /** The AWS bucket the file will be uploaded to */
  awsBucket: PropTypes.string.isRequired,
  /** The path within the bucket the file will be stored in */
  awsPath: PropTypes.string.isRequired,
  /** The AWS region */
  awsRegion: PropTypes.string.isRequired,
  /** The button label */
  buttonLabel: PropTypes.string,
  /** A className applied to outermost element */
  className: classNamePropShape,
  /** Filestack initialization options */
  clientOptions: PropTypes.object,
  /** Our filestack API key */
  filestackAPIKey: PropTypes.string.isRequired,
  /** Filestack options */
  filestackOptions: PropTypes.object,
  /** The height of the component. Allow 'auto' string. */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
  /** The initial image to show in the preview, will be replaced when a new image is uploaded */
  initialImageUrl: PropTypes.string,
  /** Disabled the component and shows the (disabled) upload button */
  isDisabled: PropTypes.bool,
  /** The image to show in the preview when no image has been uploaded */
  placeholderImageUrl: PropTypes.string,
  /** Shows the download image button next to the delete button */
  showDownloadBtn: PropTypes.bool,
  /** Class attached to the wrapper */
  wrapperClass: PropTypes.string,
  /** Callback for when an image is uploaded. It receives a filestack file object */
  onChange: PropTypes.func,
  /** Callback for when the delete button is clicked */
  onDelete: PropTypes.func,
  /** Callback for when there is an error, either a filestack error or image fails to upload */
  onError: PropTypes.func,
};

ImageInput.defaultProps = {
  buttonLabel: 'Upload',
  className: null,
  clientOptions: {},
  filestackOptions: {},
  height: 160,
  initialImageUrl: null,
  isDisabled: false,
  placeholderImageUrl: null,
  showDownloadBtn: false,
  wrapperClass: null,
  onChange: () => {},
  onDelete: () => {},
  onError: () => {},
};

ImageInput.filename = __filename;

ImageInput.displayName = 'ImageInput';
