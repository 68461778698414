/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Windowminimize = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M11.155 18.996L4.846 19a.783.783 0 0 1-.598-.293 1.131 1.131 0 0 1 0-1.413.783.783 0 0 1 .598-.292h6.309l1.69-.002h6.309c.216 0 .433.098.598.293.33.39.33 1.022 0 1.413a.783.783 0 0 1-.598.292l-6.316-.009-1.683.007z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Windowminimize.displayName = "Windowminimize"

Windowminimize.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Windowminimize.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Windowminimize;
