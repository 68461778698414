/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Systemcalendar = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" fillRule="evenodd" clipRule="evenodd" d="M18.0156 4.77123H19.5745L19.5707 4.77138C19.5973 4.77043 19.6241 4.76995 19.6509 4.76995C20.9227 4.76995 21.954 5.84505 21.9561 7.17231V14.8227C21.9561 18.0978 19.412 20.9667 16.267 20.9693H6.63015C4.78307 20.9693 3 19.2007 3 17.2726V7.17228C3 5.79951 4.35453 4.77123 5.67705 4.77123H6.93307V3.64268C6.93307 3.28775 7.20874 3 7.54877 3C7.8888 3 8.16446 3.28775 8.16446 3.64268V4.77123H16.7842V3.64268C16.7842 3.28775 17.0599 3 17.3999 3C17.7399 3 18.0156 3.28775 18.0156 3.64268V4.77123ZM5.66967 5.54244C4.74365 5.54244 3.73145 6.20571 3.73145 7.17228V8.6273H21.2172V7.17228C21.2172 6.20312 20.5005 5.54244 19.5745 5.54244H18.0156V5.96661C18.0156 6.32154 17.7399 6.60929 17.3999 6.60929C17.0599 6.60929 16.7842 6.32154 16.7842 5.96661V5.54244H8.16446V5.96661C8.16446 6.32154 7.8888 6.60929 7.54877 6.60929C7.20874 6.60929 6.93307 6.32154 6.93307 5.96661V5.54244H5.66967ZM17.4984 16.0824L17.523 20.0258L17.5588 20.0078C19.3263 19.4549 20.7128 17.9821 21.2172 16.0824H17.4984ZM17.1191 15.4126H21.2172V9.49999H3.73141V17.3715C3.73141 18.8882 5.17214 20.297 6.62273 20.297L16.2755 20.2967C16.4583 20.2898 16.6385 20.2723 16.7899 20.2523C16.7807 20.2115 16.7754 20.169 16.7744 20.1248L16.7497 15.7545C16.7502 15.653 16.7917 15.5617 16.8576 15.4979C16.9183 15.4436 16.9973 15.4107 17.0835 15.4107C17.0961 15.4107 17.1085 15.4114 17.1191 15.4126Z" fill="#36394D"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Systemcalendar.displayName = "Systemcalendar"

Systemcalendar.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Systemcalendar.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Systemcalendar;
