/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Help = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M30.444 16.243a14.147 14.147 0 0 0-2.96-8.671c.029-.102.044-.206.045-.312 0-.697-.565-1.262-1.262-1.262a1.147 1.147 0 0 0-.178 0c-5.498-5.33-14.236-5.33-19.733 0a1.282 1.282 0 0 0-.276-.03 1.249 1.249 0 0 0-1.2 1.728 14.182 14.182 0 0 0 .04 17.187 1.258 1.258 0 0 0 1.147 1.777c.137-.002.274-.026.404-.07 5.456 5.163 13.992 5.174 19.462.026a1.253 1.253 0 0 0 1.614-1.231 1.231 1.231 0 0 0-.08-.445 14.147 14.147 0 0 0 2.977-8.697zM7.227 6.732c5.016-4.768 12.879-4.799 17.933-.072-.103.184-.158.39-.16.6 0 .168.033.334.098.49l-4.334 4.337a6.133 6.133 0 0 0-9.182.125L7.204 7.829c.091-.176.14-.37.143-.569a1.262 1.262 0 0 0-.12-.528zm14.049 9.51a5.049 5.049 0 1 1-10.098 0 5.049 5.049 0 0 1 10.098 0zM6.08 24.14a1.511 1.511 0 0 0-.284.036 13.067 13.067 0 0 1-.103-15.698c.125.042.255.065.387.067.075 0 .149-.008.222-.022l4.622 4.626a6.12 6.12 0 0 0 .152 6.476l-4.592 4.591a1.209 1.209 0 0 0-.404-.076zm10.151 5.227a13.044 13.044 0 0 1-8.96-3.555c.048-.13.074-.267.076-.405a1.258 1.258 0 0 0-.076-.409l4.502-4.498a6.142 6.142 0 0 0 8.8.125l4.471 4.471a1.169 1.169 0 0 0 .058.8 13.044 13.044 0 0 1-8.87 3.449v.022zm10.427-5.182a1.204 1.204 0 0 0-.378-.067 1.271 1.271 0 0 0-.502.107l-4.476-4.48a6.133 6.133 0 0 0 .156-6.769l4.498-4.498c.105.029.214.044.324.045.163-.001.324-.033.476-.094a13.076 13.076 0 0 1-.098 15.756z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Help.displayName = "Help"

Help.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Help.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Help;
