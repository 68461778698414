/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Presell = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M18.474 19.452a.98.98 0 1 0-.007 1.96.98.98 0 0 0 .007-1.96zm-2.543-4.722a2.973 2.973 0 1 0 0-5.945 2.973 2.973 0 0 0 0 5.946zm0-5.081a2.113 2.113 0 1 1 0 4.225 2.113 2.113 0 0 1 0-4.225z"/><path transform="scale(0.75 0.75)" d="M19.083 16.527h-6.304a3.097 3.097 0 0 0-3.096 3.097v6.71h12.49V19.62a3.097 3.097 0 0 0-3.09-3.093zm2.233 8.946h-10.77v-5.85c0-1.233 1-2.234 2.233-2.236h6.304a2.237 2.237 0 0 1 2.25 2.233l-.017 5.853zm4.022-14.193a2.973 2.973 0 1 0 0-5.946 2.973 2.973 0 0 0 0 5.946zm0-5.083a2.113 2.113 0 1 1 0 4.226 2.113 2.113 0 0 1 0-4.226z"/><path transform="scale(0.75 0.75)" d="M28.831 13.048h-6.3a3.097 3.097 0 0 0-2.918 2.04l.809.293a2.24 2.24 0 0 1 2.102-1.473h6.307c1.234 0 2.235 1 2.237 2.234v5.849h-7.601v.86h8.46v-6.71a3.097 3.097 0 0 0-3.096-3.093zM5.726 11.28a2.973 2.973 0 1 1 0-5.946 2.973 2.973 0 0 1 0 5.946zm0-5.083a2.113 2.113 0 1 0 0 4.226 2.113 2.113 0 0 0 0-4.226zm-2.629 6.851h6.3a3.097 3.097 0 0 1 2.918 2.04l-.809.293a2.24 2.24 0 0 0-2.102-1.473H3.097c-1.234 0-2.235 1-2.237 2.234v5.849h7.601v.86H0v-6.71a3.097 3.097 0 0 1 3.097-3.093z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Presell.displayName = "Presell"

Presell.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Presell.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Presell;
