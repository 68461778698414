/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Arrowdown = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M11.148 17.262L11.14 4.82c0-.21.084-.42.252-.58a.89.89 0 0 1 1.216 0c.168.16.252.37.252.58v12.431l3.459-3.632a.815.815 0 0 1 .58-.244c.225 0 .429.093.579.244.149.155.24.37.24.608a.877.877 0 0 1-.24.608l-4.755 4.98a1 1 0 0 1-1.447 0l-4.755-4.978a.879.879 0 0 1-.24-.608c0-.475.367-.86.82-.86.226 0 .43.096.579.252l3.468 3.64z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Arrowdown.displayName = "Arrowdown"

Arrowdown.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Arrowdown.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Arrowdown;
