/* eslint-disable eol-last */
/* eslint-disable quote-props */
import Account from './react-icons/product/Account';
import Apps from './react-icons/product/Apps';
import Blank from './react-icons/product/Blank';
import Blog from './react-icons/product/Blog';
import Book from './react-icons/product/Book';
import Bundle from './react-icons/product/Bundle';
import Certificate from './react-icons/product/Certificate';
import Checkmarkmulticolor from './react-icons/product/Checkmarkmulticolor';
import Checkmark from './react-icons/product/Checkmark';
import Clipboard from './react-icons/product/Clipboard';
import Clocksmall from './react-icons/product/Clocksmall';
import Design from './react-icons/product/Design';
import Digitaldownload from './react-icons/product/Digitaldownload';
import Dripschedule from './react-icons/product/Dripschedule';
import Featureafterpurchase from './react-icons/product/Featureafterpurchase';
import Flagship from './react-icons/product/Flagship';
import Gift from './react-icons/product/Gift';
import Handshake from './react-icons/product/Handshake';
import Help from './react-icons/product/Help';
import Lightbulb from './react-icons/product/Lightbulb';
import Logout from './react-icons/product/Logout';
import Manage from './react-icons/product/Manage';
import Marketsell from './react-icons/product/Marketsell';
import Messages from './react-icons/product/Messages';
import Microphone from './react-icons/product/Microphone';
import Minicourse from './react-icons/product/Minicourse';
import Mobile from './react-icons/product/Mobile';
import Noresults from './react-icons/product/Noresults';
import Page from './react-icons/product/Page';
import Payouts from './react-icons/product/Payouts';
import Play from './react-icons/product/Play';
import Presell from './react-icons/product/Presell';
import Preview from './react-icons/product/Preview';
import Resourcelibrary from './react-icons/product/Resourcelibrary';
import Searchinitial from './react-icons/product/Searchinitial';
import Searchnoresult from './react-icons/product/Searchnoresult';
import Settings from './react-icons/product/Settings';
import Students from './react-icons/product/Students';
import Transactions from './react-icons/product/Transactions';
import Updates from './react-icons/product/Updates';
import Upgrade from './react-icons/product/Upgrade';
import Venn from './react-icons/product/Venn';
import Webinarreplay from './react-icons/product/Webinarreplay';

const icons = {
  'product-account': Account,
  'product-apps': Apps,
  'product-blank': Blank,
  'product-blog': Blog,
  'product-book': Book,
  'product-bundle': Bundle,
  'product-certificate': Certificate,
  'product-checkmark-multicolor': Checkmarkmulticolor,
  'product-checkmark': Checkmark,
  'product-clipboard': Clipboard,
  'product-clock-small': Clocksmall,
  'product-design': Design,
  'product-digital-download': Digitaldownload,
  'product-drip-schedule': Dripschedule,
  'product-feature-afterpurchase': Featureafterpurchase,
  'product-flagship': Flagship,
  'product-gift': Gift,
  'product-handshake': Handshake,
  'product-help': Help,
  'product-lightbulb': Lightbulb,
  'product-logout': Logout,
  'product-manage': Manage,
  'product-market-sell': Marketsell,
  'product-messages': Messages,
  'product-microphone': Microphone,
  'product-mini-course': Minicourse,
  'product-mobile': Mobile,
  'product-noresults': Noresults,
  'product-page': Page,
  'product-payouts': Payouts,
  'product-play': Play,
  'product-pre-sell': Presell,
  'product-preview': Preview,
  'product-resource-library': Resourcelibrary,
  'product-search-initial': Searchinitial,
  'product-search-no-result': Searchnoresult,
  'product-settings': Settings,
  'product-students': Students,
  'product-transactions': Transactions,
  'product-updates': Updates,
  'product-upgrade': Upgrade,
  'product-venn': Venn,
  'product-webinar-replay': Webinarreplay,
};

export default icons;
