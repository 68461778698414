/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Searchnoresult = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.3333333333333333 0.3333333333333333)" d="M34 60C19.088 60 7 47.912 7 33S19.088 6 34 6s27 12.088 27 27-12.088 27-27 27zm0-2.998c13.256 0 24.002-10.746 24.002-24.002C58.002 19.744 47.256 8.998 34 8.998 20.744 8.998 9.998 19.744 9.998 33c0 13.256 10.746 24.002 24.002 24.002z"/><path transform="scale(0.3333333333333333 0.3333333333333333)" d="M50.797 52.515c-.558-.562-.155-1.426.63-2.204.785-.779 1.652-1.175 2.21-.613L67.12 63.292a2 2 0 1 1-2.84 2.817L50.797 52.515zM38.531 25.94a1.5 1.5 0 0 1 2.121 2.122l-4.735 4.735 4.735 4.735a1.5 1.5 0 1 1-2.121 2.121l-4.735-4.735-4.735 4.735a1.5 1.5 0 1 1-2.122-2.121l4.735-4.735-4.735-4.735a1.5 1.5 0 0 1 2.122-2.122l4.735 4.735 4.735-4.735z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Searchnoresult.displayName = "Searchnoresult"

Searchnoresult.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Searchnoresult.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Searchnoresult;
