import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import IconsMap from './IconsMap';
import ProductIconsMap from './ProductIconsMap';
import { classNamePropShape } from '../../prop-shapes';

import styles from './Icon.module.scss';

const allIcons = {
  ...IconsMap,
  ...ProductIconsMap,
};
const availableIcons = Object.keys(allIcons);
const cx = classNames.bind(styles);

const Icon = ({ name, style, className, title, description, ...rest }) => {
  const classes = cx('toga-icon', `toga-icon-${name}`, className);
  const IconComponent = allIcons[name] || null;

  return (
    <IconComponent
      className={classes}
      description={description}
      style={style}
      title={title}
      {...rest}
    />
  );
};

Icon.propTypes = {
  className: classNamePropShape,
  description: PropTypes.string,
  height: PropTypes.number,
  name: PropTypes.oneOf(availableIcons).isRequired,
  style: PropTypes.shape({}),
  title: PropTypes.string,
  width: PropTypes.number,
};

Icon.defaultProps = {
  className: null,
  style: {},
  title: null,
  description: '',
  height: 24,
  width: 24,
};

Icon.displayName = 'Icon';

Icon.filename = __filename;

Icon.spreadAttributes = true;

export { availableIcons };
export default Icon;
