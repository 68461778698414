import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Button from '../../Button';
import { SUPPORTED_IMAGE_TYPES } from '../constants';

import styles from '../ImageInput.module.scss';

const ReactFilestack = loadable(() => import('filestack-react'));

const cx = classNames.bind(styles);

export function UploadButton({
  awsBucket,
  awsPath,
  awsRegion,
  buttonLabel,
  clientOptions,
  filestackAPIKey,
  filestackOptions,
  isDisabled,
  onError,
  onSuccess,
}) {
  const actionOptions = {
    accept: SUPPORTED_IMAGE_TYPES,
    storeTo: {
      access: 'public',
      container: awsBucket,
      location: 's3',
      path: awsPath,
      region: awsRegion,
    },
    ...filestackOptions,
  };

  return (
    <ReactFilestack
      actionOptions={actionOptions}
      apikey={filestackAPIKey}
      clientOptions={clientOptions}
      customRender={({ onPick }) => (
        <Button
          appearance="knockout"
          className={cx('upload-button')}
          isDisabled={isDisabled}
          size="small-tight"
          isDark
          onClick={onPick}
        >
          {buttonLabel}
        </Button>
      )}
      onError={onError}
      onSuccess={onSuccess}
    />
  );
}

UploadButton.propTypes = {
  awsBucket: PropTypes.string.isRequired,
  awsPath: PropTypes.string.isRequired,
  awsRegion: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  clientOptions: PropTypes.object,
  filestackAPIKey: PropTypes.string.isRequired,
  filestackOptions: PropTypes.object,
  isDisabled: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
};

UploadButton.defaultProps = {
  buttonLabel: 'Upload',
  clientOptions: {},
  filestackOptions: {},
  isDisabled: false,
  onError: () => {},
  onSuccess: () => {},
};

UploadButton.filename = __filename;

UploadButton.displayName = 'UploadButton';
