/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Socialgooglecolorized = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><path transform="scale(1 1)" d="M12,6.48 C13.69,6.48 14.83,7.21 15.48,7.82 L18.02,5.34 C16.46,3.89 14.43,3 12,3 C8.48,3 5.44,5.02 3.96,7.96 L6.87,10.22 C7.6,8.05 9.62,6.48 12,6.48 L12,6.48 Z" fill="#EA4335" /><path transform="scale(1 1)" d="M20.64,12.2 C20.64,11.46 20.58,10.92 20.45,10.36 L12,10.36 L12,13.7 L16.96,13.7 C16.86,14.53 16.32,15.78 15.12,16.62 L17.96,18.82 C19.66,17.25 20.64,14.94 20.64,12.2 L20.64,12.2 Z" fill="#4285F4" /><path transform="scale(1 1)" d="M6.88,13.78 C6.69,13.22 6.58,12.62 6.58,12 C6.58,11.38 6.69,10.78 6.87,10.22 L3.96,7.96 C3.35,9.18 3,10.55 3,12 C3,13.45 3.35,14.82 3.96,16.04 L6.88,13.78 L6.88,13.78 Z" fill="#FBBC05" /><path transform="scale(1 1)" d="M12,21 C14.43,21 16.47,20.2 17.96,18.82 L15.12,16.62 C14.36,17.15 13.34,17.52 12,17.52 C9.62,17.52 7.6,15.95 6.88,13.78 L3.97,16.04 C5.45,18.98 8.48,21 12,21 L12,21 Z" fill="#34A853" /></g>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Socialgooglecolorized.displayName = "Socialgooglecolorized"

Socialgooglecolorized.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Socialgooglecolorized.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Socialgooglecolorized;
