/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Clocksmall = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M16.102 6.603c6.447.008 11.671 5.233 11.679 11.68 0 6.45-5.229 11.679-11.68 11.679-6.45 0-11.679-5.229-11.679-11.68 0-6.45 5.23-11.679 11.68-11.679zm0 1.899c-5.398.008-9.771 4.383-9.777 9.78a9.781 9.781 0 1 0 9.777-9.78zm0 10.616a.95.95 0 0 1-.95-.95v-6.676a.95.95 0 1 1 1.899 0v6.677a.95.95 0 0 1-.95.949zm0-14.56z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Clocksmall.displayName = "Clocksmall"

Clocksmall.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Clocksmall.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Clocksmall;
