/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Socialfacebook = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M10.397 20v-7.156H8V10h2.397V7.76c0-2.435 1.487-3.76 3.66-3.76 1.04 0 1.934.078 2.193.112v2.544h-1.506c-1.181 0-1.41.563-1.41 1.385V10H16l-.366 2.844h-2.3V20" fill="#3B5998"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Socialfacebook.displayName = "Socialfacebook"

Socialfacebook.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Socialfacebook.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Socialfacebook;
