/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Play = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M13 11l8 5.333-8 5.334V11zm3.111 19.444c-7.916 0-14.333-6.417-14.333-14.333S8.195 1.778 16.11 1.778 30.444 8.195 30.444 16.11s-6.417 14.333-14.333 14.333zm0-1.11c7.303 0 13.222-5.92 13.222-13.223 0-7.302-5.92-13.222-13.222-13.222S2.89 8.809 2.89 16.11c0 7.303 5.92 13.222 13.222 13.222z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Play.displayName = "Play"

Play.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Play.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Play;
