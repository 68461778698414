import { css } from 'styled-components';
import { isColor, accessibleTextColor, fadedColor } from '../theme';

const notificationCSS = css`
  ${({ theme, type }) =>
    theme?.color?.[type] &&
    isColor(theme.color[type]) &&
    `
    background-color: ${fadedColor(theme.color[type])};
    color: ${accessibleTextColor(
      theme.color[type],
      fadedColor(theme.color[type])
    )};

    svg {
      fill: ${theme.color[type]};
    }
  `}
`;

export default notificationCSS;
