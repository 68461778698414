/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Pencil = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M19.26 7.86l-1.52 1.52-3.12-3.12 1.52-1.52c.16-.16.357-.24.59-.24.233 0 .43.08.59.24l1.94 1.94c.16.16.24.357.24.59 0 .233-.08.43-.24.59zM4.5 16.38l9.22-9.22 3.12 3.12-9.22 9.22H4.5v-3.12z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Pencil.displayName = "Pencil"

Pencil.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Pencil.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Pencil;
