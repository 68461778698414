/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contentaudio = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M19.43.894a.591.591 0 0 0-.63.083l-7.157 6.001H5.591A.591.591 0 0 0 5 7.57v8.566c0 .326.265.591.59.591h5.404l7.812 6.304a.591.591 0 0 0 .963-.458V1.428a.591.591 0 0 0-.336-.534h-.003zm-.842 20.44l-7.013-5.66a.586.586 0 0 0-.37-.13H6.183V8.16h5.674a.59.59 0 0 0 .382-.14l6.35-5.325v18.64z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contentaudio.displayName = "Contentaudio"

Contentaudio.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contentaudio.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contentaudio;
