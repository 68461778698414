/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contenttext = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M18.842.192H5.082c-1.402 0-2.323 1.28-2.323 2.691v18.362c0 1.402.96 2.592 2.352 2.592 0 0 10.88 0 10.966-.054a5.558 5.558 0 0 0 5.235-5.546V2.881c0-1.398-1.072-2.688-2.47-2.688V.192zm-2.33 22.259v-3.699h3.52c-.352 1.92-1.6 3.424-3.52 3.699zm3.52-4.979h-4.224c-.352 0-.579.573-.579.925v3.875H5.072c-.694 0-1.043-.32-1.043-1.027V2.88c0-.694.349-1.411 1.043-1.411h13.76c.694 0 1.19.717 1.19 1.411v14.592h.01z"/><path transform="scale(1 1)" d="M17.213 14.272H6.973a.48.48 0 0 0 0 .96h10.24a.48.48 0 0 0 0-.96zm-3.84 2.88h-6.4a.48.48 0 0 0 0 .96h6.4a.48.48 0 0 0 0-.96zM7.872 11.04c0 .506.275.96.797.96a.832.832 0 0 0 .803-.926V6.271h1.84a.779.779 0 0 0 .689-.772L12 5.47v-.009a.79.79 0 0 0-.79-.79l-.047.001H6.26l-.045-.001a.79.79 0 0 0-.79.79v.024c0 .4.302.73.691.773h1.751v4.781l.005.001zm3.542-3.235a.756.756 0 0 0 .199.487l.985 1.206-1.082 1.357a.651.651 0 0 0-.157.426v.016c0 .3.244.544.544.544h.017a.687.687 0 0 0 .519-.317l.84-1.2.851 1.187a.677.677 0 0 0 .559.32h.005a.55.55 0 0 0 .55-.55l-.001-.037a.753.753 0 0 0-.199-.485L13.989 9.48l1.024-1.28a.695.695 0 0 0 .185-.427v-.012c0-.3-.244-.544-.544-.544h-.017a.687.687 0 0 0-.519.317l-.798 1.107-.781-1.104a.677.677 0 0 0-.559-.32h-.018a.55.55 0 0 0-.55.55l.001.04.001-.002z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contenttext.displayName = "Contenttext"

Contenttext.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contenttext.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contenttext;
