import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Button from '../Button';
import CircularSpinner from '../CircularSpinner';
import Icon from '../Icon';
import styles from './LoadingSuccessButton.module.scss';
import STATES, { LOADING, SUCCESS } from './constants';

const cx = classNames.bind(styles);

const LoadingSuccessButton = ({
  appearance,
  children,
  disabled,
  hasSuccessDisabled,
  iconColor,
  iconName,
  loadingChildren,
  spinnerColor,
  state,
  successChildren,
  ...props
}) => {
  let childrenComponent;
  const iconStyle = { backgroundColor: iconColor };
  const isDisabled = disabled || hasSuccessDisabled;

  if (state === LOADING) {
    childrenComponent = (
      <div aria-live="polite" className={cx('btn-loading__container')}>
        <CircularSpinner
          accessibilityLabel=""
          className={cx('btn-loading__spinner', `${appearance}--spinner`)}
          color={spinnerColor}
        />
        <div>{loadingChildren || children}</div>
      </div>
    );
  } else if (state === SUCCESS) {
    childrenComponent = (
      <div aria-live="polite" className={cx('btn-loading__container')}>
        <Icon
          className={cx('btn-loading__success-icon', `${appearance}--icon`)}
          name={iconName}
          style={iconStyle}
        />
        <div>{successChildren || children}</div>
      </div>
    );
  } else {
    childrenComponent = <div aria-live="polite">{children}</div>;
  }

  return (
    <Button appearance={appearance} isDisabled={isDisabled} {...props}>
      {childrenComponent}
    </Button>
  );
};

LoadingSuccessButton.propTypes = {
  appearance: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
  hasSuccessDisabled: PropTypes.bool,
  iconColor: PropTypes.string,
  iconName: PropTypes.string,
  loadingChildren: PropTypes.node,
  spinnerColor: PropTypes.string,
  state: PropTypes.oneOf(STATES),
  successChildren: PropTypes.node,
};

LoadingSuccessButton.defaultProps = {
  appearance: null,
  disabled: false,
  hasSuccessDisabled: true,
  iconColor: null,
  iconName: 'checkmark',
  loadingChildren: null,
  spinnerColor: null,
  state: LOADING,
  successChildren: null,
};

LoadingSuccessButton.displayName = 'LoadingSuccessButton';

LoadingSuccessButton.filename = __filename;

LoadingSuccessButton.spreadAttributes = true;

export default LoadingSuccessButton;
