/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Smileyface = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12 3c4.95 0 9 4.05 9 9s-4.05 9-9 9-9-4.05-9-9 4.05-9 9-9zm0 1.059c-4.368 0-7.941 3.573-7.941 7.941S7.632 19.941 12 19.941s7.941-3.573 7.941-7.941S16.368 4.059 12 4.059zM8.453 14.806a.512.512 0 0 1 .741 0c1.535 1.535 4.077 1.535 5.612 0a.512.512 0 0 1 .741 0 .512.512 0 0 1 0 .741A4.992 4.992 0 0 1 12 17.03a4.992 4.992 0 0 1-3.547-1.482.512.512 0 0 1 0-.741zm.9-6.247a1.324 1.324 0 1 1 0 2.647 1.324 1.324 0 0 1 0-2.647zm5.294 0a1.324 1.324 0 1 1 0 2.647 1.324 1.324 0 0 1 0-2.647z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Smileyface.displayName = "Smileyface"

Smileyface.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Smileyface.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Smileyface;
