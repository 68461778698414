/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Speaker = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12.256 6.05a.666.666 0 0 1 .411.616v10.667a.666.666 0 0 1-1.138.472l-2.862-3.138h-2A.669.669 0 0 1 6 14v-4c0-.367.3-.667.667-.667h2l2.862-3.137a.665.665 0 0 1 .727-.145zM15 9a3.066 3.066 0 0 1 3.063 3.063A3.066 3.066 0 0 1 15 15.125v-2.042a1.022 1.022 0 0 0 0-2.041V9z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Speaker.displayName = "Speaker"

Speaker.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Speaker.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Speaker;
