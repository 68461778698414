import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Grid.module.scss';

const cx = classNames.bind(styles);

const FormRow = ({ children, className, ...props }) => {
  const classes = cx('form-row', className);

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

FormRow.propTypes = {
  /** Content of the element */
  children: PropTypes.node.isRequired,
  /** CSS classes */
  className: classNamePropShape,
};

FormRow.defaultProps = {
  className: null,
};

FormRow.displayName = 'FormRow';

FormRow.filename = __filename;

FormRow.spreadAttributes = true;

export default FormRow;
