import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Typography.module.scss';

const cx = classNames.bind(styles);

const H1 = ({ children, className, ...props }) => {
  const classes = cx('h1', className);

  return (
    <h1 className={classes} {...props}>
      {children}
    </h1>
  );
};

H1.propTypes = {
  children: PropTypes.node.isRequired,
  /** CSS classes */
  className: classNamePropShape,
};

H1.defaultProps = {
  className: null,
};

H1.displayName = 'H1';

H1.filename = __filename;

export default H1;
