/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Envelope = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12 11.18L18.68 7H5.32L12 11.18zM18.68 17V8.68L12 12.82 5.32 8.68V17h13.36zm0-11.68c.453 0 .84.167 1.16.5.32.333.48.727.48 1.18v10c0 .453-.16.847-.48 1.18-.32.333-.707.5-1.16.5H5.32a1.55 1.55 0 0 1-1.16-.5A1.647 1.647 0 0 1 3.68 17V7c0-.453.16-.847.48-1.18.32-.333.707-.5 1.16-.5h13.36z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Envelope.displayName = "Envelope"

Envelope.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Envelope.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Envelope;
