/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Socialapple = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M16.6442 12.1823C16.6362 10.7586 17.3031 9.68405 18.6531 8.89267C17.8978 7.84914 16.7567 7.275 15.25 7.1625C13.8237 7.05388 12.2647 7.96552 11.6942 7.96552C11.0915 7.96552 9.70937 7.20129 8.62455 7.20129C6.38259 7.23621 4 8.92759 4 12.3685C4 13.3849 4.19286 14.4349 4.57857 15.5185C5.09286 16.9422 6.94911 20.4336 8.88571 20.3754C9.89821 20.3522 10.6134 19.681 11.9312 19.681C13.2089 19.681 13.8719 20.3754 15.0009 20.3754C16.9536 20.3483 18.633 17.175 19.1232 15.7474C16.5036 14.5565 16.6442 12.256 16.6442 12.1823V12.1823ZM14.3701 5.8125C15.467 4.5556 15.3665 3.41121 15.3344 3C14.3661 3.05431 13.2451 3.63621 12.6062 4.35388C11.9031 5.12198 11.4893 6.07241 11.5777 7.1431C12.6263 7.22069 13.5826 6.70086 14.3701 5.8125V5.8125Z" fill="#12131A"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Socialapple.displayName = "Socialapple"

Socialapple.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Socialapple.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Socialapple;
