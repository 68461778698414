/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Menu = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M4.5 7h15v1.68h-15V7zm0 5.82v-1.64h15v1.64h-15zm0 4.18v-1.68h15V17h-15z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Menu.displayName = "Menu"

Menu.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Menu.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Menu;
