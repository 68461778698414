import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Typography.module.scss';

const cx = classNames.bind(styles);

const H5 = ({ children, className, ...props }) => {
  const classes = cx('h5', className);

  return (
    <h5 className={classes} {...props}>
      {children}
    </h5>
  );
};

H5.propTypes = {
  children: PropTypes.node.isRequired,
  /** CSS classes */
  className: classNamePropShape,
};

H5.defaultProps = {
  className: null,
};

H5.displayName = 'H5';

H5.filename = __filename;

export default H5;
