/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contentexam = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M19.242 10.458h-6.885a.443.443 0 0 0 0 .887h6.885a.443.443 0 0 0 0-.887zm0-2.363h-6.885a.443.443 0 0 0 0 .887h6.885a.443.443 0 0 0 0-.887zm0 8.271h-6.885a.443.443 0 0 0 0 .886h6.885a.443.443 0 0 0 0-.886zm0-2.363h-6.885a.443.443 0 0 0 0 .886h6.885a.443.443 0 0 0 0-.886zM10.29 6.982a.555.555 0 0 0-.783 0l-2.99 2.886L5.12 8.184a.556.556 0 0 0-.853.708l1.772 2.152a.559.559 0 0 0 .394.2h.033c.15 0 .286-.06.386-.156l3.426-3.323a.553.553 0 0 0 .012-.783zM8.16 14.78c0-.592-.48-1.072-1.073-1.072h-1.4c-.593 0-1.073.48-1.073 1.072v1.401c0 .592.48 1.072 1.073 1.072h1.4c.593 0 1.073-.48 1.073-1.072v-1.4zm-.886 1.414a.168.168 0 0 1-.168.168H5.67a.167.167 0 0 1-.168-.165v-1.435c0-.093.076-.168.168-.168h1.433c.093 0 .168.076.168.168v1.432h.003z"/><path transform="scale(1 1)" d="M21.73 20.706H2.27a1.773 1.773 0 0 1-1.772-1.773V5.535c0-.979.793-1.773 1.772-1.773h19.46c.979 0 1.772.794 1.772 1.773v13.398c0 .979-.793 1.773-1.772 1.773zM2.27 4.952a.591.591 0 0 0-.59.591v13.384c0 .326.264.59.59.59h19.46a.59.59 0 0 0 .59-.59V5.534a.591.591 0 0 0-.59-.59H2.27v.008z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contentexam.displayName = "Contentexam"

Contentexam.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contentexam.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contentexam;
