import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import loadable from '@loadable/component';
import { initialState, reducer } from './reducer';
import Button from '../Button';
import Icon from '../Icon';
import styles from './FilestackInlineUploader.module.scss';

import { classNamePropShape } from '../../prop-shapes';

const cx = classNames.bind(styles);
const ReactFilestack = loadable(() => import('filestack-react'));

export function FilestackInlineUploader({
  awsBucket,
  awsPath,
  awsRegion,
  className,
  clientOptions,
  filestackAPIKey,
  filestackOptions,
  hasUploadedFileDisplayed,
  isDisabled,
  isFileProcessingState,
  uploadedFilename,
  onError,
  onFileUploadCanceled,
  onFileUploadStarted,
  onSuccess,
}) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { filename, progress } = state;

  const isUploadDefault = progress < 1 && !uploadedFilename;
  const isUploadProcessing = progress > 0 && progress < 100;
  const showProcessing =
    isUploadProcessing || (progress >= 100 && isFileProcessingState);
  const isUploadComplete = progress >= 100 && !isFileProcessingState;
  const showUploadedFile = uploadedFilename && hasUploadedFileDisplayed;

  const actionOptions = {
    hideModalWhenUploading: true,
    storeTo: {
      access: 'private',
      container: awsBucket,
      location: 's3',
      path: awsPath,
      region: awsRegion,
    },
    onFileUploadProgress: handleUploadProgress,
    onFileUploadStarted: handleUploadStarted,
    ...filestackOptions,
  };

  function handleUploadError(error) {
    onError(error.message);
    dispatch({ type: 'reset' });
  }

  function handleUploadProgress() {
    dispatch({ type: 'increment' });
  }

  function handleUploadStarted(file) {
    onFileUploadStarted();
    dispatch({ type: 'start', payload: file.filename });
  }

  function handleUploadCancel() {
    onFileUploadCanceled();
    dispatch({ type: 'reset' });
  }

  function handleUploadSuccess({ filesUploaded }) {
    if (filesUploaded.length > 0) {
      onSuccess(filesUploaded);
      dispatch({ type: 'complete', payload: filesUploaded[0].filename });
    }
  }

  //
  // ─── UPLOAD DEFAULT ──────────────────────────────────────────────────────────────
  //
  let buttonLabel = 'Browse files';
  let buttonAppearance = 'primary';

  //
  // ─── UPLOAD PROCESSING ──────────────────────────────────────────────────────────────
  //
  let progressLabel = `${progress}%`;
  let progressIndicator = (
    <div className={cx(['progress__wrapper', 'progress'])}>
      <div className="progress-bar" data-value={progress} />
    </div>
  );

  if (progress >= 100 && isFileProcessingState) {
    progressLabel = 'Proccessing file';
    progressIndicator = <div className={cx('loading-spinner')} />;
  }

  //
  // ─── UPLOAD COMPLETE ───────────────────────────────────────────────────────────
  //
  if (isUploadComplete || showUploadedFile) {
    buttonLabel = 'Use different file';
    buttonAppearance = 'secondary';
  }

  const wrapperClass = cx(
    {
      'file-uploader__default': isUploadDefault,
      'file-uploader__processing': isUploadProcessing,
      'file-uploader__complete': isUploadComplete || showUploadedFile,
    },
    className
  );

  const uploadedFileClass = cx({
    'file-uploader__default__container': isUploadDefault,
    'file-uploader__processing__container': isUploadProcessing,
    'file-uploader__complete__container': isUploadComplete || showUploadedFile,
  });

  return (
    <div className={wrapperClass}>
      <div className={uploadedFileClass}>
        {isUploadDefault && (
          <span className={cx('file-uploader__default__description')}>
            No file selected
          </span>
        )}
        {showProcessing && (
          <div className={cx('progress__container')}>
            {filename} -
            <span className={cx('file-uploader__processing__label')}>
              {progressLabel}
            </span>
            {progressIndicator}
          </div>
        )}

        <span
          className={cx('file-uploader__complete__name', {
            hidden:
              isUploadDefault || showProcessing || !hasUploadedFileDisplayed,
          })}
        >
          <Icon
            className={cx('file-uploader__complete__icon')}
            name="page"
            aria-hidden
          />
          {filename || uploadedFilename}
        </span>

        <span className={cx({ hidden: showProcessing })}>
          <ReactFilestack
            actionOptions={actionOptions}
            apikey={filestackAPIKey}
            clientOptions={clientOptions}
            customRender={({ onPick }) => (
              <Button
                appearance={buttonAppearance}
                isDisabled={isDisabled}
                onClick={onPick}
              >
                {buttonLabel}
              </Button>
            )}
            onError={handleUploadError}
            onSuccess={handleUploadSuccess}
          />
        </span>
      </div>

      {showProcessing && (
        <Button
          appearance="secondary"
          className={cx('upload-cancel__button')}
          onClick={handleUploadCancel}
        >
          Cancel
        </Button>
      )}
    </div>
  );
}

FilestackInlineUploader.propTypes = {
  /** The AWS bucket the file will be uploaded to */
  awsBucket: PropTypes.string.isRequired,
  /** The path within the bucket the file will be stored in */
  awsPath: PropTypes.string.isRequired,
  /** The AWS region */
  awsRegion: PropTypes.string.isRequired,
  /** A className applied to outermost element */
  className: classNamePropShape,
  /** Filestack initialization options */
  clientOptions: PropTypes.object,
  /** Our filestack API key */
  filestackAPIKey: PropTypes.string.isRequired,
  /** Filestack options */
  filestackOptions: PropTypes.object,
  /** Controls whether or not to show the uploaded file */
  hasUploadedFileDisplayed: PropTypes.bool,
  /** Disabled the component and shows the (disabled) upload button */
  isDisabled: PropTypes.bool,
  /* isFileProcessingState: if file upload is done, and this has a value,
     show processing information until it is false */
  isFileProcessingState: PropTypes.bool,
  /** Name of the uploaded file */
  uploadedFilename: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Callback for when the file upload failed */
  onError: PropTypes.func,
  /** Callback for when file upload is cancelled */
  onFileUploadCanceled: PropTypes.func,
  /** Callback for when the file upload has started */
  onFileUploadStarted: PropTypes.func,
  /** Callback for when the file upload succeeds */
  onSuccess: PropTypes.func,
};

FilestackInlineUploader.defaultProps = {
  className: null,
  clientOptions: {},
  filestackOptions: {},
  hasUploadedFileDisplayed: true,
  isDisabled: false,
  isFileProcessingState: false,
  uploadedFilename: null,
  onError: () => {},
  onFileUploadCanceled: () => {},
  onFileUploadStarted: () => {},
  onSuccess: () => {},
};

FilestackInlineUploader.displayName = 'FilestackInlineUploader';
