/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const People = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M15.32 12.82c.72 0 1.52.107 2.4.32.973.253 1.767.58 2.38.98.72.48 1.08 1.02 1.08 1.62v2.08h-5v-2.08c0-1.133-.547-2.093-1.64-2.88.187-.027.447-.04.78-.04zm-6.64 0c.72 0 1.513.107 2.38.32.973.253 1.76.58 2.36.98.72.48 1.08 1.02 1.08 1.62v2.08H2.82v-2.08c0-.6.36-1.14 1.08-1.62.613-.4 1.407-.727 2.38-.98.88-.213 1.68-.32 2.4-.32zm0-1.64a2.41 2.41 0 0 1-1.24-.34 2.536 2.536 0 0 1-.92-.92 2.41 2.41 0 0 1-.34-1.24c0-.44.113-.853.34-1.24.227-.387.533-.693.92-.92a2.412 2.412 0 0 1 2.47 0c.38.227.68.53.9.91.22.38.33.797.33 1.25 0 .453-.11.87-.33 1.25-.22.38-.52.683-.9.91-.38.227-.79.34-1.23.34zm6.64 0a2.41 2.41 0 0 1-1.24-.34 2.536 2.536 0 0 1-.92-.92 2.41 2.41 0 0 1-.34-1.24c0-.44.113-.853.34-1.24.227-.387.533-.693.92-.92.387-.227.8-.34 1.24-.34.44 0 .853.113 1.24.34.387.227.693.533.92.92.227.387.34.8.34 1.24 0 .44-.113.853-.34 1.24a2.536 2.536 0 0 1-.92.92c-.387.227-.8.34-1.24.34z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

People.displayName = "People"

People.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

People.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default People;
