/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Checkboxempty = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M5 6.347v11.306C5 18.396 5.604 19 6.347 19h11.306c.743 0 1.347-.604 1.347-1.347V6.347C19 5.604 18.396 5 17.653 5H6.347C5.604 5 5 5.604 5 6.347zm-1 0A2.348 2.348 0 0 1 6.347 4h11.306A2.348 2.348 0 0 1 20 6.347v11.306A2.348 2.348 0 0 1 17.653 20H6.347A2.348 2.348 0 0 1 4 17.653V6.347z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Checkboxempty.displayName = "Checkboxempty"

Checkboxempty.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Checkboxempty.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Checkboxempty;
