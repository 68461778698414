import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styled from 'styled-components';
import { IconButton } from '../Button';
import Icon from '../Icon';
import ThemeContext from '../theme';
import { classNamePropShape } from '../../prop-shapes';

import notificationCSS from './notificationCSS';
import styles from './Notification.module.scss';

const cx = classNames.bind(styles);

const StyledNotification = styled.div`
  && {
    ${notificationCSS}
  }
`;

const Notification = (props) => {
  const { className, type, hasCloseButton, children, onClose } = props;
  const theme = useContext(ThemeContext);

  let icon;

  switch (type) {
    case 'success':
      icon = 'circle-fill-check';
      break;
    case 'danger':
      icon = 'stop-sign';
      break;
    case 'warning':
      icon = 'warning';
      break;
    case 'upsell':
      icon = 'upgrade';
      break;
    case 'info':
    default:
      icon = 'circle-fill-info';
  }

  return (
    <StyledNotification
      className={cx('notification', `notification--${type}`, className)}
      theme={theme}
      type={type}
    >
      <div className={cx('notification__text-wrapper')}>
        <Icon name={icon} aria-hidden />
        <div className={cx('notification__text')}>{children}</div>
      </div>

      {hasCloseButton && (
        <IconButton
          appearance="utility"
          className={cx('notification__close-button')}
          name="x-dismiss"
          title="Close notification"
          onClick={onClose}
        />
      )}
    </StyledNotification>
  );
};

Notification.propTypes = {
  /** alert content */
  children: PropTypes.node.isRequired,
  /** Optional classnames (array or string) */
  className: classNamePropShape,
  /** shows/hides the alert close button */
  hasCloseButton: PropTypes.bool,
  /** Type of inline alert */
  type: PropTypes.oneOf(['success', 'danger', 'warning', 'info', 'upsell']),
  /** callback function after close button is clicked */
  onClose: PropTypes.func,
};

Notification.defaultProps = {
  className: null,
  type: 'info',
  hasCloseButton: false,
  onClose: () => {},
};

Notification.displayName = 'Notification';

Notification.filename = __filename;

export default Notification;
