/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Viewgrid = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M15.32 6.18h4.18v5h-4.18v-5zm-5 5v-5h4.18v5h-4.18zm5 5.82v-5h4.18v5h-4.18zm-5 0v-5h4.18v5h-4.18zm-5 0v-5H9.5v5H5.32zm0-5.82v-5H9.5v5H5.32z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Viewgrid.displayName = "Viewgrid"

Viewgrid.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Viewgrid.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Viewgrid;
