/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Blog = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M23.129 12.431a1.262 1.262 0 1 1 0-2.524 1.262 1.262 0 0 1 0 2.524zm-9.445-1.822h6.392v1.111h-6.392v-1.111zm0 5.004h10.707v1.111H13.684v-1.11zm0 5.005h10.707v1.11H13.684v-1.11zM9.062 4.284v21.503a1.6 1.6 0 1 1-3.195 0v-8.84h1.577v-1.111H4.778v9.95a2.711 2.711 0 0 0 2.706 2.712h17.81a3.111 3.111 0 0 0 3.11-3.111V4.284H9.062zm18.222 21.094a2.013 2.013 0 0 1-2.008 2.009H9.666a2.693 2.693 0 0 0 .525-1.6V5.396h17.107l-.014 19.982z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Blog.displayName = "Blog"

Blog.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Blog.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Blog;
