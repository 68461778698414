/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Account = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M15.951 14.689a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-6.853a2.871 2.871 0 1 1 0 5.742 2.871 2.871 0 0 1 0-5.742zm3.142 13.373a1.262 1.262 0 1 1 0 2.524 1.262 1.262 0 0 1 0-2.524zM20.2 17.2a4.151 4.151 0 0 1 4.151 4.147v4.355c-.356.29-.727.562-1.111.814V21.4a3.044 3.044 0 0 0-3.04-3.04h-8.498a3.044 3.044 0 0 0-3.04 3.04v5.116a14.23 14.23 0 0 1-1.11-.814v-4.355a4.151 4.151 0 0 1 4.15-4.147H20.2zM15.951.431C7.497.431.644 7.284.644 15.738c0 8.453 6.853 15.306 15.307 15.306s15.307-6.853 15.307-15.306A15.302 15.302 0 0 0 15.95.43zm0 29.498c-7.83-.017-14.174-6.36-14.191-14.191v-.236c0-7.835 6.352-14.186 14.187-14.186 7.835 0 14.186 6.351 14.186 14.186v.222c-.002 7.838-6.345 14.196-14.182 14.218v-.013z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Account.displayName = "Account"

Account.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Account.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Account;
