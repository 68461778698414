/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Dataexport = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M14.895 7.57L12.61 5.285v11.07a.6.6 0 0 1-.186.44.6.6 0 0 1-.44.185.617.617 0 0 1-.625-.625V5.325L9.095 7.57a.567.567 0 0 1-.44.196.567.567 0 0 1-.439-.196.556.556 0 0 1-.195-.43c0-.169.065-.318.195-.448L12.005 3l3.788 3.692a.89.89 0 0 1 .127.214.605.605 0 0 1 .049.235.605.605 0 0 1-.049.234.597.597 0 0 1-.127.195.618.618 0 0 1-.449.196.618.618 0 0 1-.45-.196zM22 19.837c0 .327-.12.603-.361.827a1.256 1.256 0 0 1-.889.336H3.25c-.352 0-.648-.112-.889-.336-.24-.224-.361-.5-.361-.827v-7.674c0-.315.12-.588.361-.818.241-.23.537-.345.889-.345h5.645v1.181H4.012a.78.78 0 0 0-.537.2.632.632 0 0 0-.225.49v6.258c0 .193.075.36.225.5a.76.76 0 0 0 .537.208l15.976-.018a.76.76 0 0 0 .537-.209.66.66 0 0 0 .225-.5v-6.239a.632.632 0 0 0-.225-.49.78.78 0 0 0-.537-.2h-4.863V11h5.625c.352 0 .648.115.889.345.24.23.361.503.361.818v7.674z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Dataexport.displayName = "Dataexport"

Dataexport.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Dataexport.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Dataexport;
