import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { P } from '../../Typography';
import { classNamePropShape } from '../../../prop-shapes';

import styles from '../Section.module.scss';

const cx = classNames.bind(styles);

export function SectionUpgrade({
  actions,
  className,
  description,
  label,
  size,
}) {
  const classes = cx(
    'section__upgrade__container',
    {
      'section__upgrade__container--small': size === 'small',
      'section__upgrade__container--large': size === 'large',
    },
    className
  );

  return (
    <div className={classes}>
      {size === 'small' ? (
        <P className={cx('section__upgrade__label')}>{label}</P>
      ) : (
        <h3 className={cx('section__upgrade__title')}>{label}</h3>
      )}
      {description ? (
        <P className={cx('section__upgrade__description')}>{description}</P>
      ) : null}
      <div className={cx('section__upgrade__actions')}>{actions}</div>
    </div>
  );
}

SectionUpgrade.propTypes = {
  /** Action buttons */
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  /** Class for extra styling */
  className: classNamePropShape,
  /** Description rendered as a P */
  description: PropTypes.string,
  /** Label rendered as a H4 */
  label: PropTypes.string.isRequired,
  /** Changes the layout  */
  size: PropTypes.oneOf(['small', 'large']),
};

SectionUpgrade.defaultProps = {
  className: null,
  description: null,
  size: 'small',
};

SectionUpgrade.filename = __filename;

SectionUpgrade.displayName = 'SectionUpgrade';
