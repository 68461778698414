/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Noresults = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" fillRule="evenodd" clipRule="evenodd" d="M25.2668 4C26.7256 4 28 5.52829 28 7V11H29V7C28.9952 4.90801 27.314 3.00244 25.2403 3H2V28H25V27H3V4H25.2668ZM25.4144 14.5503C27.1694 16.3053 27.1718 19.1493 25.4214 20.9072L25.4144 20.9142L25.4073 20.9213C23.6494 22.6716 20.8055 22.6692 19.0504 20.9142C17.2931 19.1569 17.2931 16.3076 19.0504 14.5503C20.8078 12.7929 23.657 12.7929 25.4144 14.5503ZM18.3433 21.6212C20.3721 23.65 23.5914 23.7625 25.7522 21.9589L29.3036 25.5103L30.0107 24.8032L26.4593 21.2518C28.2628 19.0909 28.1502 15.8717 26.1215 13.8431C23.9736 11.6952 20.4912 11.6952 18.3433 13.8431C16.1955 15.991 16.1955 19.4734 18.3433 21.6212ZM24 9H7V10H24V9ZM7 15H15V16H7V15ZM15 21H7V22H15V21Z" fill="#12131A"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Noresults.displayName = "Noresults"

Noresults.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Noresults.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Noresults;
