/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Userlogout = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M17.41 14.91l2.286-2.285H7.626a.6.6 0 0 1-.44-.186A.6.6 0 0 1 7 12c0-.182.062-.331.186-.448a.617.617 0 0 1 .44-.176h12.03L17.41 9.109a.567.567 0 0 1-.195-.44c0-.175.065-.322.195-.439a.556.556 0 0 1 .43-.195c.17 0 .32.065.45.195l3.69 3.79-3.69 3.788a.89.89 0 0 1-.215.127.605.605 0 0 1-.235.05.605.605 0 0 1-.234-.05.597.597 0 0 1-.196-.127.617.617 0 0 1-.195-.449.62.62 0 0 1 .195-.45zM3.25 2c-.352 0-.648.12-.889.361-.24.241-.361.537-.361.889v17.5c0 .352.12.648.361.889.241.24.537.361.889.361H14.5c.339 0 .632-.12.879-.361s.371-.537.371-.889v-5.645h-1.27v4.883a.75.75 0 0 1-.214.537.701.701 0 0 1-.528.225H4.012a.733.733 0 0 1-.537-.225.733.733 0 0 1-.225-.537l.02-15.976c0-.209.074-.388.224-.537a.733.733 0 0 1 .537-.225h9.707c.209 0 .384.075.528.225a.75.75 0 0 1 .214.537v4.863h1.27V3.25c0-.352-.124-.648-.371-.889A1.217 1.217 0 0 0 14.5 2H3.25z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Userlogout.displayName = "Userlogout"

Userlogout.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Userlogout.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Userlogout;
