/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Webhook = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M17.588 16.96a1.16 1.16 0 0 1 .954-.492c.639 0 1.157.507 1.157 1.133 0 .625-.518 1.133-1.157 1.133-.427 0-.8-.227-1-.565h-8.65C8.616 19.776 7.19 21 5.47 21 3.554 21 2 19.478 2 17.6c0-1.156.59-2.177 1.49-2.791l.663.928a2.25 2.25 0 0 0-.996 1.864c0 1.251 1.036 2.266 2.313 2.266 1.14 0 2.088-.808 2.279-1.87V16.96h9.84zm-13.153.17a1.156 1.156 0 0 1 1.065-.71l4.338-7.395a3.366 3.366 0 0 1-.789-4.323c.955-1.627 3.069-2.185 4.722-1.246a3.391 3.391 0 0 1 1.714 2.651l-1.147.1a2.26 2.26 0 0 0-1.144-1.769 2.325 2.325 0 0 0-3.147.831 2.247 2.247 0 0 0 .775 3.058l.634.36-4.913 8.374c.14.287.154.628.021.935-.162.432-.585.74-1.081.74a1.143 1.143 0 0 1-1.153-1.133c0-.169.038-.33.105-.474zm7.506-9.605a1.14 1.14 0 0 1-.932-.557 1.119 1.119 0 0 1 .428-1.54 1.157 1.157 0 0 1 1.569.405c.206.351.195.767.01 1.101l4.315 7.356c1.56-.592 3.36-.007 4.214 1.448.945 1.61.37 3.679-1.283 4.618a3.514 3.514 0 0 1-3.193.146l.49-1.022c.658.3 1.452.29 2.132-.096 1.103-.626 1.486-2.005.855-3.08-.63-1.073-2.035-1.436-3.137-.81l-.015.008-.01-.016-.579.329-4.864-8.29z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Webhook.displayName = "Webhook"

Webhook.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Webhook.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Webhook;
