/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Dripschedule = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.9230769230769231 0.9230769230769231)" d="M3.76381 5.63246C3.86672 5.63264 3.96847 5.61076 4.06221 5.5683C4.15595 5.52584 4.23951 5.46378 4.30724 5.38631C4.64269 5.00372 5.00292 4.6436 5.38561 4.30827C5.45698 4.24579 5.51534 4.16988 5.55736 4.08485C5.59939 3.99982 5.62425 3.90735 5.63054 3.81272C5.63683 3.71808 5.62441 3.62313 5.59401 3.53329C5.5636 3.44345 5.51579 3.36048 5.45332 3.28912C5.39085 3.21775 5.31493 3.15939 5.22991 3.11737C5.14488 3.07534 5.05241 3.05048 4.95777 3.04419C4.86313 3.0379 4.76819 3.05032 4.67835 3.08073C4.58851 3.11113 4.50554 3.15894 4.43417 3.22141C4.00381 3.59878 3.59859 4.0039 3.22111 4.43416C3.12964 4.53852 3.07016 4.66702 3.04981 4.80429C3.02945 4.94157 3.04907 5.0818 3.10633 5.20821C3.16358 5.33462 3.25604 5.44186 3.37265 5.5171C3.48925 5.59234 3.62507 5.63239 3.76384 5.63246H3.76381Z" />
<path transform="scale(0.9230769230769231 0.9230769230769231)" d="M7.60028 2.6928C7.71031 2.69278 7.81888 2.66757 7.91766 2.61909C8.37429 2.39512 8.84528 2.20168 9.32751 2.04005C9.50737 1.97786 9.65541 1.8472 9.73947 1.67646C9.82352 1.50572 9.83678 1.30871 9.77638 1.12824C9.71598 0.947778 9.58679 0.798447 9.41689 0.712707C9.247 0.626968 9.05012 0.61175 8.86907 0.670362C8.32609 0.8524 7.79573 1.07011 7.28145 1.32206C7.13484 1.39391 7.01691 1.51332 6.94688 1.66081C6.87685 1.80829 6.85887 1.97516 6.89585 2.13418C6.93283 2.2932 7.02261 2.435 7.15053 2.53645C7.27846 2.6379 7.43698 2.69301 7.60025 2.6928H7.60028Z" />
<path transform="scale(0.9230769230769231 0.9230769230769231)" d="M1.12532 9.78394C1.21525 9.81423 1.31027 9.82648 1.40494 9.81998C1.49961 9.81348 1.59207 9.78836 1.67701 9.74607C1.76196 9.70378 1.83772 9.64514 1.89997 9.57351C1.96221 9.50189 2.00971 9.41868 2.03974 9.32867C2.2015 8.84624 2.39494 8.37502 2.6188 7.91808C2.70307 7.74608 2.71556 7.54765 2.65353 7.36645C2.59149 7.18524 2.46001 7.0361 2.28802 6.95183C2.11602 6.86756 1.91759 6.85507 1.73638 6.9171C1.55517 6.97914 1.40603 7.11061 1.32176 7.28261C1.06993 7.7967 0.852231 8.32682 0.670064 8.86952C0.609263 9.05115 0.623062 9.2495 0.708428 9.42096C0.793795 9.59242 0.943744 9.72298 1.12532 9.78394Z" />
<path transform="scale(0.9230769230769231 0.9230769230769231)" d="M13 0C12.744 0 12.4894 0.00728729 12.2362 0.0218617C12.0486 0.0326819 11.8726 0.116178 11.7455 0.254629C11.6185 0.39308 11.5504 0.575586 11.5557 0.763412C11.5611 0.951238 11.6394 1.1296 11.774 1.26063C11.9087 1.39167 12.0892 1.46507 12.2771 1.46525C12.2912 1.46525 12.3053 1.4649 12.3194 1.46419C12.5447 1.45115 12.7716 1.44456 13 1.44444C15.2855 1.44444 17.5196 2.12217 19.4199 3.39191C21.3202 4.66165 22.8013 6.46638 23.6759 8.57788C24.5505 10.6894 24.7794 13.0128 24.3335 15.2544C23.8876 17.4959 22.7871 19.5549 21.171 21.171C19.5549 22.7871 17.4959 23.8876 15.2544 24.3335C13.0128 24.7794 10.6894 24.5505 8.57788 23.6759C6.46638 22.8013 4.66165 21.3202 3.39191 19.4199C2.12217 17.5196 1.44444 15.2855 1.44444 13C1.44444 12.7715 1.45103 12.5446 1.46419 12.3194C1.46966 12.2247 1.45642 12.1298 1.42523 12.0402C1.39403 11.9507 1.3455 11.8681 1.2824 11.7973C1.2193 11.7264 1.14286 11.6687 1.05746 11.6274C0.972051 11.5861 0.879349 11.5621 0.784644 11.5566C0.593997 11.5481 0.407552 11.6143 0.265059 11.7413C0.122566 11.8682 0.0352982 12.0458 0.0218617 12.2362C0.00740285 12.489 0.000115556 12.7436 0 13C0 15.5712 0.762436 18.0846 2.19089 20.2224C3.61935 22.3602 5.64967 24.0265 8.02511 25.0104C10.4006 25.9944 13.0144 26.2518 15.5362 25.7502C18.0579 25.2486 20.3743 24.0105 22.1924 22.1924C24.0105 20.3743 25.2486 18.0579 25.7502 15.5362C26.2518 13.0144 25.9944 10.4006 25.0104 8.02511C24.0265 5.64967 22.3602 3.61935 20.2224 2.19089C18.0846 0.762436 15.5712 0 13 0Z" />
<path transform="scale(0.9230769230769231 0.9230769230769231)" d="M13.0001 3.61108C12.8085 3.61108 12.6248 3.68718 12.4894 3.82262C12.3539 3.95806 12.2778 4.14176 12.2778 4.33331V13C12.2779 13.1915 12.354 13.3752 12.4894 13.5106L16.8228 17.8439C16.9586 17.9773 17.1416 18.0517 17.332 18.0508C17.5224 18.0499 17.7048 17.9739 17.8394 17.8393C17.974 17.7047 18.05 17.5223 18.0509 17.3319C18.0518 17.1416 17.9774 16.9585 17.844 16.8227L13.7223 12.7009V4.33331C13.7223 4.14176 13.6462 3.95806 13.5107 3.82262C13.3753 3.68718 13.1916 3.61108 13.0001 3.61108Z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Dripschedule.displayName = "Dripschedule"

Dripschedule.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Dripschedule.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Dripschedule;
