/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Datadownload = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M9.105 16.43l2.285 2.285V7.645a.6.6 0 0 1 .186-.44.6.6 0 0 1 .44-.185.617.617 0 0 1 .625.625v11.03l2.265-2.245a.567.567 0 0 1 .44-.196c.175 0 .322.065.439.196.13.117.195.26.195.43a.615.615 0 0 1-.195.448L11.995 21l-3.788-3.692a.89.89 0 0 1-.127-.214.605.605 0 0 1-.049-.235c0-.078.016-.156.049-.234a.597.597 0 0 1 .127-.195c.13-.13.28-.196.449-.196.17 0 .319.065.45.196zM2 4.163c0-.327.12-.603.361-.827.241-.224.537-.336.889-.336h17.5c.352 0 .648.112.889.336.24.224.361.5.361.827v7.674c0 .315-.12.588-.361.818-.241.23-.537.345-.889.345h-5.645v-1.181h4.883a.78.78 0 0 0 .537-.2.632.632 0 0 0 .225-.49V4.87a.66.66 0 0 0-.225-.5.761.761 0 0 0-.537-.208L4.012 4.18a.761.761 0 0 0-.537.209.66.66 0 0 0-.225.5v6.239a.63.63 0 0 0 .225.49c.15.133.328.2.537.2h4.863V13H3.25c-.352 0-.648-.115-.889-.345A1.094 1.094 0 0 1 2 11.837V4.163z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Datadownload.displayName = "Datadownload"

Datadownload.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Datadownload.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Datadownload;
