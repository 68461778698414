import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { nanoid } from 'nanoid/non-secure';
import styled from 'styled-components';
import ThemeContext from '../theme';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Loader.module.scss';

const cx = classNames.bind(styles);

// Use a ternary operator to make sure that the document object is defined
const portalRoot =
  typeof document !== `undefined` ? document.querySelector('body') : null;

const loaderColor = (theme) => {
  if (theme?.loader?.color) {
    return theme.loader.color;
  }

  if (theme?.color?.primary) {
    return theme.color.primary;
  }

  return null;
};

const StyledLoader = styled.div`
  && {
    ${(props) => `
        background-color: ${loaderColor(props.theme)};
    `}
  }
`;

class Loader extends Component {
  constructor() {
    super();
    // Use a ternary operator to make sure that the document object is defined
    this.el =
      typeof document !== `undefined` ? document.createElement('div') : null;
  }

  componentDidMount = () => {
    portalRoot.appendChild(this.el);
  };

  componentWillUnmount = () => {
    portalRoot.removeChild(this.el);
  };

  render() {
    const { accessibilityLabel, className } = this.props;
    // If accessibilityLabel is set to an empty string, the Loader label ID
    // will return null to avoid identifying a non-existent label element
    const loaderLabelId = accessibilityLabel ? nanoid() : null;

    return ReactDOM.createPortal(
      <ThemeContext.Consumer>
        {(theme) => (
          <>
            <StyledLoader
              aria-labelledby={loaderLabelId}
              className={cx('loader', className)}
              theme={theme}
            />
            {accessibilityLabel && (
              <p className={cx('sr-only')} id={loaderLabelId} role="alert">
                {accessibilityLabel}
              </p>
            )}
          </>
        )}
      </ThemeContext.Consumer>,
      this.el
    );
  }
}

Loader.displayName = 'Loader';

Loader.propTypes = {
  accessibilityLabel: PropTypes.string,
  className: classNamePropShape,
};

Loader.defaultProps = {
  accessibilityLabel: 'Loading',
  className: null,
};

Loader.filename = __filename;

export default Loader;
