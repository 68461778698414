import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { IconButton } from '../../Button';

import styles from '../ImageInput.module.scss';

const cx = classNames.bind(styles);

export function DeleteButton({ isDisabled, onClick }) {
  return (
    <IconButton
      appearance="knockout"
      className={cx('delete-button')}
      isDisabled={isDisabled}
      name="trash-can"
      title="Remove image"
      isDark
      isSmall
      onClick={onClick}
    />
  );
}

DeleteButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

DeleteButton.defaultProps = {
  isDisabled: false,
  onClick: () => {},
};

DeleteButton.filename = __filename;

DeleteButton.displayName = 'DeleteButton';
