/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Viewlist = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M8.82 7.82H20.5V9.5H8.82V7.82zm0 8.36V14.5H20.5v1.68H8.82zm0-3.36v-1.64H20.5v1.64H8.82zM5.5 9.5V7.82h1.68V9.5H5.5zm0 6.68V14.5h1.68v1.68H5.5zm0-3.36v-1.64h1.68v1.64H5.5z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Viewlist.displayName = "Viewlist"

Viewlist.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Viewlist.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Viewlist;
