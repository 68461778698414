/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Digitaldownload = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M28.927 25.6v3.49c0 .973-.752 1.746-1.698 1.746H3.862c-.948 0-1.698-.77-1.698-1.745V25.6A.582.582 0 0 0 1 25.6v3.49C1 30.703 2.265 32 3.862 32h23.367c1.595 0 2.862-1.301 2.862-2.91V25.6a.582.582 0 0 0-1.164 0z"/><path transform="scale(0.75 0.75)" d="M22.35 19.363a.582.582 0 0 0-.823.015l-5.4 5.599V.582a.582.582 0 1 0-1.163 0v24.395l-5.4-5.6a.582.582 0 1 0-.837.809l6.4 6.637.004.002.007.009c.002 0 .002.002.003.004.02.017.043.025.063.04.028.02.055.042.086.058.01.004.018.014.028.018.003.002.007.002.01.003.036.014.073.016.11.023.034.007.067.02.103.02h.009c.035 0 .069-.013.103-.02.038-.007.074-.01.11-.023.003-.001.007-.001.01-.003.01-.004.017-.013.026-.017.032-.017.06-.039.089-.06.02-.014.043-.022.061-.04.002 0 .002-.002.003-.003l.008-.009.004-.002 6.4-6.637a.582.582 0 0 0-.015-.823"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Digitaldownload.displayName = "Digitaldownload"

Digitaldownload.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Digitaldownload.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Digitaldownload;
