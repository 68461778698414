/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Transactions = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" fillRule="evenodd" clipRule="evenodd" d="M5.94967 2.55022L1.99992 6.49996L1.99996 6.5L1.99992 6.50004L5.94967 10.4498L6.65678 9.74268L3.91406 6.99996H26.2499C27.1682 6.99996 27.9999 7.84454 27.9999 8.99996V15H28.9999V8.99996C28.9999 7.39397 27.817 5.99996 26.2499 5.99996H3.91414L6.65678 3.25732L5.94967 2.55022ZM30 25.5L26.0503 29.4497L25.3431 28.7426L28.0858 26H5.74992C4.18289 26 2.99992 24.606 2.99992 23L2.99992 17H3.99992L3.99992 23C3.99992 24.1554 4.83168 25 5.74992 25H28.0859L25.3431 22.2572L26.0503 21.5501L30 25.4999L30 25.4999L30 25.5ZM14.9999 9.99996H15.9999V11.5355C17.346 11.7276 18.4999 12.6819 18.4999 14H17.4999C17.4999 13.3757 16.9256 12.7223 15.9999 12.546V15.5857C16.6456 15.7277 17.2096 15.9514 17.6408 16.2833C18.1845 16.7017 18.4999 17.2815 18.4999 18C18.4999 19.3181 17.346 20.2723 15.9999 20.4644V22H14.9999V20.4644C13.6539 20.2723 12.4999 19.3181 12.4999 18H13.4999C13.4999 18.6242 14.0742 19.2776 14.9999 19.4539V16.4172C14.2557 16.2642 13.6657 16.0379 13.2428 15.69C12.7083 15.2502 12.4999 14.6678 12.4999 14C12.4999 12.6819 13.6539 11.7276 14.9999 11.5355V9.99996ZM13.4999 14C13.4999 13.3757 14.0742 12.7223 14.9999 12.546V15.3931C14.451 15.2626 14.0991 15.0996 13.8781 14.9178C13.6222 14.7072 13.4999 14.4367 13.4999 14ZM15.9999 19.4539V16.6142C16.4473 16.7322 16.7894 16.8899 17.0309 17.0757C17.3466 17.3187 17.4999 17.6139 17.4999 18C17.4999 18.6242 16.9256 19.2776 15.9999 19.4539Z" fill="#12131A"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Transactions.displayName = "Transactions"

Transactions.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Transactions.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Transactions;
