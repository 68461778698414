/* Migrate on 04/30/2018 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames/bind';

import IconButton from '../Button/IconButton';
import { H2 } from '../Typography';

import { ModalHeader } from './ModalHeader';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import styles from './Modal.module.scss';

import { classNamePropShape } from '../../prop-shapes';

const cx = classNames.bind(styles);

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: props.isOpen };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = ({ isOpen }) => {
    this.setState({ isOpen });
  };

  findComponent = (children, componentToFind) =>
    Array.isArray(children) &&
    children.find((component) => component.type.name === componentToFind.name);

  closeModal = () => {
    const { closeModal, isClosable } = this.props;

    if (!isClosable) {
      return;
    }

    this.setState({ isOpen: false });
    closeModal();
  };

  render() {
    const {
      children,
      className,
      contentLabel,
      extraClassNames,
      footer,
      isFullWidth,
      isNavigationShown,
      hasContentOverflow,
      overlayClassName,
      style,
      title,
    } = this.props;
    const { isOpen } = this.state;

    const classes = cx(
      'overlay',
      {
        'overlay--navigation-shown': isNavigationShown,
      },
      overlayClassName
    );

    return (
      <ReactModal
        aria={{
          labelledby: title && 'modalTitle',
        }}
        ariaHideApp={false}
        className={cx(
          'modal',
          { 'allow-overflow': hasContentOverflow },
          extraClassNames,
          className
        )}
        contentLabel={contentLabel}
        isOpen={isOpen}
        overlayClassName={classes}
        style={style}
        onRequestClose={this.closeModal}
      >
        {this.findComponent(children, ModalHeader)}
        {title && (
          <ModalHeader hasTitle={!!title}>
            <H2 className={cx('h4')} id="modalTitle">
              {title}
            </H2>
            <IconButton
              appearance="utility"
              className={cx('modal__header__close-button')}
              name="x-dismiss"
              title="Close"
              onClick={this.closeModal}
            />
          </ModalHeader>
        )}
        {this.findComponent(children, ModalBody) || (
          <ModalBody
            hasContentOverflow={hasContentOverflow}
            hasTitle={!!title}
            isFullWidth={isFullWidth}
          >
            {children}
          </ModalBody>
        )}

        {this.findComponent(children, ModalFooter)}
        {footer && <ModalFooter>{footer(this.closeModal)}</ModalFooter>}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  /** Content for the modal */
  children: PropTypes.node.isRequired,
  /** A className applied to outermost element */
  className: classNamePropShape,
  /** The action to close the modal, handled by the parent */
  closeModal: PropTypes.func,
  /** Aria information about the modal */
  contentLabel: PropTypes.string,
  /** Scape hatch to override classes, use sparingly */
  extraClassNames: classNamePropShape,
  /** A render prop that recieves the closeModal func as the first parameter.
   * Use if you need to close the modal from here * */
  footer: PropTypes.func,
  /** Provide this to allow overflow on the modal * */
  hasContentOverflow: PropTypes.bool,
  /** Whether the modal is closable or not */
  isClosable: PropTypes.bool,
  /** Removes the padding in the content so you can have the whole space available.
   * Usefull for videos and photos * */
  isFullWidth: PropTypes.bool,
  /** Whether the navigation is accessible or not */
  isNavigationShown: PropTypes.bool,
  /** Provide this to show/hide the modal * */
  isOpen: PropTypes.bool,
  /** ClassNames applied to the overlay element */
  overlayClassName: classNamePropShape,
  /** Another Scape hatch to override classes but for the react-modal, use sparingly */
  style: PropTypes.object,
  /** The title on the header, is a H3 element */
  title: PropTypes.node,
};

Modal.defaultProps = {
  className: null,
  closeModal: () => {},
  contentLabel: 'Modal',
  extraClassNames: null,
  footer: null,
  hasContentOverflow: false,
  isClosable: true,
  isFullWidth: false,
  isOpen: false,
  isNavigationShown: false,
  overlayClassName: null,
  style: {},
  title: null,
};

Modal.displayName = 'Modal';

Modal.filename = __filename;

export default Modal;
