/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Bookmarkfill = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M16.18 4.5c.453 0 .84.167 1.16.5.32.333.48.727.48 1.18V19.5L12 17l-5.82 2.5V6.18c0-.453.16-.847.48-1.18.32-.333.707-.5 1.16-.5h8.36z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Bookmarkfill.displayName = "Bookmarkfill"

Bookmarkfill.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Bookmarkfill.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Bookmarkfill;
