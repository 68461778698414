/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Lightbulb = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M20.954 23.0728L21.2133 21.8409C24.7042 19.976 27.0795 16.2964 27.0795 12.062C27.0795 5.94297 22.119 0.982483 16 0.982483C9.8809 0.982483 4.92041 5.94297 4.92041 12.062C4.92041 16.176 7.16257 19.7662 10.4915 21.6774L10.7853 23.0728C11.0496 24.3284 12.1573 25.2272 13.4405 25.2272H18.2988C19.5819 25.2272 20.6896 24.3284 20.954 23.0728ZM20.6699 20.8238C20.3693 20.9844 20.1551 21.2698 20.0848 21.6033L19.8255 22.8352C19.6735 23.5572 19.0366 24.074 18.2988 24.074H13.4405C12.7027 24.074 12.0657 23.5572 11.9137 22.8352L11.62 21.4398C11.5523 21.1183 11.3506 20.8409 11.0657 20.6773C8.08021 18.9633 6.07359 15.7463 6.07359 12.062C6.07359 6.57986 10.5178 2.13566 16 2.13566C21.4822 2.13566 25.9263 6.57986 25.9263 12.062C25.9263 15.8541 23.8004 19.1514 20.6699 20.8238Z"/>
<path transform="scale(0.75 0.75)" d="M12.6196 26.5382C12.3012 26.5382 12.043 26.7964 12.043 27.1148C12.043 27.4333 12.3012 27.6914 12.6196 27.6914H19.2673C19.5858 27.6914 19.8439 27.4333 19.8439 27.1148C19.8439 26.7964 19.5858 26.5382 19.2673 26.5382H12.6196Z"/>
<path transform="scale(0.75 0.75)" d="M12.043 29.489C12.043 29.1706 12.3012 28.9124 12.6196 28.9124H19.2673C19.5858 28.9124 19.8439 29.1706 19.8439 29.489C19.8439 29.8075 19.5858 30.0656 19.2673 30.0656H12.6196C12.3012 30.0656 12.043 29.8075 12.043 29.489Z"/>
<path transform="scale(0.75 0.75)" d="M16 30.9825C15.438 30.9825 14.9825 31.438 14.9825 32C14.9825 32.562 15.438 33.0175 16 33.0175C16.5619 33.0175 17.0175 32.562 17.0175 32C17.0175 31.438 16.5619 30.9825 16 30.9825Z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Lightbulb.displayName = "Lightbulb"

Lightbulb.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Lightbulb.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Lightbulb;
