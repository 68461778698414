/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Link = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M16.18 7.82c.747 0 1.44.187 2.08.56A4.15 4.15 0 0 1 20.32 12a4.15 4.15 0 0 1-2.06 3.62 4.06 4.06 0 0 1-2.08.56h-3.36v-1.6h3.36a2.5 2.5 0 0 0 1.28-.35c.4-.233.717-.55.95-.95a2.5 2.5 0 0 0 .35-1.28 2.5 2.5 0 0 0-.35-1.28c-.233-.4-.55-.717-.95-.95a2.5 2.5 0 0 0-1.28-.35h-3.36v-1.6h3.36zm-7.5 5v-1.64h6.64v1.64H8.68zM5.24 12c0 .453.117.88.35 1.28.233.4.55.717.95.95.4.233.827.35 1.28.35h3.36v1.6H7.82a4.06 4.06 0 0 1-2.08-.56A4.15 4.15 0 0 1 3.68 12a4.15 4.15 0 0 1 2.06-3.62 4.057 4.057 0 0 1 2.08-.56h3.36v1.6H7.82a2.5 2.5 0 0 0-1.28.35c-.4.233-.717.55-.95.95A2.5 2.5 0 0 0 5.24 12z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Link.displayName = "Link"

Link.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Link.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Link;
