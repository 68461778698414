/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Page = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <g fillRule="evenodd"><path transform="scale(0.75 0.75)" d="M29.097 29.594H2.452c-.271 0-.452-.181-.452-.452V2.452C2 2.18 2.18 2 2.452 2h13.232a.41.41 0 0 1 .316.135l13.413 13.413c.09.09.135.181.135.317v13.232c0 .316-.18.497-.451.497zM2.903 28.69H28.69v-12.6L15.503 2.903h-12.6V28.69z" fillRule="nonzero"/><circle cx="7.103" cy="6.968" r="1"/><path transform="scale(0.75 0.75)" d="M15.4 2c.22 0 .4.18.4.4v13.52a.4.4 0 1 1-.8 0V2.4c0-.22.18-.4.4-.4z"/><path transform="scale(0.75 0.75)" d="M29.32 16.16a.4.4 0 0 1-.4.4H15.4a.4.4 0 1 1 0-.8h13.52c.22 0 .4.18.4.4z"/></g>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Page.displayName = "Page"

Page.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Page.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Page;
