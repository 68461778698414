/*
 * Input
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import classNames from 'classnames/bind';
import Icon, { availableIcons } from '../Icon';
import IconButton from '../Button/IconButton';
import Label from '../Label';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Input.module.scss';

const cx = classNames.bind(styles);

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.defaultId = uniqueId('input-');
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { initValue } = this.props;

    if (initValue) {
      this.setState({ value: initValue.toString() });
    }
  }

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      this.input.select();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Update the input state when you change the value from outside
    this.setState({ value: nextProps.initValue });
  }

  handleValueFieldChange = (e) => {
    const { handleChange, validator } = this.props;

    const newValue = e.target.value;
    let isValid = true;
    if (validator && !validator(newValue)) {
      isValid = false;
    }
    if (isValid) {
      this.setState({ value: newValue });
      if (handleChange) handleChange(e);
    }
  };

  sanitizeState = (state) => {
    const sanitizedState = {};
    Object.keys(state).forEach((key) => {
      sanitizedState[key] = state[key].trim ? state[key].trim() : state[key];
    });
    return sanitizedState;
  };

  handleBlur = (e) => {
    const { handleBlur } = this.props;

    handleBlur(e, this.sanitizeState(this.state));
  };

  handleKeyDown = (e) => {
    const { handleKeyDown } = this.props;

    handleKeyDown(e, this.sanitizeState(this.state));
  };

  handleKeyPress = (e) => {
    const { onEnter } = this.props;

    if (e.key === 'Enter' && onEnter !== null) {
      onEnter(e, this.sanitizeState(this.state));
    }
  };

  resetInputValue = () => {
    this.setState({ value: '' });
    if (this.input) this.input.select();
    const { handleChange, name } = this.props;
    if (handleChange) handleChange({ target: { value: '', name } });
  };

  render() {
    const {
      ariaLabel,
      className,
      id,
      disabled /* eslint-disable-line react/prop-types */,
      isDisabled,
      name,
      type,
      placeholder,
      maxLength,
      extraClasses,
      extraProps,
      label,
      leftAccessory,
      rightAccessory,
      autoFocus,
      hasError,
      hasNoGroup,
      isRequired,
      icon,
      hasClearButton,
      wrapperClass,
      formText,
      errorText,
    } = this.props;

    const { value } = this.state;

    const inputId = id || this.defaultId;

    const isInputRequired = isRequired || extraProps?.required;

    const hasLabel = !!label;
    let inputLabel = label;
    if (typeof label === 'string') {
      inputLabel = (
        <Label className={cx('input__label')} htmlFor={inputId}>
          {label}
          {isInputRequired && ' *'}
        </Label>
      );
    }

    const isInputDisabled = isDisabled || disabled || extraProps?.disabled;
    if (typeof extraProps.disabled !== 'undefined') {
      extraProps.disabled = null;
    }

    return (
      <div
        className={cx('input__box', wrapperClass, className, {
          'toga-form-group': !hasNoGroup,
        })}
      >
        {inputLabel}
        <AccessoriesWrapper
          hasError={hasError || !!errorText}
          leftAccessory={leftAccessory}
          rightAccessory={rightAccessory}
        >
          <div className={cx('input__wrapper')}>
            {icon && (
              <Icon
                className={cx('input__box__icon--left', {
                  'label-spacing': hasLabel,
                })}
                name={icon}
                aria-hidden
              />
            )}
            <input
              aria-describedby={`${inputId}--msg`}
              aria-disabled={isInputDisabled}
              aria-invalid={hasError || !!errorText}
              aria-label={ariaLabel}
              autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
              className={cx(
                'input__box--input',
                'toga-form-control',
                extraClasses,
                {
                  'has-clear-button': hasClearButton,
                  'has-left-accessory': !!leftAccessory,
                  'has-right-accessory': !!rightAccessory,
                  'has-icon': icon,
                  'has-error': hasError || !!errorText,
                  'is-disabled': isInputDisabled,
                }
              )}
              id={inputId}
              maxLength={maxLength}
              name={name}
              placeholder={placeholder}
              readOnly={isInputDisabled}
              ref={(node) => {
                this.input = node;
                return this.input;
              }}
              required={isInputRequired}
              type={type} // eslint-disable-line jsx-a11y/no-autofocus
              value={value} // eslint-disable-line no-return-assign
              onBlur={this.handleBlur}
              onChange={this.handleValueFieldChange}
              onKeyDown={this.handleKeyDown}
              onKeyPress={this.handleKeyPress}
              {...extraProps}
            />
            {hasClearButton && value && (
              <IconButton
                appearance="utility"
                className={cx(
                  'input__box__clear-button',
                  'input__box__icon--right',
                  {
                    'label-spacing': hasLabel,
                  }
                )}
                name="circle-fill-x"
                title="Clear input"
                isSmall
                onClick={this.resetInputValue}
              />
            )}
          </div>
        </AccessoriesWrapper>
        <div
          aria-live="polite"
          className={cx('input__form-error')}
          id={`${inputId}--error`}
          role="alert"
        >
          {errorText && (
            <div className={cx('toga-form-error')}>{errorText}</div>
          )}
        </div>
        <div
          aria-live={hasError ? 'polite' : 'off'}
          className={cx('input__form-text')}
          id={`${inputId}--msg`}
          role={hasError ? 'alert' : ''}
        >
          {formText && (
            <span
              className={cx('toga-form-text', {
                'has-error': hasError,
              })}
            >
              {formText}
            </span>
          )}
        </div>
      </div>
    );
  }
}

/** Conditionally adds a flex wrapper if Input has accessories
 * to avoid multi-line view
 */
const AccessoriesWrapper = ({
  leftAccessory,
  rightAccessory,
  hasError,
  children,
}) => {
  return (
    <>
      {leftAccessory || rightAccessory ? (
        <div className={cx('input__accessories-wrapper')}>
          {leftAccessory && (
            <span
              className={cx('left-acessory__box', {
                'has-error': hasError,
              })}
            >
              {leftAccessory}
            </span>
          )}
          {children}
          {rightAccessory && (
            <span
              className={cx('right-acessory__box', {
                'has-error': hasError,
              })}
            >
              {rightAccessory}
            </span>
          )}
        </div>
      ) : (
        children
      )}
    </>
  );
};

AccessoriesWrapper.propTypes = {
  children: PropTypes.node,
  hasError: PropTypes.bool,
  leftAccessory: PropTypes.element,
  rightAccessory: PropTypes.element,
};

AccessoriesWrapper.defaultProps = {
  children: null,
  leftAccessory: null,
  rightAccessory: null,
  hasError: false,
};

Input.propTypes = {
  // TODO: replace to hasAutoFocus
  ariaLabel: PropTypes.string,
  autoFocus: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
  className: classNamePropShape,
  errorText: PropTypes.node,
  extraClasses: PropTypes.string,
  extraProps: PropTypes.object,
  formText: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleKeyDown: PropTypes.func,
  hasClearButton: PropTypes.bool,
  hasError: PropTypes.bool,
  hasNoGroup: PropTypes.bool,
  icon: PropTypes.oneOf(availableIcons),
  id: PropTypes.string,
  initValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.any,
  leftAccessory: PropTypes.element,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  rightAccessory: PropTypes.element,
  type: PropTypes.string,
  validator: PropTypes.func,
  wrapperClass: PropTypes.string,
  onEnter: PropTypes.func,
};

Input.defaultProps = {
  ariaLabel: '',
  autoFocus: false,
  className: null,
  handleBlur: () => {},
  handleKeyDown: () => {},
  validator: () => true,
  handleChange: null,
  hasClearButton: false,
  hasError: false,
  name: '',
  type: 'text',
  initValue: '',
  isDisabled: false,
  placeholder: '',
  extraProps: {},
  extraClasses: '',
  maxLength: null,
  label: null,
  id: null,
  icon: null,
  leftAccessory: null,
  rightAccessory: null,
  onEnter: null,
  hasNoGroup: false,
  isRequired: false,
  wrapperClass: null,
  formText: null,
  errorText: null,
};

Input.displayName = 'Input';

Input.filename = __filename;

export default Input;
