/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Newwindow = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M16.458 6.43l-5.228 5.227a.787.787 0 0 0 1.113 1.113l5.226-5.225v1.748a.716.716 0 0 0 1.431 0V5.715A.712.712 0 0 0 18.285 5h-3.579a.716.716 0 0 0 0 1.43h1.752zM19 13.4v-3.328 7.183C19 18.22 18.306 19 17.448 19H6.552C5.695 19 5 18.217 5 17.255V6.745C5 5.78 5.694 5 6.552 5h7.472H10.6a.7.7 0 0 1 0 1.4H6.799c-.223 0-.399.21-.399.467v10.266c0 .253.178.467.399.467H17.2c.223 0 .399-.21.399-.467V13.4a.7.7 0 0 1 1.4 0z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Newwindow.displayName = "Newwindow"

Newwindow.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Newwindow.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Newwindow;
