/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contentdownload = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M19.793 9.509c-.431-2.91-2.912-5.119-5.909-5.119a5.962 5.962 0 0 0-4.886 2.54 4.718 4.718 0 0 0-2.206-.554h-.009a4.534 4.534 0 0 0-4.528 4.534v.005c.001.243.022.48.06.711A3.687 3.687 0 0 0 .08 15.027v.016a3.702 3.702 0 0 0 3.607 3.701H19.35c2.456 0 4.53-2.104 4.53-4.629a4.656 4.656 0 0 0-4.063-4.603l-.024-.003zm-.443 8.21H3.69a2.666 2.666 0 0 1-2.588-2.665v-.028c0-1.224.808-2.26 1.919-2.604a.52.52 0 0 0 .384-.624A3.497 3.497 0 0 1 8.85 8.09a.512.512 0 0 0 .734-.163c.87-1.503 2.467-2.496 4.298-2.496a4.949 4.949 0 0 1 4.936 4.59c.02.283.241.493.512.493h.031a3.608 3.608 0 0 1-.004 7.206h-.007z"/><path transform="scale(1 1)" d="M13.769 12.665l-1.257 1.418V9.862c0-.3-.22-.547-.509-.588H12a.61.61 0 0 0-.512.601v4.205l-1.295-1.416a.511.511 0 0 0-.733-.038.511.511 0 0 0-.033.732l2.143 2.339c.087.14.24.23.413.23H12v.026a.462.462 0 0 0 .354-.166c.086-.1.014 0 .019 0l2.155-2.435a.512.512 0 1 0-.76-.686z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contentdownload.displayName = "Contentdownload"

Contentdownload.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contentdownload.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contentdownload;
