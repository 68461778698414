/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Starfill = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M20.46 9.888a.538.538 0 0 0-.44-.343l-5.238-.594-2.455-4.531a.582.582 0 0 0-.476-.313h-.015a.57.57 0 0 0-.477.335L9.142 9.064l-5.184.873a.536.536 0 0 0-.45.52c0 .152.066.29.173.386L7.55 14.29l-.755 5.022a.533.533 0 0 0 .537.597.554.554 0 0 0 .267-.068l4.594-2.477 4.726 2.244a.55.55 0 0 0 .237.052c.3 0 .543-.234.543-.522a.483.483 0 0 0-.01-.101l-1.026-4.976 3.676-3.627a.509.509 0 0 0 .121-.55v.005z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Starfill.displayName = "Starfill"

Starfill.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Starfill.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Starfill;
