import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Typography.module.scss';

const cx = classNames.bind(styles);

const P = ({ children, className, ...props }) => {
  const classes = cx('p', className);

  return (
    <p className={classes} {...props}>
      {children}
    </p>
  );
};

P.propTypes = {
  children: PropTypes.node.isRequired,
  /** CSS classes */
  className: classNamePropShape,
};

P.defaultProps = {
  className: null,
};

P.displayName = 'P';

P.filename = __filename;

export default P;
