import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Grid.module.scss';

const cx = classNames.bind(styles);

const Col = ({
  auto,
  children,
  className,
  lgColumns,
  mdColumns,
  smColumns,
  variation,
  xsColumns,
  ...props
}) => {
  const classes = [cx({ 'col-auto': auto }, className)];

  const breakpoints = {
    lg: lgColumns,
    md: mdColumns,
    sm: smColumns,
    xs: xsColumns,
  };

  Object.entries(breakpoints).forEach(([breakpoint, value]) => {
    if (
      (typeof value === 'number' && value >= 1 && value <= 12) ||
      (typeof value === 'string' && value === 'auto')
    ) {
      classes.push(cx(`col-${breakpoint}-${value}`));
    } else if (typeof value === 'boolean' && value === true) {
      classes.push(cx(`col-${breakpoint}`));
    }
  });

  if (variation !== null) {
    classes.push(cx(`col-${variation}`));
  }

  return (
    <div className={classes.join(' ')} {...props}>
      {children}
    </div>
  );
};

Col.propTypes = {
  /** Auto width */
  auto: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
  /** Content for the column */
  children: PropTypes.node.isRequired,
  /** A className applied to outermost element */
  className: classNamePropShape,
  /** The number of cols (or auto) you wish to use in LG devices */
  lgColumns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  /** The number of cols (or auto) you wish to use in MD devices */
  mdColumns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  /** The number of cols (or auto) you wish to use in SM devices */
  smColumns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  /** Col variation to handle special cases */
  variation: PropTypes.oneOf(['calendar--single', 'calendar--double']),
  /** The number of cols (or auto) you wish to use in XS devices */
  xsColumns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

Col.defaultProps = {
  auto: false,
  className: null,
  lgColumns: null,
  mdColumns: null,
  smColumns: null,
  variation: null,
  xsColumns: null,
};

Col.displayName = 'Col';

Col.filename = __filename;

Col.spreadAttributes = true;

export default Col;
