/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Socialgoogle = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M20.4966094,10.71075 C20.5825781,11.1650625 20.6439375,11.619375 20.6439375,12.2086875 C20.6439375,17.3533594 17.1937031,21 12,21 C7.0273125,21 3,16.9727344 3,12 C3,7.02726563 7.0273125,3 12,3 C14.431125,3 16.4570156,3.88401563 18.0286406,5.3574375 L15.5852344,7.70259375 C14.9221875,7.06410938 13.7557969,6.31514063 12,6.31514063 C8.9304375,6.31514063 6.425625,8.85675 6.425625,12 C6.425625,15.14325 8.9304375,17.6848594 12,17.6848594 C15.5606719,17.6848594 16.8990469,15.1186875 17.1077344,13.804875 L12,13.804875 L12,10.7107031 L20.4966094,10.7107031 L20.4966094,10.71075 Z" fill="#12131A" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Socialgoogle.displayName = "Socialgoogle"

Socialgoogle.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Socialgoogle.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Socialgoogle;
