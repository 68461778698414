import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Typography.module.scss';

const cx = classNames.bind(styles);

const H6 = ({ children, className, ...props }) => {
  const classes = cx('h6', className);

  return (
    <h6 className={classes} {...props}>
      {children}
    </h6>
  );
};

H6.propTypes = {
  children: PropTypes.node.isRequired,
  /** CSS classes */
  className: classNamePropShape,
};

H6.defaultProps = {
  className: null,
};

H6.displayName = 'H6';

H6.filename = __filename;

export default H6;
