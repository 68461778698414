import Color from 'color';
import {
  isColor,
  colorCanLighten,
  colorCanDarken,
  accessibleTextColor,
} from '../theme';

const white = Color('white');

export const solidButton = (color) => {
  if (!color || !isColor(color)) {
    return null;
  }

  const amount = 0.1;

  const hoverColor = colorCanDarken(color)
    ? Color(color)
        .darken(amount * 2)
        .hex()
    : Color(color)
        .lighten(amount * 40)
        .desaturate(0.15)
        .hex();

  const activeColor = colorCanDarken(color)
    ? Color(color)
        .darken(amount * 4)
        .hex()
    : Color(color)
        .lighten(amount * 80)
        .desaturate(0.4)
        .hex();

  return `
    background-color: ${color};
    color: ${accessibleTextColor(color)};
    &:hover,
    &:focus,
    &:not([disabled]):not([aria-disabled='true']):hover,
    &:not([disabled]):not([aria-disabled='true']):focus,
    &.hover {
      color: ${accessibleTextColor(hoverColor)};
      background-color: ${hoverColor};
    }
    &:active,
    &.active {
      color: ${accessibleTextColor(activeColor)};
      background-color: ${activeColor};
    }
  `;
};

export const outlineButton = (color) => {
  if (!color || !isColor(color)) {
    return null;
  }

  const hoverBackground = colorCanLighten(color)
    ? Color(color).lighten(0.1).mix(white, 0.92).hex()
    : Color(color).darken(0.3).mix(white, 0.85).hex();

  const activeBackground = colorCanLighten(color)
    ? Color(color).mix(white, 0.25).hex()
    : Color(color).darken(0.2).mix(white, 0.4).hex();

  return `
    border-color: ${color};
    color: ${accessibleTextColor(color, 'white')};

    &.hover,
    &:hover,
    &:not([disabled]):not([aria-disabled='true']):hover,
    &:not([disabled]):not([aria-disabled='true']):focus,
    &:focus {
      background-color: ${hoverBackground};
      border-color: ${color};
      color: ${accessibleTextColor(color, hoverBackground)};

      svg {
        fill: ${accessibleTextColor(color, 'white')};
      }
    }

    &.active,
    &:active {
      background-color: ${activeBackground};
      border-color: ${color};
      color: ${accessibleTextColor(activeBackground)};

      svg {
        fill: white;
      }
    }

    svg {
      fill: ${accessibleTextColor(color, 'white')};
    }
  `;
};

export const knockoutButton = (color, background = 'white') => {
  if (!color || !isColor(color)) {
    return null;
  }

  return `
    color: ${accessibleTextColor(color, background)};
    border-color: transparent;
    &.hover,
    &:hover,
    &:not([disabled]):not([aria-disabled='true']):hover,
    &:not([disabled]):not([aria-disabled='true']):focus,
    &:focus {
      border-color: ${accessibleTextColor(color, background)};
      color: ${accessibleTextColor(color, background)};

      svg {
        fill: ${accessibleTextColor(color, background)};
      }
    }
    &.active,
    &:active {
      background-color: ${accessibleTextColor(color, background)};
      border-color: ${accessibleTextColor(color, background).darken(0.5)};
      color: #fff;

      svg {
        fill: white;
      }
    }

    svg {
      fill: ${accessibleTextColor(color, background)};
    }
  `;
};

export const linkButton = (color, background = 'white') => {
  if (!color || !isColor(color)) {
    return null;
  }

  const foreground = accessibleTextColor(color, background);

  return `
    color: ${foreground};
    &.hover,
    &:hover,
    &:focus,
    &:not([disabled]):not([aria-disabled='true']):hover,
    &:not([disabled]):not([aria-disabled='true']):focus {
      color: ${
        colorCanDarken(foreground)
          ? foreground.darken(0.3)
          : foreground.lighten(0.1).mix(white, 0.15)
      };
    }
    &.active,
    &:active {
      color: ${
        colorCanDarken(foreground)
          ? foreground.darken(0.6)
          : foreground.lighten(0.15).mix(white, 0.3)
      };
    }
  `;
};
