import styled from 'styled-components';
import { accessibleTextColor, alternateColor } from '../theme';

export const Header = styled.div`
  && {
    ${(props) =>
      props.theme?.color?.background &&
      `
      background: ${props.theme.color.background};
    `}
  }
`;

export const NavItemLabel = styled.button`
  &&&& {
    ${(props) =>
      props.theme?.color?.primary &&
      props.isActive &&
      `
      color: ${accessibleTextColor(
        props.theme.color.primary,
        props.theme.color.background || 'white'
      )};
      border-color: ${props.theme.color.primary};
    `}

    ${(props) =>
      props.theme?.color?.primary &&
      !props.isActive &&
      `
        &:hover {
          color: ${alternateColor(props.theme.color.primary)};
          border-color: ${alternateColor(props.theme.color.primary)};
        }
    `}
  }
`;
