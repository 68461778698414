export const initialState = {
  filename: null,
  progress: 0,
};

export function reducer(state, action) {
  switch (action.type) {
    case 'increment':
      return { ...state, progress: state.progress + 1 };
    case 'start':
      return { filename: action.payload, progress: 0 };
    case 'complete':
      return { filename: action.payload, progress: 100 };
    case 'reset':
      return initialState;
    default:
      throw new Error();
  }
}
