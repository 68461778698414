/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ThemeContext from '../theme';

import { classNamePropShape } from '../../prop-shapes';
import { KEY_CODES } from '../../key-codes';

import styles from './Tabs.module.scss';
import { Header, NavItemLabel } from './components';
import { navigateTabs } from './utils';

const cx = classNames.bind(styles);

const Tabs = ({
  accessibleLabel,
  className,
  defaultTabId,
  tabItems,
  rightAccessory,
}) => {
  const [currentId, setCurrentTab] = useState(defaultTabId || tabItems[0].id);
  const theme = useContext(ThemeContext);

  const navbar = tabItems.map((item, index) => {
    const classString = cx('nav-link', {
      active: item.id === currentId,
    });
    const isActive = item.id === currentId;

    const handleTabClick = (id) => {
      setCurrentTab(id);
      if (item.onClick) {
        item.onClick(id);
      }
    };

    return (
      <li
        className={cx('nav-item', 'nav-item--mobile-grow')}
        key={`nav_${item.id}`}
        role="presentation"
      >
        <NavItemLabel
          aria-controls={`tab-${item.id}`}
          aria-selected={isActive}
          className={classString}
          id={`tab-${item.id}`}
          isActive={isActive}
          role="tab"
          tabIndex={isActive ? 0 : -1}
          theme={theme}
          onClick={() => handleTabClick(item.id)}
          onKeyDown={(event) => {
            if (event.nativeEvent.keyCode === KEY_CODES.LEFT) {
              navigateTabs(tabItems, index, 'prev', handleTabClick);
            }
            if (event.nativeEvent.keyCode === KEY_CODES.RIGHT) {
              navigateTabs(tabItems, index, 'next', handleTabClick);
            }
          }}
          onKeyPress={(event) => {
            if (event.nativeEvent.keyCode === KEY_CODES.ENTER) {
              handleTabClick(item.id);
            }
          }}
        >
          {item.title}
        </NavItemLabel>
      </li>
    );
  });

  const content = tabItems.map((item) => {
    const classString = cx('tab-pane', {
      active: item.id === currentId,
    });

    return (
      <div
        aria-labelledby={`tab-${item.id}`}
        className={classString}
        key={item.id}
        role="tabpanel"
      >
        {item.content}
      </div>
    );
  });

  return (
    <>
      <Header className={cx('navbar', className)} theme={theme}>
        <ul
          aria-label={accessibleLabel}
          className={cx('nav', 'nav-tabs')}
          role="tablist"
        >
          {navbar}
        </ul>

        <div className={cx('navbar__actions')}>{rightAccessory}</div>
      </Header>

      <div className={cx('tab-content')}>{content}</div>
    </>
  );
};

Tabs.propTypes = {
  /** Label used for accessibility as the aria-label */
  accessibleLabel: PropTypes.string,
  /** A className applied to outermost element */
  className: classNamePropShape,
  /** Show a specific tab when the component is mounted */
  defaultTabId: PropTypes.string,
  /** Node rendered on the right side of the nav. Used for action buttons */
  rightAccessory: PropTypes.node,
  /** Array of objects containing id, title (string or node) and content */
  tabItems: PropTypes.array.isRequired,
};

Tabs.defaultProps = {
  accessibleLabel: 'Tabs',
  className: null,
  defaultTabId: null,
  rightAccessory: null,
};

Tabs.displayName = 'Tabs';

Tabs.filename = __filename;

export default Tabs;
