/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Blank = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <g fillRule="evenodd"><path transform="scale(0.75 0.75)" d="M25.191 31.32a6.809 6.809 0 1 1 0-13.618 6.809 6.809 0 0 1 0 13.617zm3.405-6.3v-1.076H25.7V21.05h-1.076v2.894H21.73v1.076h2.895v2.895h1.076V25.02h2.895zM1.424.447l1.052 1.7A.998.998 0 0 0 2 3v2.377H0V3C0 1.921.57.975 1.424.447z" fillRule="nonzero"/><path transform="scale(0.75 0.75)" d="M10 0v2H4V0zm10 0v2h-6V0z"/><path transform="scale(0.75 0.75)" d="M24.552 1a2.998 2.998 0 0 1 1.424 2.553V5.93h-2V3.553a.998.998 0 0 0-.476-.852L24.552 1z" fillRule="nonzero"/><path transform="scale(0.75 0.75)" d="M26 14.36h-2V9h2z"/><path transform="scale(0.75 0.75)" d="M1.424 24.93A2.998 2.998 0 0 1 0 22.377V20h2v2.377c0 .351.181.67.476.852l-1.052 1.7z" fillRule="nonzero"/><path transform="scale(0.75 0.75)" d="M13 25.377H7v-2h6zM2 15.65H0v-6h2z"/></g>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Blank.displayName = "Blank"

Blank.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Blank.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Blank;
