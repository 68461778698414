/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Caretstrokeup = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M11.295 8.5a1 1 0 0 1 1.412 0l5.043 5.034a.855.855 0 0 1-.608 1.46.876.876 0 0 1-.608-.244l-4.533-4.535-4.533 4.533a.86.86 0 0 1-1.216-1.216l5.043-5.031z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Caretstrokeup.displayName = "Caretstrokeup"

Caretstrokeup.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Caretstrokeup.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Caretstrokeup;
