/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Image = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M9.08 13.24L6.18 17h11.64l-3.74-5-2.9 3.74-2.1-2.5zm10.42 4.58c0 .453-.167.847-.5 1.18-.333.333-.727.5-1.18.5H6.18c-.453 0-.847-.167-1.18-.5a1.614 1.614 0 0 1-.5-1.18V6.18c0-.453.167-.847.5-1.18.333-.333.727-.5 1.18-.5h11.64c.453 0 .847.167 1.18.5.333.333.5.727.5 1.18v11.64z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Image.displayName = "Image"

Image.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Image.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Image;
