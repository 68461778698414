/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contentpresentation = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M23.481 2.649l.001-.036a1.2 1.2 0 0 0-1.16-1.199H1.645c-.676.016-1.22.56-1.238 1.234V2.7c0 .586.425 1.071.983 1.165l.008 13.43c0 .295.363.664.906.664h8.841v1.258l-6.867 2.806a.543.543 0 0 0 .623.885l6.962-2.855 7.21 2.865a.484.484 0 0 0 .27.084c.186 0 .35-.1.441-.246a.544.544 0 0 0-.198-.734l-7.26-2.799v-1.267h9.309c.381 0 .695-.29.732-.662V3.872a1.275 1.275 0 0 0 1.117-1.222l-.002-.001zM2.577 16.774V3.777h18.61v12.997H2.576zM22.321 2.89H1.644a.295.295 0 0 1-.133-.297.294.294 0 0 1 .132-.293h20.678a.295.295 0 0 1 .133.297.294.294 0 0 1-.132.293h-.001z"/><path transform="scale(1 1)" d="M16.135 7.646l-3.13 3.126-2.251-1.837a.53.53 0 0 0-.689.015l-3.907 3.48a.532.532 0 0 0 .706.793l3.57-3.177 2.272 1.856a.528.528 0 0 0 .712-.036l3.48-3.48a.532.532 0 0 0-.763-.737v-.003z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contentpresentation.displayName = "Contentpresentation"

Contentpresentation.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contentpresentation.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contentpresentation;
