/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Arrowupright = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" fillRule="evenodd" d="M16.941 6h.02c.024 0 .05.002.074.004L16.94 6a1.067 1.067 0 0 1 .206.02l.021.004a1.051 1.051 0 0 1 .315.125l.084.055a1.063 1.063 0 0 1 .123.106l.008.009c.023.023.044.047.065.072l-.073-.08a.911.911 0 0 1 .096.11l.01.012.055.084a1.05 1.05 0 0 1 .15.543v7.059a1.059 1.059 0 0 1-2.118 0V9.614L7.808 17.69a1.059 1.059 0 0 1-1.498-1.498l8.075-8.075H9.882a1.059 1.059 0 1 1 0-2.117h7.06z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Arrowupright.displayName = "Arrowupright"

Arrowupright.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Arrowupright.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Arrowupright;
