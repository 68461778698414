/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Pin = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M13.61 17.42c.338-.512.487-1.074.43-1.56-.043-.355-.046-.846.158-1.14l3.096-4.457c.204-.294.381-.521.395-.507l.026.025c.393.393 1.07.389 1.68.041.312-.176.309-.598.055-.851l-4.786-4.786c-.253-.253-.675-.257-.851.054-.347.612-.352 1.288.042 1.682l.025.025c.014.014-.213.191-.508.395L8.916 9.437c-.295.204-.785.202-1.14.16-.487-.059-1.048.09-1.56.428-.3.197-.296.616-.043.869L9.01 13.73l-3.15 3.15.006.008c-1.262 1.33-2.062 2.522-1.824 2.76.238.238 1.43-.562 2.76-1.825l.007.007 3.151-3.15 2.781 2.781c.254.253.672.257.87-.043z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Pin.displayName = "Pin"

Pin.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Pin.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Pin;
