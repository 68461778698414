/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Dataimport = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M14.919 10.577l-2.3-2.289v11.086a.6.6 0 0 1-.187.44.606.606 0 0 1-.442.186.597.597 0 0 1-.452-.186.617.617 0 0 1-.177-.44V8.327l-2.28 2.25a.572.572 0 0 1-.442.195.572.572 0 0 1-.442-.195.556.556 0 0 1-.197-.43c0-.17.066-.32.197-.45L12.01 6l3.813 3.697a.89.89 0 0 1 .128.215.596.596 0 0 1 .049.234.604.604 0 0 1-.05.235.597.597 0 0 1-.127.196.623.623 0 0 1-.452.195.623.623 0 0 1-.452-.195zM2 4.279c0-.36.12-.663.361-.91.241-.246.537-.369.889-.369h17.5c.352 0 .648.123.889.37.24.246.361.55.361.91v8.44c0 .347-.12.647-.361.9s-.537.38-.889.38h-5.645v-1.3h4.883a.74.74 0 0 0 .537-.219.726.726 0 0 0 .225-.54V5.06a.759.759 0 0 0-.225-.55.725.725 0 0 0-.537-.23L4.012 4.3a.725.725 0 0 0-.537.23.759.759 0 0 0-.225.55v6.862c0 .214.075.394.225.54a.74.74 0 0 0 .537.22h4.863V14H3.25a1.18 1.18 0 0 1-.889-.38 1.26 1.26 0 0 1-.361-.9V4.28z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Dataimport.displayName = "Dataimport"

Dataimport.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Dataimport.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Dataimport;
