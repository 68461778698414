import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { IconButton } from '../../Button';

import styles from '../ImageInput.module.scss';

const cx = classNames.bind(styles);

export function DownloadButton({ imageUrl }) {
  return (
    <IconButton
      appearance="knockout"
      className={cx('download-button')}
      href={imageUrl}
      name="data-download"
      target="_blank"
      title="Download image"
      download
      isDark
      isSmall
    />
  );
}

DownloadButton.propTypes = {
  imageUrl: PropTypes.string,
};

DownloadButton.defaultProps = {
  imageUrl: '',
};

DownloadButton.filename = __filename;

DownloadButton.displayName = 'DownloadButton';
