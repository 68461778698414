/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Caretstrokeleft = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M14.61 18h-.144a.858.858 0 0 1-.536-.25l-5.033-5.042a1 1 0 0 1 0-1.413l5.031-5.043a.86.86 0 1 1 1.216 1.216L10.61 12l4.536 4.533a.877.877 0 0 1 0 1.217.858.858 0 0 1-.536.249z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Caretstrokeleft.displayName = "Caretstrokeleft"

Caretstrokeleft.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Caretstrokeleft.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Caretstrokeleft;
