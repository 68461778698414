/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Webinarreplay = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M16 .132c-6.623 0-12 5.377-12 12 0 5.023 3.094 9.326 7.477 11.113l-3.795 5.796a.334.334 0 0 0-.057.182c0 .445.362.766.818 1.011.456.245 1.073.444 1.818.614 1.49.338 3.505.545 5.739.545s4.248-.207 5.739-.545c.745-.17 1.362-.369 1.818-.614.456-.245.818-.566.818-1.011a.334.334 0 0 0-.057-.182l-3.795-5.796a12.067 12.067 0 0 0 7.102-8.102.367.367 0 0 0-.705-.204c-1.26 4.847-5.674 8.465-10.92 8.465A11.267 11.267 0 0 1 4.727 12.132C4.727 5.902 9.77.859 16 .859c6.23 0 11.273 5.043 11.273 11.273a.364.364 0 1 0 .727 0c0-6.623-5.377-12-12-12zm0 6.545a5.46 5.46 0 0 0-5.455 5.455A5.46 5.46 0 0 0 16 17.586a5.46 5.46 0 0 0 5.455-5.454A5.46 5.46 0 0 0 16 6.677zm0 .727a4.722 4.722 0 0 1 4.727 4.728A4.722 4.722 0 0 1 16 16.859a4.722 4.722 0 0 1-4.727-4.727A4.722 4.722 0 0 1 16 7.404zm-3.818 16.103a11.976 11.976 0 0 0 7.636 0l3.773 5.75c-.014.067-.085.2-.41.375-.353.19-.91.374-1.613.534-1.405.32-3.384.523-5.568.523-2.184 0-4.163-.204-5.568-.523-.703-.16-1.26-.344-1.614-.534-.324-.174-.395-.308-.409-.375l3.773-5.75z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Webinarreplay.displayName = "Webinarreplay"

Webinarreplay.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Webinarreplay.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Webinarreplay;
