/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contentquiz = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M18.813.192H5.053c-1.402 0-2.349 1.28-2.349 2.672v18.371c0 1.402.96 2.595 2.368 2.595 0 0 10.88 0 10.96-.054a5.552 5.552 0 0 0 5.216-5.539V2.861c0-1.398-1.034-2.669-2.435-2.669zm-3.034 17.28c-.352 0-.611.563-.611.915v3.885H5.04c-.694 0-1.069-.339-1.069-1.034V2.86c0-.694.374-1.392 1.069-1.392H18.8c.694 0 1.155.698 1.155 1.392v14.611h-4.173l-.003.001zm.669 4.97v-3.69H20c-.358 1.92-1.632 3.414-3.552 3.69z"/><path transform="scale(1 1)" d="M9.907 8.95l-2.285 2.317-1.034-1.146a.528.528 0 0 0-.346-.201l-.033-.001a.417.417 0 0 0-.29.116.48.48 0 0 0-.019.704l1.28 1.414a.61.61 0 0 0 .414.195h.042c.12 0 .229-.045.313-.119l2.646-2.636a.558.558 0 0 0 .182-.403v-.014a.44.44 0 0 0-.125-.307.503.503 0 0 0-.745.079v.002z"/><path transform="scale(1 1)" d="M8.358 6.122a4.182 4.182 0 1 0 4.182 4.185 4.19 4.19 0 0 0-4.182-4.186v.001zm0 7.494a3.309 3.309 0 1 1 3.322-3.309A3.313 3.313 0 0 1 8.368 13.6h-.01v.016zm8.093-3.254l1.28-1.28a.451.451 0 0 0-.32-.773.451.451 0 0 0-.32.133l-1.28 1.28-1.28-1.28a.451.451 0 0 0-.773.32c0 .125.051.238.133.32l1.28 1.28-1.28 1.28a.442.442 0 0 0 0 .64.447.447 0 0 0 .64.001l1.28-1.28 1.28 1.28a.451.451 0 0 0 .773-.32.451.451 0 0 0-.133-.32l-1.28-1.281z"/><path transform="scale(1 1)" d="M15.84 6.323a4 4 0 1 0 4 4 4.007 4.007 0 0 0-4-4zm0 7.165a3.165 3.165 0 1 1 0-6.33 3.165 3.165 0 0 1 0 6.33z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contentquiz.displayName = "Contentquiz"

Contentquiz.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contentquiz.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contentquiz;
