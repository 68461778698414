/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Userlogin = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12.41 15.16l2.285-2.285H2.625a.6.6 0 0 1-.44-.186A.6.6 0 0 1 2 12.25a.617.617 0 0 1 .625-.625h12.031L12.41 9.359a.567.567 0 0 1-.195-.44c0-.175.065-.321.195-.439a.556.556 0 0 1 .43-.195.62.62 0 0 1 .45.195l3.69 3.79-3.69 3.789a.89.89 0 0 1-.216.127.605.605 0 0 1-.234.048.605.605 0 0 1-.235-.048.597.597 0 0 1-.195-.127.617.617 0 0 1-.195-.45c0-.169.065-.319.195-.449zm8.34-12.91c.352 0 .648.12.889.361.24.241.361.537.361.889V21c0 .352-.12.648-.361.889-.241.24-.537.361-.889.361H9.5c-.339 0-.632-.12-.879-.361A1.192 1.192 0 0 1 8.25 21v-5.645h1.27v4.883a.75.75 0 0 0 .214.537c.144.15.32.225.528.225h9.726a.733.733 0 0 0 .537-.225.733.733 0 0 0 .225-.537l-.02-15.976a.733.733 0 0 0-.224-.537.733.733 0 0 0-.537-.225h-9.707a.701.701 0 0 0-.528.225.75.75 0 0 0-.214.537v4.863H8.25V3.5c0-.352.124-.648.371-.889.247-.24.54-.361.879-.361h11.25z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Userlogin.displayName = "Userlogin"

Userlogin.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Userlogin.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Userlogin;
