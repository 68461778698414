/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Reply = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M11.875 9.669V5.333a.334.334 0 0 0-.193-.308.299.299 0 0 0-.34.072l-6.25 6.667a.35.35 0 0 0 0 .471l6.25 6.667a.303.303 0 0 0 .34.073.334.334 0 0 0 .193-.308v-4.322c1.354.072 6.636.558 7.513 3.749a.31.31 0 0 0 .344.236A.327.327 0 0 0 20 18c0-7.647-6.661-8.284-8.125-8.331z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Reply.displayName = "Reply"

Reply.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Reply.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Reply;
