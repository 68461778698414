/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Arrowright = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M17.26 12.853l-12.44.008a.797.797 0 0 1-.58-.252.89.89 0 0 1 0-1.215.797.797 0 0 1 .58-.252h12.43l-3.631-3.46a.815.815 0 0 1-.245-.58c0-.224.093-.428.244-.578a.877.877 0 0 1 .608-.241c.238 0 .453.092.608.24l4.981 4.756a1 1 0 0 1 0 1.446l-4.979 4.755a.879.879 0 0 1-.608.24c-.474 0-.86-.367-.86-.82 0-.225.097-.43.253-.578l3.64-3.469z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Arrowright.displayName = "Arrowright"

Arrowright.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Arrowright.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Arrowright;
