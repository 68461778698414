/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Shield = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12 2.82l7.5 3.36v5c0 1.52-.327 2.987-.98 4.4a10.849 10.849 0 0 1-2.66 3.56c-1.16 1.013-2.447 1.693-3.86 2.04-1.413-.347-2.7-1.027-3.86-2.04a10.849 10.849 0 0 1-2.66-3.56 10.372 10.372 0 0 1-.98-4.4v-5L12 2.82zM12 12v7.46a7.576 7.576 0 0 0 2.77-1.59 9.188 9.188 0 0 0 2.05-2.64 9.247 9.247 0 0 0 1-3.23H12zm0 0V4.66L6.18 7.24V12H12z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Shield.displayName = "Shield"

Shield.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Shield.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Shield;
