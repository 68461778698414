/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Arrowleft = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M6.895 11.15l12.234-.008c.223 0 .446.084.616.252.34.335.34.88 0 1.215a.874.874 0 0 1-.616.252H6.905l3.723 3.673a.87.87 0 0 1 0 1.216.872.872 0 0 1-.616.253.872.872 0 0 1-.616-.253l-5.1-5.043a.99.99 0 0 1 0-1.412l5.098-5.043A.874.874 0 0 1 10.01 6c.481 0 .871.385.871.86a.851.851 0 0 1-.255.608l-3.73 3.682z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Arrowleft.displayName = "Arrowleft"

Arrowleft.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Arrowleft.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Arrowleft;
