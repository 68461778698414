/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Warning = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12.75 13.75v-3.5a.75.75 0 1 0-1.5 0v3.5a.75.75 0 1 0 1.5 0zm0 3a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0zm-8.622 1.23L11.18 5.005c.263-.483.843-.648 1.295-.367a.982.982 0 0 1 .344.367l7.053 12.977c.262.483.109 1.102-.344 1.382a.902.902 0 0 1-.476.137H4.947c-.523 0-.947-.453-.947-1.012 0-.178.044-.353.128-.507z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Warning.displayName = "Warning"

Warning.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Warning.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Warning;
