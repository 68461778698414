import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Modal.module.scss';

const cx = classNames.bind(styles);

const ModalBody = ({
  children,
  hasContentOverflow,
  hasTitle,
  isFullWidth,
  ...rest
}) => (
  <div
    className={cx('modal__body', {
      'no-padding': isFullWidth,
      'no-title': !hasTitle,
      'allow-overflow': hasContentOverflow,
    })}
    {...rest}
  >
    {children}
  </div>
);

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  hasContentOverflow: PropTypes.bool,
  hasTitle: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

ModalBody.defaultProps = {
  isFullWidth: false,
  hasContentOverflow: false,
  hasTitle: false,
};

ModalBody.displayName = 'ModalBody';

ModalBody.filename = __filename;

ModalBody.spreadAttributes = true;

export { ModalBody }; // eslint-disable-line import/prefer-default-export
