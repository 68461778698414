/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Clipboard = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M10.626 3.717v-1.47c0-.281.18-.47.451-.47h10.735c.27 0 .45.189.45.47v1.47h7.304c.273 0 .454.185.454.463v25.626c0 .231-.227.416-.454.416H2.676c-.272 0-.454-.185-.454-.462V4.18c0-.278.182-.463.454-.463h7.95zm0 .925H3.131v24.701h25.935v-24.7h-6.803v1.883c0 .235-.226.423-.451.423H11.077c-.27 0-.45-.188-.45-.47V4.642zM8.04 17.94a.646.646 0 1 1 0-1.293.646.646 0 0 1 0 1.293zm0-5.818a.646.646 0 1 1 0-1.293.646.646 0 0 1 0 1.293zm0 11.637a.646.646 0 1 1 0-1.293.646.646 0 0 1 0 1.293zm8.564 0h-3.552c-.292 0-.486-.13-.486-.324s.194-.323.486-.323h3.552c.292 0 .487.13.487.323 0 .194-.243.324-.487.324zm0-5.819h-3.552c-.292 0-.486-.129-.486-.323s.194-.323.486-.323h3.552c.292 0 .487.13.487.323 0 .194-.243.323-.487.323zm0-5.818h-3.552c-.292 0-.486-.13-.486-.323 0-.194.194-.323.486-.323h3.552c.292 0 .487.129.487.323s-.243.323-.487.323zM11.528 6.01h9.833V2.72h-9.833v3.29z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Clipboard.displayName = "Clipboard"

Clipboard.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Clipboard.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Clipboard;
