/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Key = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12.532 10.333C11.86 8.392 10.045 7 7.909 7 5.201 7 3 9.242 3 12s2.2 5 4.91 5c2.135 0 3.95-1.392 4.622-3.333h3.559V17h3.273v-3.333H21v-3.334h-8.468zm-4.623 3.334c-.9 0-1.636-.75-1.636-1.667s.736-1.667 1.636-1.667c.9 0 1.636.75 1.636 1.667s-.736 1.667-1.636 1.667z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Key.displayName = "Key"

Key.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Key.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Key;
