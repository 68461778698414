/*
  Naming convention is derived from the keydown
  event object:

  - Key name: event.code
  - Value: event.keyCode
 */
export const KEY_CODES = {
  ESCAPE: 27,
  SPACE: 32,
  ENTER: 13,
  TAB: 9,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  LEFT: 37,
};
