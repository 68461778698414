/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Beta = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.7058823529411765 0.7058823529411765)" d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h30a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/><path transform="scale(0.7058823529411765 0.7058823529411765)" d="M5 5.03h2.222c1.012 0 1.747.143 2.204.431.458.289.686.747.686 1.375 0 .426-.1.776-.3 1.05a1.2 1.2 0 0 1-.798.493v.049c.452.1.778.29.979.566.2.277.3.645.3 1.104 0 .65-.235 1.159-.706 1.523-.47.365-1.109.547-1.916.547H5V5.029zm1.514 2.826h.879c.41 0 .707-.063.89-.19.185-.127.277-.337.277-.63 0-.273-.1-.47-.3-.588-.2-.12-.517-.178-.95-.178h-.796v1.586zm0 1.202v1.86H7.5c.417 0 .724-.08.923-.24.198-.159.298-.403.298-.732 0-.592-.423-.888-1.27-.888h-.937zm9.316 3.11h-4.11V5.029h4.111v1.24h-2.598v1.568h2.417v1.24h-2.417v1.841h2.598v1.25zm4.243 0H18.56V6.289h-1.94V5.03h5.39v1.26h-1.938v5.879zm7.407 0l-.517-1.7H24.36l-.517 1.7h-1.631L24.732 5h1.85l2.53 7.168H27.48zm-.878-2.969a502.62 502.62 0 0 1-.809-2.612 7.374 7.374 0 0 1-.129-.479c-.107.417-.415 1.447-.923 3.091h1.86z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Beta.displayName = "Beta"

Beta.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Beta.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Beta;
