/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contentmultimedia = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M22.494 2.075H1.612c-.732 0-1.371.318-1.371 1.051v16.227c0 .732.647 1.646 1.371 1.646h20.882c.732 0 1.328-.92 1.328-1.646V3.126c0-.732-.595-1.05-1.328-1.05zm.23 17.006a.823.823 0 0 1-.822.823H2.153a.84.84 0 0 1-.823-.822V5.367h21.395V19.08zm0-14.537H1.33v-.976c0-.219.177-.395.395-.395H22.33c.219 0 .396.176.396.395v.976z"/><path transform="scale(1 1)" d="M2.556 3.83a.331.331 0 1 1-.663.001.331.331 0 0 1 .663 0zm1.254 0a.331.331 0 1 1-.663.001.331.331 0 0 1 .663 0zm1.256 0a.331.331 0 1 1-.663.001.331.331 0 0 1 .663 0zm.781 14.154h10.028c1.84 0 3.292-1.646 3.292-3.492V8.488c0-.527-.373-.65-.9-.65H5.847c-.527 0-.952.126-.952.65v8.388c0 .526.425 1.108.952 1.108zm10.029-1.097h-.274v-2.194h2.52a2.306 2.306 0 0 1-2.243 2.194h-.003zM6.002 8.933H18.07v4.662h-2.973c-.302 0-.601.055-.601.36v2.932H5.993V8.933h.009z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contentmultimedia.displayName = "Contentmultimedia"

Contentmultimedia.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contentmultimedia.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contentmultimedia;
