import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ThemeContext from '../theme';

import { classNamePropShape } from '../../prop-shapes';

import styles from './CircularSpinner.module.scss';

const cx = classNames.bind(styles);

export const VARIATIONS = ['default', 'small', 'medium', 'large', 'xlarge'];

const CircularSpinner = ({
  accessibilityLabel,
  className,
  color,
  variation,
  text,
}) => {
  const theme = useContext(ThemeContext);
  let borderColor = color;

  if (theme && theme.color && theme.color.primary) {
    borderColor = theme.color.primary;
  }

  if (theme && theme.spinner && theme.spinner.color) {
    borderColor = theme.spinner.color;
  }

  // If no custom text is provided the default for accessibilityLabel
  // will be used for Screen Reader / Assistive technologies
  const screenReaderText = text || accessibilityLabel;

  return (
    <div className={cx('spinner-container')}>
      <div className={cx(['spinner', `spinner--${variation}`, className])}>
        <div className={cx('circle')}>
          <div
            className={cx('circle-inner')}
            style={{
              borderLeftColor: borderColor,
              borderTopColor: borderColor,
            }}
          />
        </div>
        <div className={cx(['circle', 'circle-2'])}>
          <div
            className={cx('circle-inner')}
            style={{
              borderLeftColor: borderColor,
              borderTopColor: borderColor,
            }}
          />
        </div>
      </div>
      {text && <p aria-hidden="true">{text}</p>}
      {screenReaderText && (
        <p className={cx('spinner-text')} role="alert">
          {screenReaderText}
        </p>
      )}
    </div>
  );
};

CircularSpinner.propTypes = {
  accessibilityLabel: PropTypes.string,
  className: classNamePropShape,
  color: PropTypes.string,
  text: PropTypes.string,
  variation: PropTypes.string,
};

CircularSpinner.defaultProps = {
  accessibilityLabel: 'Loading',
  className: null,
  color: null,
  text: null,
  variation: 'default',
};

CircularSpinner.displayName = 'CircularSpinner';

CircularSpinner.filename = __filename;

export default CircularSpinner;
