/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Pagenew = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M17.873 3.08H6.2c-1.12 0-1.439.715-1.439 1.347v15.561a1.495 1.495 0 0 0 1.437 1.39h8.66c2.82 0 4.038-1.805 4.038-4.402V4.547c-.002-.63-.125-1.467-1.023-1.467h-.001zM14.83 20.226v-2.892a.231.231 0 0 1 .251-.231h2.76c-.009 1.857-.915 3.162-3.011 3.116v.007zm3.035-4.203H14.59a.927.927 0 0 0-.927.927v3.266h-7.29a.552.552 0 0 1-.552-.551V4.837a.603.603 0 0 1 .6-.6h10.935c.218 0 .512.122.512.739v11.027l-.002.02z"/><path transform="scale(1 1)" d="M14.578 11.692c0-.188-.072-.505-.558-.505h-1.853v-2.02a.731.731 0 0 0-.123-.506.447.447 0 0 0-.311-.125h-.024a.408.408 0 0 0-.405.229.95.95 0 0 0-.055.364v-.002 2.062H9.49c-.376 0-.607.195-.607.505.005.28.23.505.509.51.279.005.035 0 1.935 0v1.872c.012.303.079.54.438.54.141 0 .405-.062.405-.549v-1.869h1.854c.34-.044.556-.236.556-.505v-.001z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Pagenew.displayName = "Pagenew"

Pagenew.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Pagenew.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Pagenew;
