import { css } from 'styled-components';
import { isColor } from '../theme';

import {
  solidButton,
  outlineButton,
  knockoutButton,
  linkButton,
} from './buttonVariations';

const appearanceStyles = {
  primary: solidButton,
  secondary: outlineButton,
  knockout: knockoutButton,
  link: linkButton,
  'accessible-link': linkButton,
};

const getButtonColor = ({
  color,
  hasDanger,
  isUpgrade,
  isAlternate,
  theme,
}) => {
  if (isColor(color)) {
    return color;
  }

  if (!(hasDanger || isUpgrade || isAlternate)) {
    return theme.color?.primary;
  }

  if (hasDanger && !(isUpgrade || isAlternate)) {
    return theme.color?.danger;
  }

  if (isUpgrade || isAlternate) {
    return theme.color?.secondary;
  }

  return null;
};

const buttonCSS = css`
  // Border radius
  ${(props) =>
    (props.theme?.button?.borderRadius ||
      props.theme?.button?.borderRadius === 0) &&
    `
    border-radius: ${props.theme.button.borderRadius}px;
  `}

  // Text transform
  ${(props) =>
    props.theme?.button?.allCaps === false &&
    `
    text-transform: none;
  `}

  // Font family
  ${(props) =>
    props.theme?.button?.fontFamily &&
    `
    font-family: '${props.theme.button.fontFamily}';
  `}

  // Font weight
  ${(props) =>
    props.theme?.button?.fontWeight &&
    `
    font-weight: ${props.theme.button.fontWeight};
  `}

  // Button variations
  ${(props) =>
    appearanceStyles[props.appearance] &&
    getButtonColor(props) &&
    `
  ${appearanceStyles[props.appearance](getButtonColor(props))}`}
`;

export default buttonCSS;
