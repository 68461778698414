/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Flagship = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M16.087 6.449a.449.449 0 0 0-.084.014L.743 11.849a.449.449 0 0 0 0 .855l5.99 2.118v8.022c0 .588.367 1.092.883 1.487s1.204.723 2.047.996c1.687.545 3.969.87 6.494.87s4.808-.325 6.494-.87c.843-.273 1.532-.6 2.047-.996.516-.395.884-.899.884-1.487v-8.022l5.386-1.908v5.204a.449.449 0 1 0 .897 0v-5.835a.449.449 0 0 0-.294-.434L16.31 6.463a.449.449 0 0 0-.224-.014zm.07.925l13.9 4.91-13.9 4.894-13.899-4.895 13.9-4.909zM7.63 15.13l8.373 2.96c.1.036.209.036.308 0l8.373-2.96v7.714c0 .216-.142.473-.533.772-.39.299-1.001.604-1.78.855-1.56.504-3.766.828-6.214.828-2.447 0-4.654-.324-6.213-.828-.78-.251-1.391-.556-1.781-.855-.39-.3-.533-.556-.533-.772V15.13z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Flagship.displayName = "Flagship"

Flagship.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Flagship.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Flagship;
