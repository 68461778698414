/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Design = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M24.778 21.862a1.267 1.267 0 1 0 0 2.534 1.267 1.267 0 0 0 0-2.534zm-10.716 6.125h10.894a4.582 4.582 0 0 0 4.577-4.596V14.44h-15.47v13.547zm1.111-12.445h13.25v7.85a3.471 3.471 0 0 1-3.467 3.461h-9.778l-.005-11.31zM2.591 27.987h9.542V3.91H2.591v24.076zM3.702 5.044h7.32v21.81h-7.32V5.043zm10.36-1.133v8.551h15.471v-8.55h-15.47zm14.36 7.44H15.173V5.022h13.25v6.33z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Design.displayName = "Design"

Design.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Design.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Design;
