/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Message = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M17.32 9V7.32h-10V9h10zm0 2.5V9.82h-10v1.68h10zm0 2.5v-1.68h-10V14h10zM19 4c.453 0 .84.16 1.16.48.32.32.48.707.48 1.16v10c0 .453-.16.847-.48 1.18-.32.333-.707.5-1.16.5H7.32L4 20.64v-15c0-.453.16-.84.48-1.16.32-.32.707-.48 1.16-.48H19z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Message.displayName = "Message"

Message.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Message.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Message;
