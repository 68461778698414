import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Surface from '../Surface';
import { classNamePropShape } from '../../prop-shapes';

import styles from './Section.module.scss';

const cx = classNames.bind(styles);

export const Section = ({ className, children }) => {
  const classes = cx('section', className);

  return (
    <Surface className={classes} tag="section">
      {children}
    </Surface>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: classNamePropShape,
};

Section.defaultProps = {
  className: null,
};

Section.filename = __filename;

Section.displayName = 'Section';
