/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Circlefillx = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M6.657 17.97A8 8 0 1 1 17.97 6.658 8 8 0 0 1 6.657 17.97zm5.657-6.717L9.839 8.778a.75.75 0 1 0-1.06 1.06l2.474 2.476-2.475 2.475a.75.75 0 0 0 1.06 1.06l2.476-2.475 2.475 2.475a.75.75 0 0 0 1.06-1.06l-2.475-2.475 2.475-2.475a.75.75 0 0 0-1.06-1.06l-2.475 2.474z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Circlefillx.displayName = "Circlefillx"

Circlefillx.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Circlefillx.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Circlefillx;
