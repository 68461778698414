/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Connection = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M17.763 6l-1.049 1.201L20.903 12l-4.189 4.799L17.763 18 23 12l-5.237-6zM7.286 7.201L6.237 6 1 12l5.237 6 1.049-1.201L3.097 12l4.189-4.799zm0 6.435H9.38v-2.181H7.286v2.181zm3.143 0h2.095v-2.181h-2.095v2.181zm6.285-2.181H14.62v2.181h2.095v-2.181z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Connection.displayName = "Connection"

Connection.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Connection.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Connection;
