/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Arrowgrow = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M5.72 17.103l3.997-4.005a.86.86 0 0 1 1.216 1.216l-3.995 3.995 2.367-.001a.876.876 0 0 1 .86.86.855.855 0 0 1-.86.86l-4.298-.007a1 1 0 0 1-.998-.998L4 14.727a.86.86 0 1 1 1.72 0v2.376zm8.551-6.154a.86.86 0 0 1-1.216-1.216l3.996-3.996-2.367.001a.876.876 0 0 1-.86-.86.855.855 0 0 1 .86-.86l4.297.007a1 1 0 0 1 .999.998l.007 4.296a.86.86 0 1 1-1.72 0V6.943l-3.996 4.006z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Arrowgrow.displayName = "Arrowgrow"

Arrowgrow.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Arrowgrow.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Arrowgrow;
