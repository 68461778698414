/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contentsurvey = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M16.563.95a.967.967 0 0 0-1.067-.944H8.648A.967.967 0 0 0 7.585.95H5.348A2.333 2.333 0 0 0 3 3.29l.002.097V21.45c0 1.38.969 2.55 2.33 2.55 0 0 10.544 0 10.627-.053 2.834-.17 5.068-2.54 5.068-5.439V3.382c0-1.38-.963-2.43-2.312-2.43L16.563.95zm-1.44 17.695v4.032H5.35a1.124 1.124 0 0 1-1.1-1.233V3.38a1.076 1.076 0 0 1 1.065-1.172l2.043.001v-.063a1.52 1.52 0 0 0 1.288 1.321l6.856.001c.59 0 .864-.724.864-1.322v.063h2.352c.674 0 1.066.485 1.066 1.17v14.574h-4.008a.712.712 0 0 0-.654.691zm1.242 3.99v-3.422h3.496c-.268 1.81-1.692 3.213-3.474 3.421l-.022.001z"/><path transform="scale(1 1)" d="M17.44 10.26h-4.548a.458.458 0 0 0-.454.461c0 .255.203.461.454.461h4.548a.458.458 0 0 0 .455-.46.458.458 0 0 0-.455-.462zm0 3.994h-4.548a.458.458 0 0 0-.454.46c0 .255.203.462.454.462h4.548a.458.458 0 0 0 .455-.461.458.458 0 0 0-.455-.46zM12.316 7.09a.556.556 0 0 0-.793 0L8.49 10.07 7.084 8.325a.56.56 0 0 0-.434-.204.569.569 0 0 0-.565.573c0 .14.05.27.132.37l1.8 2.207c.098.12.24.197.402.206h.032a.556.556 0 0 0 .385-.172l3.462-3.41a.572.572 0 0 0 .018-.805zm-2.719 5.935H8.132c-.6 0-1.089.494-1.089 1.103v1.484c0 .61.487 1.104 1.089 1.104h1.465c.6 0 1.088-.494 1.088-1.104v-1.484c0-.608-.487-1.103-1.088-1.103zm.179 2.593a.17.17 0 0 1-.17.172h-1.48a.17.17 0 0 1-.17-.172v-1.495a.17.17 0 0 1 .17-.172h1.476a.17.17 0 0 1 .17.172v1.495h.004z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contentsurvey.displayName = "Contentsurvey"

Contentsurvey.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contentsurvey.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contentsurvey;
