/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Resourcelibrary = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M5.965 0a2.676 2.676 0 0 0-2.672 2.672v25.832a3.217 3.217 0 0 0 3.213 3.213h22.217V0H5.965zm22.073 4.664h-.847V.68h.847v3.983zM5.965.68H26.51v3.983H5.965a1.991 1.991 0 0 1 0-3.983zM3.974 28.504V4.453l.105.109a2.655 2.655 0 0 0 1.886.786h2.482v25.689H6.51a2.536 2.536 0 0 1-2.536-2.533zm5.154 2.533V5.348h18.927v25.689H9.128z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Resourcelibrary.displayName = "Resourcelibrary"

Resourcelibrary.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Resourcelibrary.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Resourcelibrary;
