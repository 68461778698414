/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Caretfillup = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M16.146 13.146a.5.5 0 0 1-.353.854H8.207a.5.5 0 0 1-.353-.854l3.439-3.439a1 1 0 0 1 1.414 0l3.44 3.44z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Caretfillup.displayName = "Caretfillup"

Caretfillup.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Caretfillup.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Caretfillup;
