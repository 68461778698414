/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Handledrag = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M9.35 5h1.3a.35.35 0 0 1 .35.35v1.3a.35.35 0 0 1-.35.35h-1.3A.35.35 0 0 1 9 6.65v-1.3A.35.35 0 0 1 9.35 5zm0 4h1.3a.35.35 0 0 1 .35.35v1.3a.35.35 0 0 1-.35.35h-1.3a.35.35 0 0 1-.35-.35v-1.3A.35.35 0 0 1 9.35 9zm0 4h1.3a.35.35 0 0 1 .35.35v1.3a.35.35 0 0 1-.35.35h-1.3a.35.35 0 0 1-.35-.35v-1.3a.35.35 0 0 1 .35-.35zm0 4h1.3a.35.35 0 0 1 .35.35v1.3a.35.35 0 0 1-.35.35h-1.3a.35.35 0 0 1-.35-.35v-1.3a.35.35 0 0 1 .35-.35zm4-12h1.3a.35.35 0 0 1 .35.35v1.3a.35.35 0 0 1-.35.35h-1.3a.35.35 0 0 1-.35-.35v-1.3a.35.35 0 0 1 .35-.35zm0 4h1.3a.35.35 0 0 1 .35.35v1.3a.35.35 0 0 1-.35.35h-1.3a.35.35 0 0 1-.35-.35v-1.3a.35.35 0 0 1 .35-.35zm0 4h1.3a.35.35 0 0 1 .35.35v1.3a.35.35 0 0 1-.35.35h-1.3a.35.35 0 0 1-.35-.35v-1.3a.35.35 0 0 1 .35-.35zm0 4h1.3a.35.35 0 0 1 .35.35v1.3a.35.35 0 0 1-.35.35h-1.3a.35.35 0 0 1-.35-.35v-1.3a.35.35 0 0 1 .35-.35z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Handledrag.displayName = "Handledrag"

Handledrag.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Handledrag.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Handledrag;
