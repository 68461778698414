/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Manage = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M31.489 6.893l-1.333-1.569a1.64 1.64 0 0 0-2.312-.173L26.164 6.6l-5.75 4.942h-8.89v1.111h7.587l-3.275 2.818-.494 2.969h-3.818v1.102h7.805l10.307-8.889 1.68-1.444a1.644 1.644 0 0 0 .173-2.316zM18.924 18.41h-2.44l.396-2.382 9.142-7.867 2.05 2.378-9.148 7.87zM30.591 8.36l-1.68 1.449-2.04-2.369 1.68-1.449a.529.529 0 0 1 .742.058l1.356 1.569a.529.529 0 0 1-.058.742zm-2.769 15.662l-.009-8.715h1.111v8.715a3.8 3.8 0 0 1-3.795 3.791H1.667V2.796h22.009v1.11H2.786V26.69h22.37a2.667 2.667 0 0 0 2.666-2.667zM7.924 10.818a1.262 1.262 0 1 1 0 2.524 1.262 1.262 0 0 1 0-2.524zm0 6.893a1.267 1.267 0 1 1-.008 2.533 1.267 1.267 0 0 1 .008-2.533z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Manage.displayName = "Manage"

Manage.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Manage.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Manage;
