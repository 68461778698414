import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Surface.module.scss';

const cx = classNames.bind(styles);

const Surface = ({
  children,
  className,
  isInteractive,
  isRaised,
  tag: Tag,
  hasForm,
  ...props
}) => {
  const classes = cx(
    'surface',
    {
      'surface--interactive': isInteractive,
      'surface--raised': isRaised,
      'surface--form': hasForm,
    },
    className
  );

  return isInteractive ? (
    <Tag className={classes} {...props} tabIndex="0">
      {children}
    </Tag>
  ) : (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
};

Surface.propTypes = {
  children: PropTypes.node.isRequired,
  /** CSS classes */
  className: classNamePropShape,
  hasForm: PropTypes.bool,
  isInteractive: PropTypes.bool,
  isRaised: PropTypes.bool,
  tag: PropTypes.string,
};

Surface.defaultProps = {
  className: null,
  hasForm: false,
  isInteractive: false,
  isRaised: false,
  tag: 'div',
};

Surface.displayName = 'Surface';

Surface.filename = __filename;

Surface.spreadAttributes = true;

export default Surface;
