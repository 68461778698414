/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Apps = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M20.242 3.006a.662.662 0 0 0-.4.214l-2.027 2.14-1.593-1.68a1.52 1.52 0 0 0-2.224.007L10.86 7.012a.74.74 0 0 0 0 1l5.396 5.702c.17.18.419.25.652.184a.694.694 0 0 0 .477-.503.74.74 0 0 0-.175-.688l-4.926-5.199 2.667-2.821c.094-.1.22-.102.315 0l4.126 4.354a.237.237 0 0 1 0 .34l-1.347 1.423a.736.736 0 0 0-.207.704c.06.257.252.457.496.519s.5-.024.665-.224l1.348-1.421a1.73 1.73 0 0 0 0-2.34l-1.586-1.674 2.035-2.148a.74.74 0 0 0 .136-.818.667.667 0 0 0-.69-.396zm-10.104 7.109a.662.662 0 0 0-.4.215l-1.431 1.51-.54-.577a.655.655 0 0 0-.955 0L3.67 14.58c-.608.64-.608 1.706 0 2.347l1.593 1.681-2.035 2.148a.736.736 0 0 0-.207.704c.06.257.252.457.496.52.244.061.5-.025.665-.224l2.035-2.14 1.579 1.666a1.527 1.527 0 0 0 2.224 0l3.143-3.318a.74.74 0 0 0 0-1l-.533-.57 1.432-1.51a.74.74 0 0 0 .136-.818.667.667 0 0 0-.69-.397.662.662 0 0 0-.4.214l-1.432 1.511L9.26 12.84l1.432-1.51a.74.74 0 0 0 .136-.818.667.667 0 0 0-.69-.397zM7.29 12.774l4.441 4.695-2.666 2.814c-.096.101-.22.101-.316 0L4.623 15.92c-.096-.102-.096-.232 0-.333l2.667-2.814z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Apps.displayName = "Apps"

Apps.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Apps.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Apps;
