/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Staroutline = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M20.494 9.867a.54.54 0 0 0-.44-.344L14.8 8.926 12.33 4.41a.55.55 0 0 0-.48-.281h-.016a.538.538 0 0 0-.48.304L9.139 9.057l-5.21.866a.532.532 0 0 0-.452.517c0 .152.067.29.174.385l3.882 3.461-.758 5.039a.535.535 0 0 0 .54.594.558.558 0 0 0 .259-.063l4.62-2.486 4.745 2.253c.07.034.153.054.241.054a.535.535 0 0 0 .545-.525.531.531 0 0 0-.013-.115l-1.03-4.982 3.689-3.648a.512.512 0 0 0 .123-.544v.004zm-4.792 3.654a.513.513 0 0 0-.144.471l.873 4.22-4.012-1.905a.555.555 0 0 0-.507.015l-3.899 2.102.64-4.267a.513.513 0 0 0-.172-.45l-3.284-2.929 4.407-.732a.544.544 0 0 0 .401-.293l1.876-3.914 2.084 3.815a.55.55 0 0 0 .414.277l4.434.508-3.111 3.082z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Staroutline.displayName = "Staroutline"

Staroutline.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Staroutline.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Staroutline;
