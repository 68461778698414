/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Eye = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12 9.5c.44 0 .853.113 1.24.34.387.227.693.533.92.92.227.387.34.8.34 1.24 0 .44-.113.853-.34 1.24a2.536 2.536 0 0 1-.92.92c-.387.227-.8.34-1.24.34a2.41 2.41 0 0 1-1.24-.34 2.536 2.536 0 0 1-.92-.92A2.41 2.41 0 0 1 9.5 12c0-.44.113-.853.34-1.24.227-.387.533-.693.92-.92.387-.227.8-.34 1.24-.34zm0 6.68c.76 0 1.46-.187 2.1-.56a4.155 4.155 0 0 0 1.52-1.52c.373-.64.56-1.34.56-2.1s-.187-1.46-.56-2.1a4.155 4.155 0 0 0-1.52-1.52 4.094 4.094 0 0 0-2.1-.56c-.76 0-1.46.187-2.1.56-.64.373-1.147.88-1.52 1.52s-.56 1.34-.56 2.1.187 1.46.56 2.1c.373.64.88 1.147 1.52 1.52s1.34.56 2.1.56zm0-10.44a9.752 9.752 0 0 1 7.09 3A9.785 9.785 0 0 1 21.18 12a9.677 9.677 0 0 1-2.09 3.25A9.817 9.817 0 0 1 12 18.24 9.817 9.817 0 0 1 2.82 12a9.785 9.785 0 0 1 2.09-3.26 9.752 9.752 0 0 1 7.09-3z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Eye.displayName = "Eye"

Eye.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Eye.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Eye;
