/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Bundle = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M7.83909 14.9965V6.3566C7.83909 6.0915 7.62419 5.8766 7.3591 5.8766C7.094 5.8766 6.8791 6.0915 6.8791 6.3566V14.9965C6.8791 15.2616 7.094 15.4765 7.3591 15.4765C7.62419 15.4765 7.83909 15.2616 7.83909 14.9965Z" fill="#12131A"/>
<path transform="scale(0.75 0.75)" d="M7.35775 22.6791C6.82757 22.6791 6.39777 23.1089 6.39777 23.6391C6.39777 24.1693 6.82757 24.5991 7.35775 24.5991C7.88794 24.5991 8.31774 24.1693 8.31774 23.6391C8.31774 23.1089 7.88794 22.6791 7.35775 22.6791Z" fill="#12131A"/>
<path transform="scale(0.75 0.75)" fillRule="evenodd" clipRule="evenodd" d="M4.47999 2.04018C4.2149 2.04018 4 2.25508 4 2.52017V29.4798C4 29.7449 4.2149 29.9598 4.47999 29.9598H10.2399C10.505 29.9598 10.7199 29.7449 10.7199 29.4798V2.52017C10.7199 2.25508 10.505 2.04018 10.2399 2.04018H4.47999ZM4.95999 3.00017V28.9998H9.75992V3.00017H4.95999Z" fill="#12131A"/>
<path transform="scale(0.75 0.75)" d="M16.4801 6.3566V14.9965C16.4801 15.2616 16.2652 15.4765 16.0001 15.4765C15.735 15.4765 15.5201 15.2616 15.5201 14.9965V6.3566C15.5201 6.0915 15.735 5.8766 16.0001 5.8766C16.2652 5.8766 16.4801 6.0915 16.4801 6.3566Z" fill="#12131A"/>
<path transform="scale(0.75 0.75)" d="M16.0005 22.6791C15.4703 22.6791 15.0405 23.1089 15.0405 23.6391C15.0405 24.1693 15.4703 24.5991 16.0005 24.5991C16.5307 24.5991 16.9605 24.1693 16.9605 23.6391C16.9605 23.1089 16.5307 22.6791 16.0005 22.6791Z" fill="#12131A"/>
<path transform="scale(0.75 0.75)" fillRule="evenodd" clipRule="evenodd" d="M12.6391 2.52017C12.6391 2.25508 12.854 2.04018 13.1191 2.04018H18.8791C19.1442 2.04018 19.3591 2.25508 19.3591 2.52017V29.4798C19.3591 29.7449 19.1442 29.9598 18.8791 29.9598H13.1191C12.854 29.9598 12.6391 29.7449 12.6391 29.4798V2.52017ZM13.5991 28.9998V3.00017H18.3991V28.9998H13.5991Z" fill="#12131A"/>
<path transform="scale(0.75 0.75)" d="M25.1192 14.9965V6.3566C25.1192 6.0915 24.9043 5.8766 24.6392 5.8766C24.3741 5.8766 24.1592 6.0915 24.1592 6.3566V14.9965C24.1592 15.2616 24.3741 15.4765 24.6392 15.4765C24.9043 15.4765 25.1192 15.2616 25.1192 14.9965Z" fill="#12131A"/>
<path transform="scale(0.75 0.75)" d="M24.6397 22.6791C24.1095 22.6791 23.6797 23.1089 23.6797 23.6391C23.6797 24.1693 24.1095 24.5991 24.6397 24.5991C25.1698 24.5991 25.5996 24.1693 25.5996 23.6391C25.5996 23.1089 25.1698 22.6791 24.6397 22.6791Z" fill="#12131A"/>
<path transform="scale(0.75 0.75)" fillRule="evenodd" clipRule="evenodd" d="M21.2801 2.52017C21.2801 2.25508 21.495 2.04018 21.7601 2.04018H27.52C27.7851 2.04018 28 2.25508 28 2.52017V29.4798C28 29.7449 27.7851 29.9598 27.52 29.9598H21.7601C21.495 29.9598 21.2801 29.7449 21.2801 29.4798V2.52017ZM22.2401 28.9998V3.00017H27.04V28.9998H22.2401Z" fill="#12131A"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Bundle.displayName = "Bundle"

Bundle.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Bundle.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Bundle;
