/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Minicourse = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M29.564 27.609H2.667c-1.599 0-2.41-.817-2.41-2.427V6.142c0-.933.904-.933 1.2-.933h29.345c.57 0 .94.366.94.933v18.916c0 2.08-1.84 2.55-2.178 2.55zM1.13 6.747v18.435c0 1.436.793 1.556 1.538 1.556H29.53c.223-.04 1.342-.311 1.342-1.68V6.836c-1.255 1.08-4.096 3.524-6.94 5.968-7.738 6.65-7.742 6.653-7.936 6.646-.208-.003-.21-.003-14.866-12.703zm.56-.667c4.791 4.15 12.769 11.056 14.316 12.37 1.557-1.315 9.61-8.24 14.409-12.37H1.69z" stroke="#414C55" strokeWidth=".4"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Minicourse.displayName = "Minicourse"

Minicourse.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Minicourse.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Minicourse;
