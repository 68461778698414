/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Upgrade = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12.5 9.286V15.5a.5.5 0 1 1-1 0V9.293l-1.974 1.975a.5.5 0 0 1-.707-.707L12 7.379l.003.003.003-.003 3.182 3.182a.5.5 0 0 1-.707.707L12.5 9.286zM12 20a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-1.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13z" fillRule="evenodd" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Upgrade.displayName = "Upgrade"

Upgrade.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Upgrade.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Upgrade;
