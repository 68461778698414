/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Marketsell = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M12.987 12.689L8.893 16.96l-.804-.769 4.867-5.071 4.106 3.938 3.25-3.458.804.769-4.023 4.258-4.106-3.938zM23 10.8a1.262 1.262 0 1 1 0-2.524 1.262 1.262 0 0 1 0 2.524zm7.942-5.867v-1.11H1.316v1.11h2.506v17.36H1.316v1.111H13.4l-3.484 4.48.888.68 4-5.16h2.64l4 5.16.89-.68-3.485-4.48h12.089v-1.11H28.43V4.933h2.511zm-3.618 17.36H4.934V4.933h22.39v17.36z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Marketsell.displayName = "Marketsell"

Marketsell.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Marketsell.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Marketsell;
