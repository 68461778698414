/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Contentdiscussions = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M5.356 9.662a.643.643 0 1 1-1.286 0 .643.643 0 0 1 1.286 0zm3.12 0a.643.643 0 1 1-1.286 0 .643.643 0 0 1 1.286 0zm3.121 0a.643.643 0 1 1-1.287 0 .643.643 0 0 1 1.287 0zm5.28 3.361a.643.643 0 1 1-1.287 0 .643.643 0 0 1 1.287 0zm3.12 0a.643.643 0 1 1-1.287 0 .643.643 0 0 1 1.287 0z"/><path transform="scale(1 1)" d="M18.934 7.906h-3.718a.215.215 0 0 1-.13-.166c-.456-1.869-1.567-3.434-4.44-3.434H5.224a4.495 4.495 0 0 0-4.48 4.487v1.672a4.16 4.16 0 0 0 3.68 4.133c.074.01.117.057.117.115v.009l.118 1.577a.437.437 0 0 0 .266.356c.116.048.243 0 .457-.114l2.66-1.86a.192.192 0 0 1 .075-.053h1.08a.13.13 0 0 1 .126.102c.296 1.983 1.088 3.258 3.944 3.258h2.815c.033.011.06.03.082.055l2.606 1.94a.742.742 0 0 0 .444.148c.183 0 .524-.125.546-.636v-1.409l-.001-.013c0-.059.043-.107.098-.116 2.141-.258 3.457-1.887 3.457-4.092v-1.54c-.008-2.58-1.848-4.419-4.378-4.419h-.002zM8 13.93a1.002 1.002 0 0 0-.487.063l-1.968 1.464a.137.137 0 0 1-.216-.1l-.048-.65c-.07-.72-.072-.686-.113-.758-.04-.072-.285-.086-.48-.103-2.071-.192-3.144-1.88-3.156-3.638V8.936c0-2.024 1.769-3.68 3.792-3.68l5.566-.04a3.582 3.582 0 0 1 3.533 3.58v1.798c-.088 2.136-1.764 3.336-3.691 3.336H8zm14.584-.064c0 1.92-1.342 3.295-3.254 3.295-.404 0-.336.46-.336.46l-.02 1.277v.002a.14.14 0 0 1-.223.11l-2.016-1.602a.378.378 0 0 0-.223-.084l-3.265-.034c-1.94-.142-2.88-.77-3.046-2.4l-.001-.021c0-.07.051-.13.119-.142l.68-.036c2.961-.108 4.286-2.138 4.214-4.62V8.816c0-.076.062-.137.137-.137h3.818c2.035 0 3.42 1.92 3.42 3.562v1.618l-.004.007z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Contentdiscussions.displayName = "Contentdiscussions"

Contentdiscussions.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Contentdiscussions.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Contentdiscussions;
