import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon, { availableIcons } from '../Icon';
import Button, { withIcon } from './Button';
import { classNamePropShape } from '../../prop-shapes';

import styles from './Button.module.scss';

const cx = classNames.bind(styles);

const IconButton = ({ className, isSmall, name, title, ...props }) => {
  const classes = cx(
    {
      'button--icon-only': !isSmall,
      'button--icon-only-small': isSmall,
    },
    className
  );

  return (
    <Button aria-label={title} className={classes} {...props}>
      {name && <Icon name={name} title={title} aria-hidden />}
    </Button>
  );
};

IconButton.propTypes = {
  /** CSS classes */
  className: classNamePropShape,
  isSmall: PropTypes.bool,
  name: PropTypes.oneOf(availableIcons).isRequired,
  title: PropTypes.string.isRequired,
};

IconButton.defaultProps = {
  className: null,
  isSmall: false,
};

IconButton.displayName = 'IconButton';

IconButton.filename = __filename;

IconButton.spreadAttributes = true;

const ButtonWithIconLeft = withIcon({ position: 'left' })(Button);
ButtonWithIconLeft.displayName = 'ButtonWithIconLeft';

const ButtonWithIconRight = withIcon({ position: 'right' })(Button);
ButtonWithIconRight.displayName = 'ButtonWithIconRight';

const ButtonWithBothIcon = withIcon({ position: 'both' })(Button);
ButtonWithBothIcon.displayName = 'ButtonWithBothIcon';

export {
  IconButton as default,
  ButtonWithIconLeft,
  ButtonWithIconRight,
  ButtonWithBothIcon,
};
