/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Arrowshrink = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M9.466 15.77l-3.997 4.006a.86.86 0 0 1-1.216-1.216l3.996-3.995H5.882a.876.876 0 0 1-.86-.86.855.855 0 0 1 .86-.859l4.297.006a1 1 0 0 1 .999.998l.007 4.297a.86.86 0 1 1-1.72 0V15.77zm9.053-11.5a.86.86 0 0 1 1.216 1.216L15.74 9.482l2.367-.001a.876.876 0 0 1 .86.86.855.855 0 0 1-.86.86l-4.298-.007a1 1 0 0 1-.998-.998L12.803 5.9a.86.86 0 1 1 1.72 0v2.376l3.996-4.005z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Arrowshrink.displayName = "Arrowshrink"

Arrowshrink.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Arrowshrink.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Arrowshrink;
