/* eslint-disable eol-last */
/* eslint-disable quote-props */
import Apps from './react-icons/Apps';
import Arrowdown from './react-icons/Arrowdown';
import Arrowgrow from './react-icons/Arrowgrow';
import Arrowleft from './react-icons/Arrowleft';
import Arrowredo from './react-icons/Arrowredo';
import Arrowright from './react-icons/Arrowright';
import Arrowshrink from './react-icons/Arrowshrink';
import Arrowundo from './react-icons/Arrowundo';
import Arrowupright from './react-icons/Arrowupright';
import Arrowup from './react-icons/Arrowup';
import Beta from './react-icons/Beta';
import Bookmarkfill from './react-icons/Bookmarkfill';
import Bookmarkoutlineadd from './react-icons/Bookmarkoutlineadd';
import Bookmarkoutline from './react-icons/Bookmarkoutline';
import Bullet from './react-icons/Bullet';
import Caretfilldown from './react-icons/Caretfilldown';
import Caretfillleft from './react-icons/Caretfillleft';
import Caretfillright from './react-icons/Caretfillright';
import Caretfillupdown from './react-icons/Caretfillupdown';
import Caretfillup from './react-icons/Caretfillup';
import Caretstrokedown from './react-icons/Caretstrokedown';
import Caretstrokeleft from './react-icons/Caretstrokeleft';
import Caretstrokeright from './react-icons/Caretstrokeright';
import Caretstrokeup from './react-icons/Caretstrokeup';
import Certificate from './react-icons/Certificate';
import Checkboxempty from './react-icons/Checkboxempty';
import Checkboxindeterminate from './react-icons/Checkboxindeterminate';
import Checkboxselected from './react-icons/Checkboxselected';
import Checkmark from './react-icons/Checkmark';
import Circlefillcheck from './react-icons/Circlefillcheck';
import Circlefilldash from './react-icons/Circlefilldash';
import Circlefillinfo from './react-icons/Circlefillinfo';
import Circlefillx from './react-icons/Circlefillx';
import Circleoutlineinfo from './react-icons/Circleoutlineinfo';
import Circleoutlineplay from './react-icons/Circleoutlineplay';
import Circleoutlineprice from './react-icons/Circleoutlineprice';
import Circleslash from './react-icons/Circleslash';
import Cog from './react-icons/Cog';
import Columnselect from './react-icons/Columnselect';
import Connection from './react-icons/Connection';
import Contentassignment from './react-icons/Contentassignment';
import Contentaudio from './react-icons/Contentaudio';
import Contentdiscussions from './react-icons/Contentdiscussions';
import Contentdownload from './react-icons/Contentdownload';
import Contentexam from './react-icons/Contentexam';
import Contentlive from './react-icons/Contentlive';
import Contentmultimedia from './react-icons/Contentmultimedia';
import Contentpdf from './react-icons/Contentpdf';
import Contentpresentation from './react-icons/Contentpresentation';
import Contentquiz from './react-icons/Contentquiz';
import Contentsurvey from './react-icons/Contentsurvey';
import Contenttext from './react-icons/Contenttext';
import Contentvideo from './react-icons/Contentvideo';
import Datadownload from './react-icons/Datadownload';
import Dataexport from './react-icons/Dataexport';
import Dataimport from './react-icons/Dataimport';
import Discussions from './react-icons/Discussions';
import Document from './react-icons/Document';
import Download from './react-icons/Download';
import Ellipsisv from './react-icons/Ellipsisv';
import Enrollmentadd from './react-icons/Enrollmentadd';
import Enrollmentremove from './react-icons/Enrollmentremove';
import Envelope from './react-icons/Envelope';
import Eyeslash from './react-icons/Eyeslash';
import Eye from './react-icons/Eye';
import Filter from './react-icons/Filter';
import Flag from './react-icons/Flag';
import Folder from './react-icons/Folder';
import Graphbar from './react-icons/Graphbar';
import Groupadd from './react-icons/Groupadd';
import Groupremove from './react-icons/Groupremove';
import Handledrag from './react-icons/Handledrag';
import Header from './react-icons/Header';
import Heartfill from './react-icons/Heartfill';
import Heartoutline from './react-icons/Heartoutline';
import Home from './react-icons/Home';
import Iconcommunities from './react-icons/Iconcommunities';
import Image from './react-icons/Image';
import Key from './react-icons/Key';
import Link from './react-icons/Link';
import Location from './react-icons/Location';
import Lock from './react-icons/Lock';
import Magnifyingglass from './react-icons/Magnifyingglass';
import Menu from './react-icons/Menu';
import Message from './react-icons/Message';
import Minus from './react-icons/Minus';
import Newwindow from './react-icons/Newwindow';
import Pageduplicate from './react-icons/Pageduplicate';
import Pagenew from './react-icons/Pagenew';
import Page from './react-icons/Page';
import Pencil from './react-icons/Pencil';
import People from './react-icons/People';
import Pin from './react-icons/Pin';
import Plane from './react-icons/Plane';
import Play from './react-icons/Play';
import Plus from './react-icons/Plus';
import Radioempty from './react-icons/Radioempty';
import Radioselected from './react-icons/Radioselected';
import Record from './react-icons/Record';
import Redirect from './react-icons/Redirect';
import Refresh from './react-icons/Refresh';
import Reply from './react-icons/Reply';
import Report from './react-icons/Report';
import Share from './react-icons/Share';
import Shield from './react-icons/Shield';
import Smileyface from './react-icons/Smileyface';
import Socialapple from './react-icons/Socialapple';
import Socialfacebook from './react-icons/Socialfacebook';
import Socialgooglecolorized from './react-icons/Socialgooglecolorized';
import Socialgoogle from './react-icons/Socialgoogle';
import SociallinkedIn from './react-icons/SociallinkedIn';
import Socialtwitter from './react-icons/Socialtwitter';
import Speaker from './react-icons/Speaker';
import Starfill from './react-icons/Starfill';
import Staroutline from './react-icons/Staroutline';
import Stopsign from './react-icons/Stopsign';
import Systemcalendar from './react-icons/Systemcalendar';
import Trashcan from './react-icons/Trashcan';
import Trophy from './react-icons/Trophy';
import Upgrade from './react-icons/Upgrade';
import Userlogin from './react-icons/Userlogin';
import Userlogout from './react-icons/Userlogout';
import Viewdevicedesktop from './react-icons/Viewdevicedesktop';
import Viewdevicephone from './react-icons/Viewdevicephone';
import Viewgrid from './react-icons/Viewgrid';
import Viewlist from './react-icons/Viewlist';
import Viewmenu from './react-icons/Viewmenu';
import Warning from './react-icons/Warning';
import Webhook from './react-icons/Webhook';
import Windowmaximize from './react-icons/Windowmaximize';
import Windowminimize from './react-icons/Windowminimize';
import Xdismiss from './react-icons/Xdismiss';

const icons = {
  apps: Apps,
  'arrow-down': Arrowdown,
  'arrow-grow': Arrowgrow,
  'arrow-left': Arrowleft,
  'arrow-redo': Arrowredo,
  'arrow-right': Arrowright,
  'arrow-shrink': Arrowshrink,
  'arrow-undo': Arrowundo,
  'arrow-up-right': Arrowupright,
  'arrow-up': Arrowup,
  beta: Beta,
  'bookmark-fill': Bookmarkfill,
  'bookmark-outline-add': Bookmarkoutlineadd,
  'bookmark-outline': Bookmarkoutline,
  bullet: Bullet,
  'caret-fill-down': Caretfilldown,
  'caret-fill-left': Caretfillleft,
  'caret-fill-right': Caretfillright,
  'caret-fill-up-down': Caretfillupdown,
  'caret-fill-up': Caretfillup,
  'caret-stroke-down': Caretstrokedown,
  'caret-stroke-left': Caretstrokeleft,
  'caret-stroke-right': Caretstrokeright,
  'caret-stroke-up': Caretstrokeup,
  certificate: Certificate,
  'checkbox-empty': Checkboxempty,
  'checkbox-indeterminate': Checkboxindeterminate,
  'checkbox-selected': Checkboxselected,
  checkmark: Checkmark,
  'circle-fill-check': Circlefillcheck,
  'circle-fill-dash': Circlefilldash,
  'circle-fill-info': Circlefillinfo,
  'circle-fill-x': Circlefillx,
  'circle-outline-info': Circleoutlineinfo,
  'circle-outline-play': Circleoutlineplay,
  'circle-outline-price': Circleoutlineprice,
  'circle-slash': Circleslash,
  cog: Cog,
  'column-select': Columnselect,
  connection: Connection,
  'content-assignment': Contentassignment,
  'content-audio': Contentaudio,
  'content-discussions': Contentdiscussions,
  'content-download': Contentdownload,
  'content-exam': Contentexam,
  'content-live': Contentlive,
  'content-multimedia': Contentmultimedia,
  'content-pdf': Contentpdf,
  'content-presentation': Contentpresentation,
  'content-quiz': Contentquiz,
  'content-survey': Contentsurvey,
  'content-text': Contenttext,
  'content-video': Contentvideo,
  'data-download': Datadownload,
  'data-export': Dataexport,
  'data-import': Dataimport,
  discussions: Discussions,
  document: Document,
  download: Download,
  'ellipsis-v': Ellipsisv,
  'enrollment-add': Enrollmentadd,
  'enrollment-remove': Enrollmentremove,
  envelope: Envelope,
  'eye-slash': Eyeslash,
  eye: Eye,
  filter: Filter,
  flag: Flag,
  folder: Folder,
  'graph-bar': Graphbar,
  'group-add': Groupadd,
  'group-remove': Groupremove,
  'handle-drag': Handledrag,
  header: Header,
  'heart-fill': Heartfill,
  'heart-outline': Heartoutline,
  home: Home,
  'icon-communities': Iconcommunities,
  image: Image,
  key: Key,
  link: Link,
  location: Location,
  lock: Lock,
  'magnifying-glass': Magnifyingglass,
  menu: Menu,
  message: Message,
  minus: Minus,
  'new-window': Newwindow,
  'page-duplicate': Pageduplicate,
  'page-new': Pagenew,
  page: Page,
  pencil: Pencil,
  people: People,
  pin: Pin,
  plane: Plane,
  play: Play,
  plus: Plus,
  'radio-empty': Radioempty,
  'radio-selected': Radioselected,
  record: Record,
  redirect: Redirect,
  refresh: Refresh,
  reply: Reply,
  report: Report,
  share: Share,
  shield: Shield,
  'smiley-face': Smileyface,
  'social-apple': Socialapple,
  'social-facebook': Socialfacebook,
  'social-google-colorized': Socialgooglecolorized,
  'social-google': Socialgoogle,
  'social-linkedIn': SociallinkedIn,
  'social-twitter': Socialtwitter,
  speaker: Speaker,
  'star-fill': Starfill,
  'star-outline': Staroutline,
  'stop-sign': Stopsign,
  'system-calendar': Systemcalendar,
  'trash-can': Trashcan,
  trophy: Trophy,
  upgrade: Upgrade,
  'user-login': Userlogin,
  'user-logout': Userlogout,
  'view-device-desktop': Viewdevicedesktop,
  'view-device-phone': Viewdevicephone,
  'view-grid': Viewgrid,
  'view-list': Viewlist,
  'view-menu': Viewmenu,
  warning: Warning,
  webhook: Webhook,
  'window-maximize': Windowmaximize,
  'window-minimize': Windowminimize,
  'x-dismiss': Xdismiss,
};

export default icons;
