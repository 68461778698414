/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Iconcommunities = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <g fillRule="evenodd"><circle cx="35.196" cy="2.959" r="2.042"/><circle cx="4.956" cy="53.044" r="2.042"/><circle cx="63.787" cy="53.044" r="2.042"/><path transform="scale(0.3333333333333333 0.3333333333333333)" d="M2.076 43.593a1.31 1.31 0 0 1-1.309-1.074C-2.426 25.537 7.288 8.774 23.611 3.103a1.31 1.31 0 0 1 .864 2.474A32.36 32.36 0 0 0 2.809 36.092c.002 1.993.186 3.983.55 5.943a1.31 1.31 0 0 1-1.047 1.532l-.236.026zm66.136 0h-.236a1.31 1.31 0 0 1-1.047-1.532A32.348 32.348 0 0 0 45.813 5.577a1.31 1.31 0 1 1 .864-2.474 34.992 34.992 0 0 1 23.42 32.989 35.804 35.804 0 0 1-.59 6.427 1.31 1.31 0 0 1-1.295 1.074zM35.144 71.044a34.9 34.9 0 0 1-22.87-8.535 1.31 1.31 0 0 1 1.715-1.977c12.144 10.527 30.179 10.527 42.323 0a1.31 1.31 0 0 1 1.715 1.977 34.927 34.927 0 0 1-22.883 8.535zm12.685-16.782H22.564a1.31 1.31 0 0 1-1.31-1.31V43.79a9.255 9.255 0 0 1 9.243-9.242h9.412a9.255 9.255 0 0 1 9.242 9.242v9.164a1.31 1.31 0 0 1-1.322 1.309zm-23.956-2.618H46.52v-7.855a6.637 6.637 0 0 0-6.624-6.624h-9.412a6.637 6.637 0 0 0-6.611 6.598v7.88zm11.035-22.36a7.501 7.501 0 1 1 7.488-7.5 7.514 7.514 0 0 1-7.488 7.5zm0-12.37a4.883 4.883 0 1 0-.026 9.765 4.883 4.883 0 0 0 .026-9.766z"/></g>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Iconcommunities.displayName = "Iconcommunities"

Iconcommunities.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Iconcommunities.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Iconcommunities;
