/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Heartoutline = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" fillRule="evenodd" d="M11.9885558,19.6512605 C12.037396,19.6089442 12.0631217,19.5901154 12.0848822,19.5673073 C14.871867,16.6365145 17.6691033,13.7153302 20.4409042,10.769979 C21.8255473,9.29871069 21.9509844,7.07603671 20.7990332,5.45743778 C19.4942752,3.62424857 17.0325369,3.39937037 15.4361886,4.9852615 C14.7322131,5.68474237 14.0643116,6.42081326 13.379582,7.1398994 C12.924643,7.61770491 12.4689303,8.09463692 12.0049003,8.58117745 C11.9357504,8.51605304 11.8816877,8.46966038 11.8327508,8.41822083 C11.2816789,7.83938027 10.7336051,7.25772509 10.1804055,6.6809227 C9.55467095,6.02870799 8.9623992,5.33757392 8.2885015,4.73951632 C6.77581006,3.39713808 4.41291272,3.75100339 3.19451961,5.45869951 C2.01684275,7.10913272 2.19779318,9.35102375 3.63282391,10.8629584 C5.90007187,13.2518889 8.16809355,15.64014 10.4365988,18.0280029 C10.9473413,18.5655948 11.461372,19.1000808 11.9885558,19.6512605 M12.0009939,5.09691001 C12.5647974,4.5618729 13.5865214,3.58080957 13.6442229,3.50171037 C15.2521899,1.8318815 16.7894385,1.42987486 18.2280896,1.50945993 C20.6682594,1.64453105 22.7837856,3.32670095 23.5842235,5.58209714 C24.2836352,7.55306658 24.0969311,9.44901811 23.0521849,11.2518775 C22.8230304,11.6471791 22.5346202,12.0197422 22.2197878,12.3517839 C18.8560059,15.8992957 15.4814415,19.4365071 12.109597,22.9763422 C12.0819119,23.0054942 12.0510212,23.0315367 11.9994396,23.0798319 C11.9417382,23.0252204 11.8836481,22.9749818 11.8308037,22.9195929 C8.92231946,19.8663054 6.00985246,16.8169048 3.10943089,13.7560377 C2.4204132,13.0288887 1.7028362,12.3130117 1.12086736,11.5038483 C0.195798212,10.2175631 -0.126222603,8.73362704 0.0432875996,7.15407232 C0.166461776,6.0059678 0.552692473,4.95027525 1.23792168,4.01896474 C2.22584075,2.67593024 3.53179786,1.82459349 5.19532923,1.58107678 C7.11647724,1.29985677 8.7677689,1.87648412 10.1675638,3.20708041" id="Like" fill="#36394D"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Heartoutline.displayName = "Heartoutline"

Heartoutline.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Heartoutline.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Heartoutline;
