/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Viewdevicephone = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M16.629 3.008L7.77 3C6.797 3 6 3.76 6 4.69v15.22c0 .93.797 1.69 1.771 1.69h8.858c.974 0 1.771-.76 1.771-1.69V4.69c0-.93-.797-1.682-1.771-1.682zm0 16.497H7.77V5.095h8.858v14.41z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Viewdevicephone.displayName = "Viewdevicephone"

Viewdevicephone.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Viewdevicephone.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Viewdevicephone;
