/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Enrollmentremove = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M19 11.254a6.948 6.948 0 0 0-1.5-.794V4.5H15V5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-.5H4.5v16h4.436A7.01 7.01 0 0 0 10.1 22H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1h3a1 1 0 0 1 1 1v7.254zM8.015 16.548a7.04 7.04 0 0 0 .047 1.384.75.75 0 1 1-.047-1.384zM10.75 8.5h3.5a.75.75 0 1 1 0 1.5h-3.5a.75.75 0 1 1 0-1.5zm-3 1.5a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zm0 4a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zm3.007 7.243a6 6 0 1 1 8.486-8.486 6 6 0 0 1-8.486 8.486zm7.493-4.993h-6.5a.75.75 0 1 0 0 1.5h6.5a.75.75 0 1 0 0-1.5z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Enrollmentremove.displayName = "Enrollmentremove"

Enrollmentremove.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Enrollmentremove.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Enrollmentremove;
