/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Share = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd"><path transform="scale(1 1)" d="M15.5,5 C16.0396852,5 16.5079345,5.19841071 16.9047619,5.5952381 C17.3015893,5.99206548 17.5,6.46031476 17.5,7 C17.5,7.53968524 17.3015893,8.00793452 16.9047619,8.4047619 C16.5079345,8.80158929 16.0396852,9 15.5,9 C14.9958496,9 14.5540376,8.82685726 14.1745527,8.48056693 L8.95340504,11.5637869 C8.98446839,11.7035995 9,11.8490037 9,12 C9,12.1611109 8.98231791,12.3158554 8.94695357,12.4642341 L14.17,15.525 L14.2301592,15.4703111 C14.5970028,15.156769 15.0202798,15 15.5,15 C16.0396852,15 16.5079345,15.1984107 16.9047619,15.5952381 C17.3015893,15.9920655 17.5,16.4603148 17.5,17 C17.5,17.5396852 17.3015893,18.0079345 16.9047619,18.4047619 C16.5079345,18.8015893 16.0396852,19 15.5,19 C14.9603148,19 14.4920655,18.8015893 14.0952381,18.4047619 C13.6984107,18.0079345 13.5,17.5396852 13.5,17 C13.5,16.8450301 13.5163598,16.6959505 13.5490796,16.5527607 L8.315,13.486 L8.26984084,13.5296889 C7.9029972,13.843231 7.47972021,14 7,14 C6.46031476,14 5.99206548,13.8015893 5.5952381,13.4047619 C5.19841071,13.0079345 5,12.5396852 5,12 C5,11.4603148 5.19841071,10.9920655 5.5952381,10.5952381 C5.99206548,10.1984107 6.46031476,10 7,10 C7.50992482,10 7.95607499,10.1771317 8.33846236,10.5314002 L13.5506943,7.45424388 C13.5168981,7.30891301 13.5,7.1574985 13.5,7 C13.5,6.46031476 13.6984107,5.99206548 14.0952381,5.5952381 C14.4920655,5.19841071 14.9603148,5 15.5,5 Z"></path></g>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Share.displayName = "Share"

Share.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Share.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Share;
