import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Typography.module.scss';

const cx = classNames.bind(styles);

const Caption = ({ children, className, ...props }) => {
  const classes = cx('caption', className);

  return (
    <small className={classes} {...props}>
      {children}
    </small>
  );
};

Caption.propTypes = {
  children: PropTypes.node.isRequired,
  /** CSS classes */
  className: classNamePropShape,
};

Caption.defaultProps = {
  className: null,
};

Caption.displayName = 'Caption';

Caption.filename = __filename;

export default Caption;
