/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Redirect = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M16.75 14.0002V12.0002C16.75 11.332 17.5579 10.9974 18.0303 11.4699L21.0303 14.4699C21.3232 14.7628 21.3232 15.2377 21.0303 15.5305L18.0303 18.5305C17.5579 19.003 16.75 18.6684 16.75 18.0002V16.0002H12.0217C11.6474 16.0081 11.2841 15.8045 11.1056 15.4474L6.38197 8.00022H3C2.44772 8.00022 2 7.5525 2 7.00022C2 6.44793 2.44772 6.00022 3 6.00022H6.97834C7.35258 5.99237 7.7159 6.19595 7.89443 6.55301L12.618 14.0002H16.75Z"/><path transform="scale(1 1)" d="M10.7324 6H21.0003C21.5526 6 22.0003 6.44772 22.0003 7C22.0003 7.55228 21.5526 8 21.0003 8H11.7364L10.7892 6.10557C10.7712 6.0696 10.7522 6.0344 10.7324 6Z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Redirect.displayName = "Redirect"

Redirect.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Redirect.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Redirect;
