/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Checkmarkmulticolor = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M16.111 30.444c-7.883 0-14.333-6.45-14.333-14.333S8.228 1.778 16.11 1.778s14.333 6.45 14.333 14.333-6.45 14.333-14.333 14.333zm0-27.555C8.84 2.889 2.89 8.839 2.89 16.11c0 7.272 5.95 13.222 13.222 13.222 7.272 0 13.222-5.95 13.222-13.222 0-7.272-5.95-13.222-13.222-13.222z" fill="var(--circle)"/><path transform="scale(0.75 0.75)" d="M14.801 20.532a.43.43 0 0 1-.331-.142l-3.694-3.694a.458.458 0 0 1 0-.663c.19-.19.474-.19.663 0l3.362 3.362 7.057-7.056c.19-.19.474-.19.663 0 .19.19.19.473 0 .663l-7.388 7.388c-.047.095-.19.142-.332.142z" fill="var(--checkmark)"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Checkmarkmulticolor.displayName = "Checkmarkmulticolor"

Checkmarkmulticolor.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Checkmarkmulticolor.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Checkmarkmulticolor;
