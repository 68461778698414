import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styled from 'styled-components';
import ThemeContext from '../theme';
import styles from './Button.module.scss';
import { classNamePropShape } from '../../prop-shapes';

import buttonCSS from './buttonCSS';

const cx = classNames.bind(styles);

const StyledButton = styled.button`
  && {
    ${buttonCSS}
  }
`;

const StyledA = styled.a`
  && {
    ${buttonCSS}
  }
`;

const withIcon =
  ({ position }) =>
  /* eslint-disable react/display-name */
  (WrappedComponent) =>
  ({
    // eslint-disable-next-line react/prop-types
    className,
    ...props
  }) => {
    const classes = cx(className, {
      'button--icon-left': position === 'left',
      'button--icon-right': position === 'right',
      'button--icon-both': position === 'both',
    });

    return <WrappedComponent className={classes} {...props} />;
  };

withIcon.displayName = 'withIcon';

const Button = ({
  appearance,
  color,
  children,
  className,
  disabled /* eslint-disable-line react/prop-types */,
  hasDanger,
  href,
  isActive,
  isAlternate,
  isDark,
  isDisabled,
  isUpgrade,
  isHover,
  leftAccessory,
  rightAccessory,
  onClick,
  size,
  type,
  ...props
}) => {
  const Element = href ? StyledA : StyledButton;
  const theme = useContext(ThemeContext);
  let appearanceClasses;

  const baseClasses = cx(className, 'button', {
    'button--block': size === 'block',
    'button--x-small': size === 'x-small',
    'button--small': size === 'small',
    'button--small-tight': size === 'small-tight',
    'button--large': size === 'large',
    active: isActive,
    hover: isHover,
    hovered: isHover,
  });

  const withIconClasses = cx({
    'button--icon-left': leftAccessory,
    'button--icon-right': rightAccessory,
    'button--icon-both': leftAccessory && rightAccessory,
  });

  if (isDark) {
    appearanceClasses = cx({
      'button--primary-dark': appearance === 'primary',
      'button--danger-dark': appearance === 'primary' && hasDanger,
      'button--upgrade-dark': appearance === 'primary' && isUpgrade,
      'button--secondary-dark': appearance === 'secondary',
      'button--secondary-danger-dark': appearance === 'secondary' && hasDanger,
      'button--secondary-upgrade-dark': appearance === 'secondary' && isUpgrade,
      'button--knockout-dark': appearance === 'knockout',
      'button--knockout-upgrade-dark': appearance === 'knockout' && isUpgrade,
      'button--knockout-danger-dark': appearance === 'knockout' && hasDanger,
      'button--link-dark': appearance === 'link',
      'button--utility-dark': appearance === 'utility',
    });
  } else {
    appearanceClasses = cx({
      'button--primary': appearance === 'primary',
      'button--danger': appearance === 'primary' && hasDanger,
      'button--upgrade': appearance === 'primary' && isUpgrade,
      'button--secondary': appearance === 'secondary',
      'button--secondary-danger': appearance === 'secondary' && hasDanger,
      'button--secondary-upgrade': appearance === 'secondary' && isUpgrade,
      'button--knockout': appearance === 'knockout',
      'button--knockout-upgrade': appearance === 'knockout' && isUpgrade,
      'button--knockout-danger': appearance === 'knockout' && hasDanger,
      'button--link': appearance === 'link',
      'button--utility': appearance === 'utility',
      'button--accessible-link': appearance === 'accessible-link',
    });
  }

  const isButtonDisabled = isDisabled || disabled;

  return (
    <Element
      appearance={appearance}
      aria-disabled={isButtonDisabled}
      className={`${baseClasses} ${appearanceClasses} ${withIconClasses}`}
      color={color}
      hasDanger={hasDanger}
      href={href}
      isAlternate={isAlternate}
      isUpgrade={isUpgrade}
      theme={theme}
      type={type}
      onClick={isButtonDisabled ? null : onClick}
      {...props}
    >
      {leftAccessory}
      {children}
      {rightAccessory}
    </Element>
  );
};

Button.propTypes = {
  /** Type of the button */
  appearance: PropTypes.oneOf([
    'primary',
    'secondary',
    'knockout',
    'link',
    'utility',
    'accessible-link',
  ]),
  /** Content for the button */
  children: PropTypes.node.isRequired,
  /** CSS classes */
  className: classNamePropShape,
  /** Button color */
  color: PropTypes.string,
  /** Has Danger style */
  hasDanger: PropTypes.bool,
  /** Pass an href prop to make the button an `a` element instead of a `button` */
  href: PropTypes.string,
  /** Has Active state */
  isActive: PropTypes.bool,
  /** Is alternate button style */
  isAlternate: PropTypes.bool,
  /** Change class to the dark version */
  isDark: PropTypes.bool,
  /** Disable button */
  isDisabled: PropTypes.bool,
  /** Has Upgrade content */
  isHover: PropTypes.bool,
  /** Has Hover state */
  isUpgrade: PropTypes.bool,
  /** Left content of button i.e. Icon */
  leftAccessory: PropTypes.element,
  /** Right content of button, i.e. Icon */
  rightAccessory: PropTypes.element,
  /** Size of the button */
  size: PropTypes.oneOf(['x-small', 'small', 'small-tight', 'large', 'block']),
  /** Type of the button */
  type: PropTypes.string,
  /** Function to run when the button is clicked */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  isActive: false,
  appearance: 'primary',
  className: null,
  color: null,
  hasDanger: false,
  isAlternate: false,
  isDark: false,
  isDisabled: false,
  isUpgrade: false,
  isHover: false,
  leftAccessory: null,
  rightAccessory: null,
  href: null,
  size: null,
  type: 'button',
  onClick: null,
};

Button.displayName = 'Button';

Button.filename = __filename;

Button.spreadAttributes = true;

export { Button as default, withIcon };
