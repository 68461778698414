/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Caretstrokedown = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M11.295 15.5l-5.043-5.032a.86.86 0 0 1 1.216-1.216L12 13.785l4.533-4.535a.876.876 0 0 1 1.216 0 .855.855 0 0 1 0 1.216l-5.043 5.033a1 1 0 0 1-1.412 0z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Caretstrokedown.displayName = "Caretstrokedown"

Caretstrokedown.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Caretstrokedown.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Caretstrokedown;
