/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Discussions = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M19.8 8.8h-1.6V16H7.8v1.6c0 .44.36.8.8.8h8.8l3.2 3.2v-12c0-.44-.36-.8-.8-.8zm-3.2 4.8V6.4c0-.44-.36-.8-.8-.8H5.4c-.44 0-.8.36-.8.8v11.2l3.2-3.2h8c.44 0 .8-.36.8-.8z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Discussions.displayName = "Discussions"

Discussions.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Discussions.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Discussions;
