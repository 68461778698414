/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Students = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M26.511 11.716a4 4 0 0 1 3.996 3.995v8.667H19.582v-1.111h9.814V15.71a2.889 2.889 0 0 0-2.885-2.884h-8.138c-1.215 0-2.3.76-2.715 1.902l-1.045-.378a4 4 0 0 1 3.76-2.635h8.138zm-13.382 8.27a1.267 1.267 0 1 1 0 2.534 1.267 1.267 0 0 1 0-2.533zm-3.28-6.097a3.84 3.84 0 1 0 0-7.68 3.84 3.84 0 0 0 0 7.68zm0-6.565a2.729 2.729 0 1 1 0 5.458 2.729 2.729 0 0 1 0-5.458zm4.067 8.885H5.778a4 4 0 0 0-4 4v8.667H17.91v-8.672a4 4 0 0 0-3.995-3.995zm2.888 11.555H2.894V20.21a2.889 2.889 0 0 1 2.884-2.889h8.138a2.889 2.889 0 0 1 2.888 2.884v7.56zm5.192-18.333a3.84 3.84 0 1 0 0-7.68 3.84 3.84 0 0 0 0 7.68zm0-6.564a2.729 2.729 0 1 1 0 5.457 2.729 2.729 0 0 1 0-5.457z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Students.displayName = "Students"

Students.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Students.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Students;
