/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Home = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M4.20046 11.8065C3.91042 11.477 3.93843 10.972 4.26299 10.6788L11.4136 4.21591C11.7382 3.92273 12.2585 3.92888 12.5762 4.22976L19.7512 11.0233C20.0689 11.3242 20.0845 11.8286 19.7857 12.1494L19.6058 12.3429C19.3074 12.6637 18.825 12.702 18.529 12.4281L17.9928 11.9321V19.1938C17.9928 19.6393 17.6433 20 17.2123 20H14.4155C13.9845 20 13.635 19.6393 13.635 19.1938V14.1135H10.0677V19.1938C10.0739 19.639 9.76547 19.9997 9.33446 19.9997H6.37055C5.93954 19.9997 5.59004 19.639 5.59004 19.1935V12.0343C5.59004 12.0343 5.44192 12.1684 5.25922 12.3342C5.07683 12.4998 4.69375 12.3671 4.40371 12.0372L4.20046 11.8065Z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Home.displayName = "Home"

Home.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Home.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Home;
