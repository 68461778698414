/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Eyeslash = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M12.182 7.339a4.175 4.175 0 0 1 4.174 4.174 4.07 4.07 0 0 1-.3 1.527l2.437 2.438a9.864 9.864 0 0 0 2.863-3.965c-1.444-3.665-5.009-6.26-9.182-6.26a9.72 9.72 0 0 0-3.322.583l1.803 1.803c.475-.192.985-.3 1.527-.3zM3.835 5.06l1.903 1.903.384.384A9.853 9.853 0 0 0 3 11.513c1.444 3.664 5.008 6.26 9.182 6.26a9.83 9.83 0 0 0 3.656-.701l.35.35 2.447 2.438 1.06-1.06L4.895 4l-1.06 1.06zM8.45 9.676l1.294 1.294a2.355 2.355 0 0 0-.067.543 2.5 2.5 0 0 0 2.504 2.504c.184 0 .367-.025.543-.067l1.294 1.294a4.138 4.138 0 0 1-1.837.442 4.175 4.175 0 0 1-4.174-4.173c0-.66.167-1.278.443-1.837zm3.598-.65l2.629 2.629.017-.134a2.5 2.5 0 0 0-2.505-2.504l-.141.008z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Eyeslash.displayName = "Eyeslash"

Eyeslash.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Eyeslash.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Eyeslash;
