/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Checkmark = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M15.892 29.785C8.252 29.785 2 23.533 2 15.892 2 8.252 8.252 2 15.892 2c7.641 0 13.893 6.252 13.893 13.892 0 7.641-6.252 13.893-13.893 13.893zm0-26.708c-7.048 0-12.815 5.767-12.815 12.815 0 7.049 5.767 12.816 12.815 12.816 7.049 0 12.816-5.767 12.816-12.816 0-7.048-5.767-12.815-12.816-12.815z"/><path transform="scale(0.75 0.75)" d="M14.623 20.177a.417.417 0 0 1-.321-.138l-3.58-3.58a.444.444 0 0 1 0-.643.444.444 0 0 1 .642 0l3.259 3.26 6.84-6.84a.444.444 0 0 1 .642 0 .444.444 0 0 1 0 .643l-7.16 7.16c-.047.092-.184.138-.322.138z"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Checkmark.displayName = "Checkmark"

Checkmark.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Checkmark.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Checkmark;
