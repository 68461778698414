/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Report = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M16.359 1.333c.203 0 .338.141.338.353v1.101h5.477c.205 0 .341.14.341.348v19.22c0 .173-.17.312-.34.312H2.006c-.204 0-.34-.14-.34-.347V3.135c0-.208.136-.347.34-.347H7.97V1.685c0-.212.135-.353.338-.353h8.05zm-8.39 2.148h-5.62v18.527H21.8V3.482h-5.103v1.413c0 .176-.17.317-.338.317H8.308c-.203 0-.338-.14-.338-.352V3.48zM19.017 16.5c.29 0 .484.15.484.375s-.242.375-.484.375h-3.532c-.29 0-.484-.15-.484-.375s.194-.375.484-.375h3.532zM8.25 9.75v3.75H12a3.75 3.75 0 1 1-3.75-3.75zm10.766 4.5c.29 0 .484.15.484.375s-.242.375-.484.375h-3.532c-.29 0-.484-.15-.484-.375s.194-.375.484-.375h3.532zm0-2.25c.29 0 .484.15.484.375s-.242.375-.484.375h-3.532c-.29 0-.484-.15-.484-.375s.194-.375.484-.375h3.532zM9 9a3.75 3.75 0 0 1 3.75 3.75H9zm10.016.75c.29 0 .484.15.484.375s-.242.375-.484.375h-3.532c-.29 0-.484-.15-.484-.375s.194-.375.484-.375h3.532zM16.02 2.039H8.646v2.468h7.374V2.039z" fillRule="evenodd"/>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Report.displayName = "Report"

Report.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Report.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Report;
