import Color from 'color';
import hexToCSSFilter from 'hex-to-css-filter';

const minimumLuminoscity = 10;
const maximumLuminoscity = 65;
const white = Color('white');
const black = Color('black');

export const isColor = (color) => {
  if (!color) {
    return false;
  }

  try {
    Color(color);
    return true;
  } catch {
    return false;
  }
};

export const hslObject = (color) => Color(color).hsl().object();

export const setLuminoscity = (color, luminoscity) =>
  Color.hsl({
    ...hslObject(color),
    l: luminoscity,
  });

export const almostWhiteColor = (color) => setLuminoscity(color, 95);
export const almostBlackColor = (color) => setLuminoscity(color, 15);

export const colorsAreAccessible = (foreground, background, level = 'AA') => {
  const ratio = level === 'AAA' ? 7 : 4.5;
  const color1 =
    typeof foreground === 'string' ? Color(foreground) : foreground;
  const color2 =
    typeof background === 'string' ? Color(background) : background;

  return color1.contrast(color2) >= ratio;
};

export const colorCanDarken = (color, min = minimumLuminoscity) => {
  if (!isColor(color)) {
    return null;
  }

  return Color(color).hsl().object().l > min;
};

export const colorCanLighten = (color, max = maximumLuminoscity) => {
  if (!isColor(color)) {
    return null;
  }

  return Color(color).hsl().object().l < max;
};

export const alternateColor = (color) => {
  if (!isColor) {
    return null;
  }

  if (colorCanLighten(color)) {
    return Color(color).lighten(0.5).mix(white, 0.2).hex();
  }

  return Color(color).darken(0.2).hex();
};

export const fadedColor = (color) => {
  if (!isColor(color)) {
    return null;
  }

  return Color(color).mix(Color('white'), 0.95);
};

export const accessibleTextColor = (color, background, level = 'AA') => {
  if (!isColor(color)) {
    return null;
  }

  const backgroundColor = background ? Color(background) : Color(color);
  const lightColor = almostWhiteColor(color);
  const darkColor = almostBlackColor(color);

  const colorOptions = [Color(color), lightColor, darkColor, white, black];

  const passingColor = colorOptions.find((thisColor) =>
    colorsAreAccessible(backgroundColor, thisColor, level)
  );

  if (passingColor) {
    return passingColor;
  }

  const highestContrast = colorOptions.reduce((bestColor, currentColor) => {
    if (
      !bestColor ||
      backgroundColor.contrast(bestColor) <
        backgroundColor.contrast(currentColor)
    ) {
      return currentColor;
    }

    return bestColor;
  });

  return highestContrast;
};

export const colorizeWithCSSFilter = (color) =>
  `brightness(0) invert(1) ${hexToCSSFilter(Color(color).hex()).filter}`;
