import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { IconButton } from '../Button';

import { classNamePropShape } from '../../prop-shapes';

import styles from './MobileTitleBar.module.scss';

const cx = classNames.bind(styles);

const MobileTitleBar = ({
  className,
  wrapperClass,
  iconTitle,
  hasShadow,
  primaryAction,
  primaryIcon,
  rightAccessory,
  title,
}) => {
  const classes = cx('mobile-title-bar', wrapperClass, className, {
    'mobile-title-bar--shadowed': hasShadow,
  });

  return (
    <div className={classes} id="mobile-title-bar">
      <IconButton
        appearance="knockout"
        className={cx('mobile-title-bar__primary-button')}
        id="menuToggle"
        name={primaryIcon}
        title={iconTitle}
        onClick={primaryAction}
      />

      <h1 className={cx('mobile-title-bar__title')}>{title}</h1>

      {rightAccessory && (
        <div className={cx('mobile-title-bar__actions')}>{rightAccessory}</div>
      )}
    </div>
  );
};

MobileTitleBar.propTypes = {
  /** A className applied to outermost element */
  className: classNamePropShape,
  /** Include a shadow */
  hasShadow: PropTypes.bool,
  /** IconButton title (for accessibility reasons) * */
  iconTitle: PropTypes.string,
  /** Action executed when clicked in the IconButton on the left */
  primaryAction: PropTypes.func,
  /** Icon rendered on the left. Default: menu */
  primaryIcon: PropTypes.string,
  /** Actions rendered on the right */
  rightAccessory: PropTypes.node,
  /** Menu title * */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Class added to the wrapper div */
  wrapperClass: PropTypes.string,
};

MobileTitleBar.defaultProps = {
  className: null,
  wrapperClass: null,
  iconTitle: 'Toggle menu',
  hasShadow: false,
  primaryIcon: 'menu',
  rightAccessory: null,
  title: null,
  primaryAction: null,
};

MobileTitleBar.displayName = 'MobileTitleBar';

MobileTitleBar.filename = __filename;

export default MobileTitleBar;
