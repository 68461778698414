import React from 'react';
import PropTypes from 'prop-types';
import LoadingSuccessButton from './LoadingSuccessButton';
import { LOADING, SUCCESS } from './constants';
import { classNamePropShape } from '../../prop-shapes';

class LoadingButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/destructuring-assignment
      state: this.props.isLoading ? LOADING : null,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ isLoading }) {
    const { isLoading: wasLoading, hasSuccessState } = this.props;
    if (wasLoading && !isLoading) {
      if (hasSuccessState) {
        this.setState({ state: SUCCESS });
      } else {
        this.resetState();
      }
    } else if (!wasLoading && isLoading) {
      this.setState({ state: LOADING });
    }
  }

  componentDidUpdate(prevProps, { state: stateWas }) {
    const {
      state: { state: stateIs },
      props: { successTimeout },
    } = this;

    if (stateWas === LOADING && stateIs === SUCCESS) {
      this.timeout = setTimeout(this.resetState, successTimeout);
    } else if (stateWas === SUCCESS && stateIs === LOADING) {
      clearTimeout(this.timeout);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  resetState = () => {
    this.setState({ state: null });
  };

  render() {
    const {
      state: { state },
      props: { disabled, isLoading, successTimeout, ...props },
    } = this;

    return (
      <LoadingSuccessButton
        {...props}
        disabled={disabled || state === LOADING}
        state={state}
      />
    );
  }
}

LoadingButton.propTypes = {
  className: classNamePropShape,
  disabled: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
  hasSuccessDisabled: PropTypes.bool,
  hasSuccessState: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  successTimeout: PropTypes.number,
};

LoadingButton.defaultProps = {
  className: null,
  disabled: false,
  hasSuccessDisabled: true,
  hasSuccessState: true,
  successTimeout: 3000,
};

LoadingButton.displayName = 'LoadingButton';

LoadingButton.filename = __filename;

LoadingButton.spreadAttributes = true;

export default LoadingButton;
