/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Lock = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(1 1)" d="M16.667 9.333h-.762V7.81A3.81 3.81 0 0 0 12.095 4a3.81 3.81 0 0 0-3.81 3.81v1.523h-.761A1.53 1.53 0 0 0 6 10.857v7.62A1.53 1.53 0 0 0 7.524 20h9.143c.838 0 1.523-.686 1.523-1.524v-7.619c0-.838-.685-1.524-1.523-1.524zm-4.572 6.857a1.528 1.528 0 0 1-1.524-1.523c0-.838.686-1.524 1.524-1.524s1.524.686 1.524 1.524-.686 1.523-1.524 1.523zm2.362-6.857H9.733V7.81a2.364 2.364 0 0 1 2.362-2.362 2.364 2.364 0 0 1 2.362 2.362v1.523z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Lock.displayName = "Lock"

Lock.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Lock.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Lock;
