/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Settings = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M16.458 17.569a1.262 1.262 0 1 0-.01-2.525 1.262 1.262 0 0 0 .01 2.525zm-9.854 8.844l3.263-1.87a10.48 10.48 0 0 0 2.76 1.59v3.743h7.657V26.12a10.36 10.36 0 0 0 2.76-1.578l3.267 1.885 3.827-6.631-3.24-1.867c.086-.544.13-1.094.129-1.645a10.747 10.747 0 0 0-.12-1.577l3.23-1.867-3.826-6.631-3.218 1.858a10.556 10.556 0 0 0-2.809-1.627V2.72h-7.657v3.707a10.604 10.604 0 0 0-2.805 1.626L6.604 6.196l-3.83 6.63 3.235 1.867a10.187 10.187 0 0 0 .004 3.218l-3.24 1.871 3.831 6.631zm.57-8.32a9.364 9.364 0 0 1 0-3.586l.075-.387-2.947-1.698L7 7.716 9.947 9.43l.297-.262a9.471 9.471 0 0 1 3.112-1.809l.373-.129v-3.4h5.435v3.38l.374.129a9.471 9.471 0 0 1 3.11 1.809l.299.262 2.937-1.693 2.72 4.706-2.937 1.698.07.387a9.364 9.364 0 0 1 0 3.586l-.075.391 2.96 1.707-2.72 4.707-2.978-1.72-.297.258a9.333 9.333 0 0 1-3.08 1.777l-.374.134v3.444h-5.435v-3.458l-.516-.182a9.333 9.333 0 0 1-2.924-1.715l-.302-.258L7 24.898 4.284 20.19l2.956-1.707-.067-.39z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Settings.displayName = "Settings"

Settings.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Settings.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Settings;
