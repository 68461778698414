/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Caretfillupdown = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <g id="System/caretFill-upDown" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path transform="scale(1 1)" d="M16.1464466,9.14644661 C16.2402148,9.2402148 16.2928932,9.36739176 16.2928932,9.5 C16.2928932,9.77614237 16.0690356,10 15.7928932,10 L8.20710678,10 C8.07449854,10 7.94732158,9.94732158 7.85355339,9.85355339 C7.65829124,9.65829124 7.65829124,9.34170876 7.85355339,9.14644661 L11.2928932,5.70710678 C11.6834175,5.31658249 12.3165825,5.31658249 12.7071068,5.70710678 L16.1464466,9.14644661 Z" id="IconAppearance" fill="#12131A"></path>
        <path transform="scale(1 1)" d="M7.85355339,14.8535534 C7.7597852,14.7597852 7.70710678,14.6326082 7.70710678,14.5 C7.70710678,14.2238576 7.93096441,14 8.20710678,14 L15.7928932,14 C15.9255015,14 16.0526784,14.0526784 16.1464466,14.1464466 C16.3417088,14.3417088 16.3417088,14.6582912 16.1464466,14.8535534 L12.7071068,18.2928932 C12.3165825,18.6834175 11.6834175,18.6834175 11.2928932,18.2928932 L7.85355339,14.8535534 Z" id="IconAppearance-Copy" fill="#12131A"></path>
    </g>
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Caretfillupdown.displayName = "Caretfillupdown"

Caretfillupdown.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Caretfillupdown.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Caretfillupdown;
