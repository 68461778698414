import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { classNamePropShape } from '../../prop-shapes';

import styles from './Label.module.scss';

const cx = classNames.bind(styles);

const Label = (props) => {
  const { className, children, htmlFor, ...attrs } = props;

  return (
    <label className={cx('label', className)} htmlFor={htmlFor} {...attrs}>
      {children}
    </label>
  );
};

Label.propTypes = {
  /** HTML Node that will appear in the component */
  children: PropTypes.node.isRequired,
  /** Optional classnames (array or string) */
  className: classNamePropShape,
  /** Input's name */
  htmlFor: PropTypes.string,
};

Label.defaultProps = {
  className: null,
  htmlFor: null,
};

Label.displayName = 'Label';

Label.filename = __filename;

export default Label;
