/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';

const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap'
};

const Preview = ({ title, width, height, ...rest }) => (
  <>
    <svg
      aria-hidden="true"
      focusable="false"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={width}
      {...rest}
    >
      <path transform="scale(0.75 0.75)" d="M15.951 10.436a5.956 5.956 0 1 0 .009 11.91 5.956 5.956 0 0 0-.009-11.91zm0 10.8a4.844 4.844 0 1 1 .009-9.69 4.844 4.844 0 0 1-.009 9.69zm14.333-4.61a30.302 30.302 0 0 0-4.142-4.888c-3.39-3.227-6.915-4.93-10.19-4.93-3.276 0-6.8 1.703-10.188 4.93a30.476 30.476 0 0 0-4.146 4.889l-.21.324.228.307c.262.355 6.466 8.698 14.315 8.698 7.849 0 14.058-8.343 14.316-8.698l.226-.307-.209-.324zm-14.333 8.218c-6.467 0-12-6.435-13.178-7.933C3.93 15.267 9.44 7.938 15.951 7.938c6.511 0 12.022 7.329 13.182 8.973-1.195 1.498-6.715 7.933-13.182 7.933zm0-9.715a1.262 1.262 0 1 0 0 2.524 1.262 1.262 0 0 0 0-2.524z" />
    </svg>
    { title && <span style={visuallyHidden}>{title}</span>}
  </>
);

Preview.displayName = "Preview"

Preview.defaultProps = {
  height: 24,
  title: null,
  width: 24,
};

Preview.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default Preview;
